import { Component, OnInit } from '@angular/core';
import { BackendService } from '../backend.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as xml2js from "xml2js";
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-mavinform',
  templateUrl: './mavinform.component.html',
  styleUrls: ['./mavinform.component.css']
})
export class MavinformComponent implements OnInit {

  allNews = {};
  newsState = 0;
  myNews = {};
  wasError = false;
  error = "";
  RssData;
  isLoading = true;

  constructor(private data: BackendService, private router: Router, private route: ActivatedRoute, private http: HttpClient) { }

  ngOnInit() {
    const requestOptions: Object = {
      observe: "body",
      //responseType: "text"
    }

    this.http.get<any>('https://api.allorigins.win/get?url=https://www.mavcsoport.hu/mavinform/rss.xml', requestOptions)
    .subscribe(data => {
      let parseString = xml2js.parseString;
      parseString(data.contents, (err, result) => {
        this.isLoading = false;
        this.RssData = result;
        //console.log(this.RssData);
        if(err) {
          this.error = "Hiba történt a hírek betöltése közben."
          this.wasError = true;
        }
      })
    }, error => {
      this.error = "Hiba történt a hírek betöltése közben."
      this.wasError = true;
    })

    /*this.data.getMavinform().subscribe(data => {
      if(data['Uzenetek']) {
        this.wasError = true;
        this.error = data['Uzenetek'][0]['Szoveg'];
      }
      this.allNews = data;
    },
    error => {
      this.wasError = true;
      this.error = error;
      //console.log(error);
    })*/
  }
  
  displayHir(hirID) {
    this.router.navigate([hirID], {relativeTo: this.route});
  }

  resetNews() {
    this.newsState = 0;
  }

}
