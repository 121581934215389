import { Component, OnInit } from '@angular/core';
import { SzovegkonyvService } from './szovegkonyv.service';
import { Router } from '@angular/router';
import { BackendService } from '../backend.service';

@Component({
  selector: 'app-szovegkonyv-offline',
  templateUrl: './szovegkonyv-offline.component.html',
  styleUrls: ['./szovegkonyv-offline.component.css']
})
export class SzovegkonyvOfflineComponent implements OnInit {

  fordatipus;
  fordaszam;
  szolgalatStatusz;
  fordaAdatok;
  hangositoFordak = [];
  allStations;

  constructor(public szkonyv: SzovegkonyvService, private router: Router, public data: BackendService) { }

  ngOnInit(): void {
    this.fordatipus = localStorage.getItem('fordatipus');
    this.fordaszam = localStorage.getItem('fordaszam');
    this.szolgalatStatusz = localStorage.getItem('szolgalatStatusz');

    if(this.szolgalatStatusz != "0") {
      if(this.szolgalatStatusz == "2") {
        var sign = this.fordatipus + "" + this.fordaszam;
        this.data.getFordaData(sign, localStorage.getItem('telephely')).subscribe(data => {
          this.fordaAdatok = data;
          this.hangositoFordak = this.fordaAdatok.filter(forda => {
            if((forda['Sign'].includes('H') || forda['Sign'].includes('UE') || forda['Sign'].includes('RH'))) {
              if(forda['Note'] != 'önköltség' && forda['Note'] != 'csak kerékpár') {
                return forda;
              }
            }
          })
        }, error => {
          console.log(error);
        })
      }
    }

    this.data.getAllomasLista().subscribe(data => {
      this.allStations = data;
    }, error => {
      console.log(error);
    })
  }

  goToTrain(id) {
    this.router.navigate(['szkonyvoffline', id])
  }

}
