<div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article class="card-body" style="height: 100%;">
     <h2>Adatvédelmi tájékoztató</h2>
     <p>Az adatvédelmi tájékoztató letölthető innen: <a href="../../assets/adatvedelmi-tajekoztato.pdf">letöltés</a></p>
     <style>
        body { text-indent:-0.5pt; text-align:justify; line-height:105%; font-family:Calibri; font-size:12pt }
        h1, p { margin:0pt 0.2pt 5.35pt 0.5pt }
        li, table { margin-top:0pt; margin-bottom:5.35pt }
        h1 { margin-right:0.3pt; margin-left:35.7pt; margin-bottom:10.15pt; text-indent:-17.85pt; page-break-inside:avoid; page-break-after:avoid; line-height:108%; font-size: 16pt; font-weight:bold; }
        .ListParagraph { margin-right:0.2pt; margin-left:36pt; margin-bottom:5.35pt; text-indent:-0.5pt; text-align:justify; line-height:105%; font-size:12pt; }
        span.Cmsor1Char { font-family:Calibri; font-size:13.5pt; font-weight:bold; }
        span.Hyperlink { text-decoration:underline; color:#0563c1 }
        span.UnresolvedMention { color:#605e5c; background-color:#e1dfdd }
        .awlist1 { list-style:none; counter-reset:awlistcounter1_0 }
        .awlist1 > li:before { content:counter(awlistcounter1_0, lower-latin) ')'; counter-increment:awlistcounter1_0 }
        .awlist2 { list-style:none; counter-reset:awlistcounter2_0 }
        .awlist2 > li:before { content:counter(awlistcounter2_0, lower-latin) ')'; counter-increment:awlistcounter2_0 }
    </style>
        <p style="text-align:center; line-height:105%; font-size:14pt">
            <strong>ADATVÉDELMI TÁJÉKOZTATÓ</strong>
        </p>
        <p style="margin-right:0pt; margin-left:0.7pt; margin-bottom:4.95pt; text-indent:0pt; text-align:left; line-height:108%">
            &#xa0; 
        </p>
        <p style="margin-right:0pt; margin-bottom:0.3pt">
            amelyben tájékoztatjuk Önt, mint alkalmazásunk használóját, valamint szolgáltatásaink igénybe vevőjét társaságunk adatkezelési és adatvédelmi szabályairól.&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:0.7pt; margin-bottom:8.6pt; text-indent:0pt; text-align:left; line-height:108%">
            &#xa0;
        </p>
        <h1 style="text-align:left">
            1. Milyen alapelveket követünk adatkezelésünk során?&#xa0; 
        </h1>
        <p style="margin-right:0pt; margin-left:0.7pt; margin-bottom:4.8pt; text-indent:0pt; text-align:left; line-height:108%">
            &#xa0; 
        </p>
        <p style="margin-right:0pt; margin-bottom:8.9pt">
            Társaságunk az adatkezelése során alábbi alapelveket követi:&#xa0; 
        </p>
        <ol class="awlist1" style="margin:0pt; padding-left:0pt">
            <li style="margin-left:36.7pt; text-indent:-18pt">
                <span style="width:8.61pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>a személyes adatokat jogszerűen és tisztességesen, valamint az Ön számára átláthatóan kezeljük.&#xa0; 
            </li>
            <li style="margin-left:36.7pt; text-indent:-18pt">
                <span style="width:8.06pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>a személyes adatokat csak meghatározott, egyértelmű és jogszerű célból gyűjtjük és azokat nem kezeljük a célokkal össze nem egyeztethető módon.&#xa0; 
            </li>
            <li style="margin-left:36.7pt; text-indent:-18pt">
                <span style="width:9.29pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>az általunk gyűjtött és kezelt személyes adatok az adatkezelés céljai szempontjából megfelelőek és relevánsak, valamint csak a szükségesre korlátozódnak.&#xa0; 
            </li>
            <li style="margin-left:36.7pt; text-indent:-18pt">
                <span style="width:8.06pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Társaságunk minden észszerű intézkedést megtesz annak érdekében, hogy az általunk kezelt adatok pontosak és szükség esetén naprakészek legyenek, a pontatlan személyes adatokat haladéktalanul töröljük vagy helyesbítjük.&#xa0; 
            </li>
            <li style="margin-left:36.7pt; text-indent:-18pt">
                <span style="width:8.39pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>a személyes adatokat olyan formában tároljuk, hogy Ön csak a személyes adatok kezelése céljainak eléréséhez szükséges ideig legyen azonosítható.&#xa0; 
            </li>
            <li style="margin-left:36.7pt; text-indent:-18pt">
                <span style="width:10.7pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>megfelelő technikai és szervezési intézkedések alkalmazásával biztosítjuk a személyes adatok megfelelő biztonságát az adatok jogosulatlan vagy jogellenes kezelésével, véletlen elvesztésével, megsemmisítésével vagy károsodásával szemben.&#xa0; 
            </li>
        </ol>
        <p style="margin-right:0pt; margin-left:1.2pt; margin-bottom:8.7pt; line-height:106%">
            <strong>Társaságunk az Ön személyes adatait</strong><strong>&#xa0;&#xa0; </strong>&#xa0;
        </p>
        <ol class="awlist2" style="margin:0pt; padding-left:0pt">
            <li style="margin-left:36.7pt; margin-bottom:2.95pt; text-indent:-18pt">
                <span style="width:8.61pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>az Ön előzetes tájékoztatáson alapuló és önkéntes hozzájárulása alapján és csakis a szükséges mértékben és minden esetben célhoz kötötten kezeljük, azaz gyűjtjük, rögzítjük, rendszerezzük, tároljuk és felhasználjuk.&#xa0;&#xa0; 
            </li>
            <li style="margin-left:36.7pt; margin-bottom:3.1pt; text-indent:-18pt">
                <span style="width:8.06pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>egyes esetekben az Ön adatainak kezelése jogszabályi előírásokon alapul és kötelező jellegű, ilyen esetekben erre a tényre külön felhívjuk az Ön figyelmét.&#xa0; 
            </li>
            <li style="margin-left:36.7pt; text-indent:-18pt">
                <span style="width:9.29pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>illetve bizonyos esetekben az Ön személyes adatainak kezeléséhez Társaságunknak, vagy pedig harmadik személynek fűződik jogos érdeke, például honlapunk működtetése, fejlesztése és biztonsága.&#xa0; 
            </li>
        </ol>
        <h1 style="text-align:left">
            2.<span style="width:7.4pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span>Kik vagyunk?&#xa0; 
        </h1>
        <table style="width:357.05pt; margin-left:0.7pt; margin-bottom:0pt; border-collapse:collapse">
            <tr style="height:18.25pt">
                <td style="width:132.85pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        A Társaságunk honlapja:&#xa0; 
                    </p>
                </td>
                <td style="width:44.2pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:8.75pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        &#xa0; 
                    </p>
                </td>
                <td style="width:180pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        <strong>www.mavdtc.xyz</strong> 
                    </p>
                </td>
            </tr>
            <tr style="height:20.65pt">
                <td style="width:132.85pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        Kapcsolattartás:&#xa0;&#xa0; <span style="width:19.58pt; display:inline-block">&#xa0;</span>&#xa0; 
                    </p>
                </td>
                <td style="width:44.2pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:8.75pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        &#xa0; 
                    </p>
                </td>
                <td style="width:180pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        <strong>email</strong> 
                    </p>
                </td>
            </tr>
            <tr style="height:20.65pt">
                <td style="width:132.85pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        E-mail címünk:<span style="width:34.47pt; display:inline-block">&#xa0;</span>&#xa0; 
                    </p>
                </td>
                <td style="width:44.2pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:8.75pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        &#xa0; 
                    </p>
                </td>
                <td style="width:180pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        <strong><a href="mailto:mavdtcapp@gmail.com">mavdtcapp@gmail.com</a></strong>&#xa0; 
                    </p>
                </td>
            </tr>
        </table>
        <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
            &#xa0;
        </p>
        <p style="margin-right:0pt; margin-left:177.75pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
            <strong>&#xa0;</strong>
        </p>
        <p style="margin-right:0pt">
            Társaságunk a GDPR 37. cikke alapján nem köteles adatvédelmi tisztviselő kinevezésére. 
        </p>
        <p style="margin-right:0pt; margin-bottom:9.4pt">
            Társaságunk tárhely szolgáltatójának neve, címe és elérhetősége: <strong>VNG Hosting Bt., </strong><strong><span style="line-height:105%; font-size:11pt; "><a href="mailto:info@clans.hu">info@clans.hu</a></span></strong>
        </p>
        <p style="margin-right:0pt">
            Társaságunk az adatok kezelése során – ügyfeleink színvonalas kiszolgálása érdekében – az alábbi adatfeldolgozókat veszi igénybe:&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:0.7pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
            &#xa0; 
        </p>
        <table style="width:461.9pt; margin-left:0.5pt; margin-bottom:0pt; border:0.75pt solid #000000; border-collapse:collapse">
            <tr style="height:21.35pt">
                <td style="width:142.45pt; border-right:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-top:4.8pt; padding-right:5.38pt; padding-left:5.18pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
                        NÉV
                    </p>
                </td>
                <td style="width:138.25pt; border-right:0.75pt solid #000000; border-left:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-top:4.8pt; padding-right:5.38pt; padding-left:5.18pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
                        CÍM&#xa0; 
                    </p>
                </td>
                <td style="width:146.55pt; border-left:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-top:4.8pt; padding-right:5.38pt; padding-left:5.18pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; line-height:108%">
                        TEVÉKENYSÉG&#xa0; 
                    </p>
                </td>
            </tr>
            <tr style="height:44.5pt">
                <td style="width:142.45pt; border-top:0.75pt solid #000000; border-right:0.75pt solid #000000; padding-top:4.8pt; padding-right:5.38pt; padding-left:5.18pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:4.8pt; text-indent:0pt; line-height:108%">
                        VNG Hosting Bt.
                    </p>
                </td>
                <td style="width:138.25pt; border-top:0.75pt solid #000000; border-right:0.75pt solid #000000; border-left:0.75pt solid #000000; padding-top:4.8pt; padding-right:5.38pt; padding-left:5.18pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-bottom:4.8pt; line-height:108%">
                        8200 Veszprém, Haszkovó u. 39/C 7/25.
                    </p>
                </td>
                <td style="width:146.55pt; border-top:0.75pt solid #000000; border-left:0.75pt solid #000000; padding-top:4.8pt; padding-right:5.38pt; padding-left:5.18pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:4.8pt; text-indent:0pt; text-align:left; line-height:108%">
                        <em>webtárhely, szerver szolgáltatás</em>
                    </p>
                </td>
            </tr>
        </table>
        <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
            &#xa0;
        </p>
        <p style="margin-right:0pt">
            Amennyiben az adatfeldolgozóink körét módosítjuk, a változásokat átvezetjük jelen tájékoztatónkban.&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:1.2pt; margin-bottom:0.25pt; line-height:106%">
            <strong>Az általunk kezelt adatok:</strong><strong>&#xa0;&#xa0;&#xa0; </strong>
        </p>
        <p style="margin-right:0pt; margin-left:0.7pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
            <strong>&#xa0;</strong>
        </p>
        <table style="width:460.25pt; margin-left:0.95pt; margin-bottom:0pt; border:0.75pt solid #000000; border-collapse:collapse">
            <tr style="height:18.85pt">
                <td style="width:167.9pt; border-right:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:107%">
                        Tevékenység megnevezése és az adatkezelés célja 
                    </p>
                </td>
                <td style="width:69.85pt; border-right:0.75pt solid #000000; border-left:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        Jogalap&#xa0; 
                    </p>
                </td>
                <td style="width:98.25pt; border-right:0.75pt solid #000000; border-left:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        Kezelt adatok 
                    </p>
                </td>
                <td style="width:91.1pt; border-left:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        Időtartam 
                    </p>
                </td>
            </tr>
            <tr style="height:95.6pt">
                <td style="width:167.9pt; border-top:0.75pt solid #000000; border-right:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        Az alkalmazást használók alapvető tevékenységei (be- és kijelentkezés, szolgálatra jelentkezés) az alkalmazás biztonságának megőrzésének érdekében
                    </p>
                </td>
                <td style="width:69.85pt; border:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        Társaságunk jogos érdeke 
                    </p>
                </td>
                <td style="width:98.25pt; border:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        Tevékenység típusa, felhasználó IP címe, alkalmazás verziószáma 
                    </p>
                </td>
                <td style="width:91.1pt; border-top:0.75pt solid #000000; border-left:0.75pt solid #000000; border-bottom:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        30 nap 
                    </p>
                </td>
            </tr>
            <tr style="height:46.7pt">
                <td style="width:167.9pt; border-top:0.75pt solid #000000; border-right:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        Az alkalmazás használatához szükséges regisztrációhoz kapcsolódó adatok 
                    </p>
                </td>
                <td style="width:69.85pt; border-top:0.75pt solid #000000; border-right:0.75pt solid #000000; border-left:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        Érintett személyes hozzájárulása 
                    </p>
                </td>
                <td style="width:98.25pt; border-top:0.75pt solid #000000; border-right:0.75pt solid #000000; border-left:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        Vezeték- és keresztnév, e-mail cím, telefonszám 
                    </p>
                </td>
                <td style="width:91.1pt; border-top:0.75pt solid #000000; border-left:0.75pt solid #000000; padding-top:2.5pt; padding-right:2.33pt; padding-left:5.03pt; vertical-align:top">
                    <p style="margin-right:0pt; margin-left:0pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
                        A felhasználói fiók törléséig 
                    </p>
                </td>
            </tr>
        </table>
        <p style="margin-right:0pt; margin-left:0.7pt; margin-bottom:0pt; text-indent:0pt; text-align:left; line-height:108%">
            &#xa0;
        </p>
        <p style="margin-right:0pt">
            Az alkalmazás használóitól csak akkor kérjük személyes adataikat, ha regisztrálni szeretnének a szolgáltatásban.
        </p>
        <p style="margin-right:0pt">
            Az adatkezeléssel kapcsolatos kérdéseivel Ön a <strong><em><a href="mailto:mavdtcapp@gmail.com">mavdtcapp@gmail.com</a></em></strong> e-mail címen kérhet további tájékoztatást, válaszunkat késedelem nélkül, 20 napon belül (legfeljebb azonban 1 hónapon belül) megküldjük Önnek az Ön által megadott elérhetőségre.&#xa0;&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:0.7pt; margin-bottom:8.45pt; text-indent:0pt; text-align:left; line-height:108%">
            &#xa0; 
        </p>
        <h1 style="text-align:left">
            3.<span style="width:7.4pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span>Mik azok a sütik és hogyan kezeljük őket?&#xa0; 
        </h1>
        <p style="margin-right:0pt; margin-left:0.7pt; margin-bottom:6.65pt; text-indent:0pt; text-align:left; line-height:108%">
            Társaságunk nem alkalmaz sütiket.<strong> </strong>&#xa0;
        </p>
        <h1 style="text-align:left">
            4.<span style="width:7.4pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span>Mit kell tudni még a honlapunkkal kapcsolatos adatkezelésünkről?&#xa0; 
        </h1>
        <p style="margin-right:0pt">
            A személyes adatokat Ön önkéntesen bocsátja rendelkezésünkre a regisztráció során, éppen ezért kérjük, hogy adatai közlésekor fokozatosan ügyeljen azok valódiságára, helyességére és pontosságára, mert ezekért Ön felelős. A helytelen, pontatlan vagy hiányos adat akadálya lehet a szolgáltatásaink igénybevételének.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            Amennyiben Ön nem a saját, hanem más személy személyes adatait adja meg, úgy vélelmezzük, hogy Ön az ehhez szükséges felhatalmazással rendelkezik.&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-bottom:8.85pt">
            Ön az adatkezeléshez adott hozzájárulását bármikor ingyenesen visszavonhatja&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:18.7pt; margin-bottom:2.8pt; text-indent:0pt">
            <span style="font-family:Wingdings"></span><span style="width:7.17pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span>a regisztráció törlésével,&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:18.7pt; margin-bottom:2.8pt; text-indent:0pt">
            <span style="font-family:Wingdings"></span><span style="width:7.17pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span>az adatkezeléshez hozzájárulás visszavonásával, illetve&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:18.7pt; margin-bottom:5.65pt; text-indent:0pt; line-height:104%">
            <span style="font-family:Wingdings"></span><span style="width:7.17pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span>a <span style="width:34.11pt; display:inline-block">&#xa0;</span>regisztráció <span style="width:11.99pt; display:inline-block">&#xa0;</span>során <span style="width:5.43pt; display:inline-block">&#xa0;</span>feltétlen <span style="width:26.66pt; display:inline-block">&#xa0;</span>kitöltendő <span style="width:17.85pt; display:inline-block">&#xa0;</span>bármely <span style="width:28.11pt; display:inline-block">&#xa0;</span>adat <span style="width:10.87pt; display:inline-block">&#xa0;</span>kezeléséhez               vagy felhasználásához való hozzájárulás visszavonásával vagy zárolásának kérésével.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            A hozzájárulás visszavonásának regisztrálását – technikai okokból – 30 napos határidővel vállaljuk, azonban felhívjuk a figyelmét arra, hogy jogi kötelezettségünk teljesítése vagy jogos érdekeink érvényesítése céljából bizonyos adatokat a hozzájárulás visszavonása után is kezelhetünk.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            Megtévesztő személyes adat használata esetén, illetve, ha valamelyik felhasználónk bűncselekményt követ el vagy Társaságunk rendszerét támadja, az adott látogató regisztrációjának megszüntetésével egyidejűleg adatait haladéktalanul töröljük, illetve – szükség esetén – megőrizzük azokat a polgári jogi felelősség megállapításának vagy büntető eljárás lefolytatásának időtartama alatt.&#xa0;&#xa0;&#xa0; 
        </p>
        <h1 style="text-align:left">
            5.<span style="width:7.4pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span>Egyéb adatkezelési kérdések&#xa0;&#xa0; 
        </h1>
        <p style="margin-right:0pt">
            Az Ön adatait csak jogszabályban meghatározott keretek között továbbíthatjuk, adatfeldolgozóink esetében pedig szerződéses feltételek kikötésével biztosítjuk, hogy ne használhassák az Ön hozzájárulásával ellentétes célokra az Ön személyes adatait. További információ a 2. pontban található.&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt">
            Társaságunk külföldre nem továbbít adatokat.&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt">
            A bíróság, az ügyészség és más hatóságok (pl. rendőrség, adóhivatal, Nemzeti Adatvédelmi és Információszabadság Hatóság) tájékoztatás adása, adatok közlése vagy iratok rendelkezésre bocsátása miatt megkereshetik Társaságunkat. Ezekben az esetekben adatszolgáltatási kötelezettségünket teljesítenünk kell, de csak a megkeresés céljának megvalósításához elengedhetetlenül szükséges mértékben.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            Társaságunk adatkezelésében és/vagy adatfeldolgozásában részt vevő közreműködői és munkavállalói előre meghatározott mértékben – titoktartási kötelezettség terhe mellett – jogosultak az Ön személyes adatait megismerni.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            Az Ön személyes adatait megfelelő technikai és egyéb intézkedésekkel védjük, valamint biztosítjuk az adatok biztonságát, rendelkezésre állását, továbbá óvjuk azokat a jogosulatlan hozzáféréstől, megváltoztatástól, sérülésektől illetve nyilvánosságra hozataltól és bármilyen egyéb jogosulatlan felhasználástól.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            A biztonsági kérdésekkel kapcsolatban kérjük az Ön segítségét abban, hogy gondosan őrizze meg az alkalmazáshoz meglévő hozzáférési jelszavát és ezt a jelszót senkivel se ossza meg.&#xa0; 
        </p>
        <h1 style="text-align:left">
            6.<span style="width:7.4pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span>Melyek az Ön jogai és jogorvoslati lehetőségei?&#xa0;&#xa0; 
        </h1>
        <p style="margin-right:0pt; margin-bottom:8.6pt">
            Ön az adatkezelésről&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:2.7pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>tájékoztatást kérhet,&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:2.3pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>kérheti az általunk kezelt személyes adataik helyesbítését, módosítását, kiegészítését,&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:2.95pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>tiltakozhat az adatkezelés ellen és kérheti adatai törlését valamint zárolását (a kötelező adatkezelés kivételével),&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:2.9pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>bíróság előtt jogorvoslattal élhet,&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; text-indent:-18pt; text-align:left">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>a felügyelő hatóságnál panaszt tehet, illetve eljárást kezdeményezhet (https://naih.hu/panaszuegyintezes-rendje.html).&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:1.2pt; margin-bottom:8.45pt; line-height:106%">
            <strong>Felügyelő Hatóság: Nemzeti Adatvédelmi és Információszabadság Hatóság</strong>&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:2.75pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Székhely: 1125 Budapest, Szilágyi Erzsébet fasor 22/c.&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:2.75pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Levelezési cím: 1530 Budapest, Pf.: 5.&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:3.15pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Telefon: +36 (1) 391-1400&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:2.8pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>Fax: +36 (1) 391-1410&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>E-mail: <a href="mailto:ugyfelszolgalat@naih.hu">ugyfelszolgalat@naih.hu</a>, honlap: https://naih.hu/&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-bottom:8.85pt">
            <strong>Az Ön kérelmére tájékoztatást adunk</strong> az Ön általunk kezelt, illetve az általunk – vagy a megbízott adatfeldolgozónk által – feldolgozott&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:2.8pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>adatairól,&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:2.85pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>azok forrásáról,&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:2.8pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>az adatkezelés céljáról és jogalapjáról,&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:2.95pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>időtartamáról, ha pedig ez nem lehetséges, ezen időtartam meghatározásának szempontjairól,&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:3.1pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>az <span style="width:11.37pt; text-indent:0pt; display:inline-block">&#xa0;</span>adatfeldolgozóink <span style="width:16.33pt; text-indent:0pt; display:inline-block">&#xa0;</span>nevéről, <span style="width:28.16pt; text-indent:0pt; display:inline-block">&#xa0;</span>címéről <span style="width:31.44pt; text-indent:0pt; display:inline-block">&#xa0;</span>és <span style="width:22.02pt; text-indent:0pt; display:inline-block">&#xa0;</span>az <span style="width:22.2pt; text-indent:0pt; display:inline-block">&#xa0;</span>adatkezeléssel               összefüggő tevékenységükről,&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:3pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>adatvédelmi incidensek körülményeiről, hatásairól és az elhárításukra valamint megelőzésükre tett intézkedéseinkről, továbbá&#xa0;&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; text-indent:-18pt">
            <span style="font-family:Wingdings"></span><span style="width:8.47pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>az Ön személyes adatainak továbbítása esetén az adattovábbítás jogalapjáról és címzettjéről.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            A kérelem benyújtásától számított legrövidebb idő alatt, 10 napon belül (legfeljebb azonban 1 hónapon belül) adjuk meg tájékoztatásunkat. A tájékoztatás ingyenes kivéve akkor, ha Ön a folyó évben azonos adatkörre vonatkozóan tájékoztatási kérelmet már nyújtott be hozzánk. Az Ön által már megfizetett költségtérítést visszatérítjük abban az esetben, ha az adatokat jogellenesen kezeltük vagy a tájékoztatás kérése helyesbítéshez vezetett. A tájékoztatást csak törvényben foglalt esetekben tagadhatjuk meg jogszabályi hely megjelölésével, valamint a bírósági jogorvoslat illetve a Hatósághoz fordulás lehetőségéről tájékoztatással.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            Társaságunk a személyes adatok helyesbítésről, zárolásról, megjelölésről és törlésről Önt, továbbá mindazokat értesíti, akiknek korábban az adatot adatkezelés céljára továbbította, kivéve akkor, ha az értesítés elmaradása az Ön jogos érdekét nem sérti.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            Amennyiben az Ön helyesbítés, zárolás vagy törlés iránti kérelmét nem teljesítjük, a kérelem kézhezvételét követő 10 napon belül (legfeljebb azonban 1 hónapon belül) írásban vagy – az Ön hozzájárulásával – elektronikus úton közöljünk elutasításunk indokait és tájékoztatjuk Önt a bírósági jogorvoslat, továbbá a Hatósághoz fordulás lehetőségéről.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            Amennyiben Ön tiltakozik a személyes adatai kezelése ellen, a tiltakozást a kérelem benyújtásától számított legrövidebb időn belül, 10 napon belül (legfeljebb azonban 1 hónapon belül) megvizsgáljuk és a döntésünkről Önt írásban tájékoztatjuk. Amennyiben úgy döntöttünk, hogy az Ön tiltakozása megalapozott, abban az esetben az adatkezelést - beleértve a további adatfelvételt és adattovábbítást is - megszüntetjük és az adatokat zároljuk, valamint a tiltakozásról, továbbá az annak alapján tett intézkedésekről értesítjük mindazokat, akik részére a tiltakozással érintett személyes adatot korábban továbbítottuk, és akik kötelesek intézkedni a tiltakozási jog érvényesítése érdekében.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            Abban az esetben megtagadjuk a kérés teljesítését, ha bizonyítjuk, hogy az adatkezelést olyan kényszerítő erejű jogos okok indokolják, amelyek elsőbbséget élveznek az Ön érdekeivel, jogaival és szabadságaival szemben, vagy amelyek jogi igények előterjesztéséhez, érvényesítéséhez vagy védelméhez kapcsolódnak. Amennyiben Ön a döntésünkkel nem ért egyet, illetve ha elmulasztjuk a határidőt, a döntés közlésétől, illetve a határidő utolsó napjától számított 30 napon belül Ön bírósághoz fordulhat.&#xa0; 
        </p>
        <p style="margin-right:0pt">
            Az adatvédelmi perek elbírálása a törvényszék hatáskörébe tartozik, a per – az érintett választása szerint – az érintett lakhelye vagy tartózkodási helye szerinti törvényszék előtt is megindítható. Külföldi állampolgár a lakóhelye szerint illetékes felügyeleti hatósághoz is fordulhat panasszal.&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:17.75pt; margin-bottom:6.5pt; line-height:106%">
            <strong>Kérjük Önt, hogy mielőtt a felügyeleti hatósághoz vagy bírósághoz fordulna panaszával – egyeztetés és a felmerült probléma minél gyorsabb megoldása érdekében – keresse meg Társaságunkat. </strong>&#xa0;
        </p>
        <p style="margin-right:0pt; margin-left:0.7pt; margin-bottom:8.45pt; text-indent:0pt; text-align:left; line-height:108%">
            &#xa0; 
        </p>
        <h1 style="text-align:left">
            7.<span style="width:7.4pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span>Melyek a főbb irányadó jogszabályok tevékenységünkre?&#xa0; 
        </h1>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:6.65pt; text-indent:-18pt; line-height:94%">
            <span style="font-family:Wingdings; font-size:10pt"></span><span style="width:10.06pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="line-height:94%; font-size:11pt">a természetes személyeknek a személyes adatok kezeléséről szóló az Európai Parlament és a Tanács (EU) 2016/679 rendelete (GDPR)</span>&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:0.3pt; text-indent:-18pt">
            <span style="font-family:Wingdings; font-size:10pt"></span><span style="width:10.06pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>az információs önrendelkezési jogról és az információszabadságról szóló 2011. évi CXII. 
        </p>
        <p style="margin-right:0pt; margin-left:37.2pt">
            törvény - (Info tv.)&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; text-indent:-18pt">
            <span style="font-family:Wingdings; font-size:10pt"></span><span style="width:10.06pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>a Polgári Törvénykönyvről szóló 2013. évi V. törvény (Ptk.)&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; text-indent:-18pt">
            <span style="font-family:Wingdings; font-size:10pt"></span><span style="width:10.06pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>az elektronikus kereskedelmi szolgáltatások, valamint az információs társadalommal összefüggő szolgáltatások egyes kérdéseiről szóló 2001. évi CVIII. törvény - (Eker tv.)&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; text-indent:-18pt">
            <span style="font-family:Wingdings; font-size:10pt"></span><span style="width:10.06pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>az elektronikus hírközlésről szóló 2003. évi C. törvény - (Ehtv)&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; text-indent:-18pt">
            <span style="font-family:Wingdings; font-size:10pt"></span><span style="width:10.06pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>a fogyasztóvédelemről szóló 1997. évi CLV. törvény (Fogyv tv.)&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:6.65pt; text-indent:-18pt; line-height:94%">
            <span style="font-family:Wingdings; font-size:10pt"></span><span style="width:10.06pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span><span style="line-height:94%; font-size:11pt">a panaszokról és a közérdekű bejelentésekről szóló 2013. évi CLXV. törvény. (Pktv.)</span>&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:36.7pt; margin-bottom:0.3pt; text-indent:-18pt">
            <span style="font-family:Wingdings; font-size:10pt"></span><span style="width:10.06pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0;&#xa0;&#xa0; </span>a gazdasági reklámtevékenység alapvető feltételeiről és egyes korlátairól szóló 2008.&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:37.2pt">
            évi XLVIII. törvény (Grtv.)&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:0.7pt; margin-bottom:8.5pt; text-indent:0pt; text-align:left; line-height:108%">
            &#xa0; 
        </p>
        <h1 style="text-align:left">
            8.<span style="width:7.4pt; font:7pt 'Times New Roman'; display:inline-block">&#xa0;&#xa0;&#xa0;&#xa0; </span>Adatkezelési tájékoztató módosítása&#xa0; 
        </h1>
        <p style="margin-right:0pt">
            Társaságunk fenntartja magának a jogot jelen Adatkezelési tájékoztató módosítására, amelyről az érintetteket megfelelő módon tájékoztatja. Az adatkezeléssel kapcsolatos információk közzététele a <em>www.mavdtc.xyz/adatkezeles </em>weboldalon történik.&#xa0; 
        </p>
        <p style="margin-right:0pt; margin-left:0.7pt; margin-bottom:4.95pt; text-indent:0pt; text-align:left; line-height:108%">
            <span style="color:#ff0000">&#xa0;</span> 
        </p>
        <p style="margin-right:0pt">
            <em>Budapest, 2024. 06. 03.</em>&#xa0; 
        </p>
    </article>
    </div>