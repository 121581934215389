import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError, timeout } from 'rxjs/operators';
import { environment } from './../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BackendService {
  lastTrainNumbers = [];
  lastStations = [];
  munkakorok = [
    'N/A',
    'Automata',
    'Egysoros',
    'Nemzetközi info',
    'Ügyfélszolgálat',
    'Állomási kerékpáros',
    'Nemzetközi host',
    'Táblázó',
    'Vízosztó',
    'Vágányzári info',
    'Állomási info',
    'Pénztári segítő',
    'Egyéb'
  ]
  jarmuHibak = [
    'N/A',
    'Hibás hangosítás',
    'Hibás feljáróajtó',
    'Hibás szakaszajtó',
    'Hibás világítás',
    'Hibás fűtés/klíma',
    'Hibás energiaellátás',
    'Hibás WC',
    'Egyéb hiba',
    'Hibás homlokajtó',
    'Hibás utastájékoztatás',
    'Hibás rakodóajtó'
  ]
  hibajegyStatuszok = [
    'Nincs megoldva',
    'Megoldva',
    'Érvénytelen hibajegy'
  ]
  megoldasOkok = [
    'A hiba nem jelentkezett',
    'A hiba ismert módon elhárítva',
    'A hiba ismertlen módon elhárítva'
  ]

  muveletek = [
    'Létrehoz',
    'Megold',
    'Megerősít',
    'Módosít'
  ]
  
  appVersion = {  
    num: '220619',
    branch: environment.production ?  'PROD' : 'DEV'
  }
  gotLoggedOut: boolean = false;
  loggedOutMsg: string = '';

  constructor(private http: HttpClient) { }

  addLastTrainNumber(vonat: {vonatszam: number, viszonylat: string}) {
    this.lastTrainNumbers = JSON.parse(localStorage.getItem('lastTrainNumbers'));
    //console.log(this.lastTrainNumbers.some(element => element.vonatszam === vonat.vonatszam));
    //console.log(this.lastTrainNumbers.some((element) => {element['vonatszam'].toString() === vonat.vonatszam.toString()}));
    if(this.lastTrainNumbers && !this.lastTrainNumbers.some(element => element.vonatszam === vonat.vonatszam)) {
      if(this.lastTrainNumbers.length == 10) {
        this.lastTrainNumbers.pop();
      }
      this.lastTrainNumbers.unshift(vonat);
    }
    else if(!this.lastTrainNumbers) {
      this.lastTrainNumbers = [];
    }
    localStorage.setItem('lastTrainNumbers', JSON.stringify(this.lastTrainNumbers));
  }

  getLastTrainNumbers() {
    return JSON.parse(localStorage.getItem('lastTrainNumbers'));
  }

  addLastStation(stationName: string) {
    this.lastStations = JSON.parse(localStorage.getItem('lastStations'));
    if(this.lastStations) {
      if(this.lastStations.length == 10) {
        this.lastStations.pop();
      }
    }
    else {
      this.lastStations = [];
    }
    this.lastStations.unshift(stationName);
    localStorage.setItem('lastStations', JSON.stringify(this.lastStations));
  }

  getLastStations() {
    return JSON.parse(localStorage.getItem('lastStations'));
  }

  authUser(username, password) {
    return this.http.post("https://nemzetijb.hu:3243/login", {
      username: username,
      password: password
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  regUser(username, password, email, fullname, telefon) {
    return this.http.post("https://nemzetijb.hu:3243/register", {
      username: username,
      password: password,
      email: email,
      fullname: fullname,
      telefon: telefon
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  chooseForda(username, szolgTipus, ertek1, ertek2, szolgalatVege = '', telephely = 'Budapest') {
    if(szolgTipus == '1') {
      return this.http.post("https://nemzetijb.hu:3243/chooseForda", {
        username: username,
        ujStatusz: szolgTipus,
        allomasKod: ertek1,
        munkakorKod: ertek2,
        szolgVege: szolgalatVege
      })
      .pipe(
        timeout(12000),
        catchError(this.handleError)
      )
    }
    else if(szolgTipus == '2') {
      return this.http.post("https://nemzetijb.hu:3243/chooseForda", {
        username: username,
        ujStatusz: szolgTipus,
        fordatipus: ertek1,
        fordaszam: ertek2,
        telephely: telephely
      })
      .pipe(
        timeout(12000),
        catchError(this.handleError)
      )
    }
    else if(szolgTipus == '-1') {
      return this.http.post("https://nemzetijb.hu:3243/chooseForda", {
        username: username,
        logout: true
      })
      .pipe(
        timeout(12000),
        catchError(this.handleError)
      )
    }
  }

  getVonatinfo(vonatszam) {
    return this.http.post("https://nemzetijb.hu:3243/vonatinfo", {
      VonatID: vonatszam
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  getAllomasinfo(allomasNev, reqType) {
    return this.http.post("https://nemzetijb.hu:3243/allomasinfo", {
      Nev: allomasNev,
      reqType: reqType
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  getAllomasLista(onlyNames = 0) {
    return this.http.post("https://nemzetijb.hu:3243/getAllomasok", {
      onlyNames: onlyNames
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  getElviraAllomasinfo(allomasNev, reqType) {
    return this.http.post("https://nemzetijb.hu:3243/elvira/allomasinfo", {
      allomas: allomasNev,
      reqType: reqType
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  getElviraAllomasok() {
    return this.http.get('https://nemzetijb.hu:3243/elvira/getAllomasok')
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  getMavinform(hirID = null) {
    return this.http.post("https://nemzetijb.hu:3243/mavinform", {
     HirID: hirID
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  getFordaData(sign, telephely) {
    return this.http.post("https://nemzetijb.hu:3243/getFordaData", {
     Sign: sign,
     telephely: telephely
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  getSzemelyzetFromVonatszam(vonatszam) {
    return this.http.post("https://nemzetijb.hu:3243/getSzemelyzetFromVonatszam", {
      vonatszam: vonatszam
    })
    .pipe(
      timeout(4000),
      catchError(this.handleError)
    )
  }

  getSzemelyzetFromStation(allomasKod) {
    return this.http.post("https://nemzetijb.hu:3243/getSzemelyzetForStation", {
      allomasNev: allomasKod
    })
    .pipe(
      timeout(4000),
      catchError(this.handleError)
    )
  }

  getSzovegKonyvForTrain(vonatszam) {
    return this.http.post("https://nemzetijb.hu:3243/getSzovegkonyvForTrain", {
      vonatszam: vonatszam
    })
    .pipe(
      timeout(4000),
      catchError(this.handleError)
    )
  }

  saveBicycleCount(bicycleCounts, vonatszam, username) {
    return this.http.post("https://nemzetijb.hu:3243/saveBicycleCount", {
      bicycleCounts: bicycleCounts,
      trainnum: vonatszam,
      username: username
    })
    .pipe(
      timeout(4000),
      catchError(this.handleError)
    )
  }

  checkForda(username) {
    return this.http.post("https://nemzetijb.hu:3243/checkForda", {
      username: username
    })
    .pipe(
      timeout(4000),
      catchError(this.handleError)
    )
  }

  getVonatPozicio(vonatszam) {
    return this.http.post("https://nemzetijb.hu:3243/getVonatPozicio", {
      vonatszam: vonatszam
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  getMozdonyNev(vonatID) {
    return this.http.post("https://nemzetijb.hu:3243/getMozdonyNev", {
      vonatid: vonatID
    })
    .pipe(
      timeout(5000),
      catchError(this.handleError)
    )
  }

  getTerkepAdatok() {
    return this.http.post<[]>("https://nemzetijb.hu:3243/getTerkepAdatok", {
    })
    .pipe(
      timeout(10000),
      catchError(this.handleError)
    )
  }

  getVonal(VonatID) {
    return this.http.post<[]>("https://nemzetijb.hu:3243/getVonal", {
      VonatID: VonatID
    })
    .pipe(
      timeout(10000),
      catchError(this.handleError)
    )
  }

  
  checkOsszeallitas(vonatszam) {
    return this.http.post("https://nemzetijb.hu:3243/checkOsszeallitas", {
      vonatszam: vonatszam
    })
    .pipe(
      timeout(10000),
      catchError(this.handleError)
    )
  }

  checkSZVOR(vonatszam) {
    return this.http.post("https://nemzetijb.hu:3243/getSzerelvenyFromSZVOR", {
      vonatszam: vonatszam
    })
    .pipe(
      timeout(10000),
      catchError(this.handleError)
    )
  }

  checkVonatszam(vonatszam) {
    return this.http.post<Array<{}>>("https://nemzetijb.hu:3243/checkVonatszam", {
      vonatszam: vonatszam
    })
    .pipe(
      timeout(10000),
      catchError(this.handleError)
    )
  }

  addBusForForgalmi(rendszam, type, num) {
    return this.http.post("https://nemzetijb.hu:3243/addBusForForgalmi", {
      rendszam: rendszam,
      type: type,
      num: num
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  getBusFromForgalmi(type, num) {
    return this.http.post("https://nemzetijb.hu:3243/getBusFromForgalmi", {
      type: type,
      num: num
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  getBusFromRendszam(rendszam) {
    return this.http.post("http://jail.nemzetijb.hu:3000/getBusFromRendszam", {
      rendszam: rendszam
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  getBusFromIdo(ido) {
    return this.http.post("http://jail.nemzetijb.hu:3000/getBusFromIdo", {
      ido: ido
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  getAllTrains() {
    return this.http.post("http://jail.nemzetijb.hu:3000/getTrainFromVonatinfo", {

    })
    .pipe(
      timeout(15000),
      catchError(this.handleError)
    )
  }

  getLeaderboard(station: string) {
    return this.http.post<[]>('https://nemzetijb.hu:3243/leaderboard', {
      Nev: station
    })
    .pipe(
      timeout(15000),
      catchError(this.handleError)
    )
  }

  getUsers() {
    return this.http.post('https://nemzetijb.hu:3243/getUsers', {

    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  getUser(id) {
    return this.http.post('https://nemzetijb.hu:3243/getUser/' + id, {

    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  modifyUser(id, aktiv) {
    return this.http.post<string>('https://nemzetijb.hu:3243/modifyUser/' + id, {
      aktiv: aktiv
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  addSzoveg(vonatszam, allomaskod, tipus, magyar, angol, nemet) {
    return this.http.post('https://nemzetijb.hu:3243/addSzoveg/', {
      vonatszam: vonatszam,
      stationCode: allomaskod,
      type: tipus,
      hungarian: magyar,
      english: angol,
      german: nemet
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  getCustomText(vonatszam, allomaskod, tipus) {
    return this.http.post('https://nemzetijb.hu:3243/getCustomText', {
      trainnum: vonatszam,
      stationCode: allomaskod,
      type: tipus
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  setCustomText(vonatszam, allomaskod, tipus, szoveg) {
    return this.http.post('https://nemzetijb.hu:3243/setCustomText', {
      trainnum: vonatszam,
      stationCode: allomaskod,
      type: tipus,
      text: szoveg
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  getHibajegyek(azonosito) {
    return this.http.post('https://nemzetijb.hu:3243/getHibajegyek', {
      azonosito: azonosito
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  getHibajegyFromID(id) {
    return this.http.post('https://nemzetijb.hu:3243/getHibajegyek/' + id, {
      
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  addHibajegy(place, type, azonosito, rogzHely, reszletek) {
    return this.http.post('https://nemzetijb.hu:3243/addHibajegy', {
      place: place,
      type: type,
      azonosito: azonosito,
      rogzitesHelye: rogzHely,
      kepLink: '',
      reszletek: reszletek
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  editHibajegy(id, action, megoldasOka) {
    return this.http.post('https://nemzetijb.hu:3243/editHibajegy/' + id, {
      action: action,
      megoldasOka: megoldasOka
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  getHibajegyHistory(id) {
    return this.http.post('https://nemzetijb.hu:3243/getHibajegyHistory/' + id, {
      
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  getJarmuLista() {
    return this.http.post<[]>('https://nemzetijb.hu:3243/getJarmuLista', {
      
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  getJarmuFromUIC(UIC) {
    return this.http.post('https://nemzetijb.hu:3243/getJarmuFromUIC', {
      UIC: UIC
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  savePassengerCount(trainNumber, data) {
    return this.http.post('https://nemzetijb.hu:3243/savePassengerCount', {
      trainNumber: trainNumber,
      data: data
    })
    .pipe(
      timeout(30000),
      catchError(this.handleError)
    )
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Hiba történt:', error.error.message);
      return throwError(
        'A kérés teljesítése közben hálózati hiba lépett fel, próbáld újra!');
    } 
    else if(error.status === 400 || error.status === 401) {
      return throwError(
        `${error.error}`
      )
    }
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      return throwError(
        `A háttérrendszer hibája miatt a kérést nem sikerült teljesíteni, kérlek próbáld meg újra! (${error.status ? error.status : "Időtúllépés"})`);
    }
    // return an observable with a user-facing error message
    /*return throwError(
      'Hiba történt a kérés teljesítése közben, kérlek próbáld újra!');*/
  };

  checkTrainNumber(trainNumber) {
    return this.http.post("http://jail.nemzetijb.hu:3000/getTrainNumber", {
      trainNumber: trainNumber
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  sendBusData(buses, trainNumber) {
    return this.http.post("http://jail.nemzetijb.hu:3000/addBusData", {
      buses: buses,
      trainNumber: trainNumber
    })
    .pipe(
      timeout(12000),
      catchError(this.handleError)
    )
  }

  getAllBuses() {
    return this.http.get('http://jail.nemzetijb.hu:3000/getAllBuses')
      .pipe(
        timeout(12000),
        catchError(this.handleError)
      )
  }
}
