<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
  <article class="card-body">
    <h4 class="text-center">Offline szövegkönyv hangosítóknak</h4>
    <div *ngIf="szolgalatStatusz == '2' && (fordatipus == 'H' || fordatipus == 'HK' || fordatipus == 'UE' || fordatipus == 'RH')">
      <p *ngIf="szolgalatStatusz == '2' && (fordatipus == 'H' || fordatipus == 'HK' || fordatipus == 'UE' || fordatipus == 'RH')">Válaszd ki, melyik vonaton hangosítasz!</p>
      <p *ngIf="szolgalatStatusz == '2' && (fordatipus == 'H' || fordatipus == 'HK' || fordatipus == 'UE' || fordatipus == 'RH')">Hangosító - {{fordaszam}}. forda</p>
      <div (click)="goToTrain(fordulo.TrainNumber)" *ngFor="let fordulo of hangositoFordak" class="card text-white bg-primary mb-3" style="width: 100%">
        <div class="card-body" *ngIf="allStations">
          <h5 class="card-title"><i class="fa fa-train"></i> {{fordulo.TrainNumber}} sz. vonat</h5>
          <p class="card-text"><i class="fa fa-map-marker"></i> {{allStations['00' + fordulo.DepStation]}} - {{allStations['00' + fordulo.ArriveStation]}}
            <span class="float-right"><i class="fa fa-clock-o"></i> {{fordulo.Dep}}</span>
          </p>
        </div>
      </div>
    </div>
    <p *ngIf="fordatipus != 'HK' && fordatipus != 'H' && fordatipus != 'UE' && fordatipus != 'RH'">Ez a funkció csak a vonaton hangosítóknak érhető el.</p>
  </article>
</div>

<!--<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article class="card-body">
      <h4 class="text-center">Offline szövegkönyv</h4>
        <div (click)="goToTrain(vonat.id)" *ngFor="let vonat of szkonyv.vonatok" class="card text-white bg-primary mb-3" style="width: 100%">
          <div class="card-body">
            <h5 class="card-title"><i class="fa fa-train"></i> {{vonat.nev}} {{vonat.vonatnem}}</h5>
            <p class="card-text"><i class="fa fa-arrow-right"></i> {{vonat.vegallomas}}
              <!--<span class="float-right"><i class="fa fa-clock-o"></i> {{fordulo.Dep}}</span>-->
            <!--</p>
          </div>
        </div>
    </article>
  </div>-->