import { Component, OnInit, ViewChild } from '@angular/core';
import { BackendService } from '../backend.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-vonatinfo',
  templateUrl: './vonatinfo.component.html',
  styleUrls: ['./vonatinfo.component.css']
})
export class VonatinfoComponent implements OnInit {
  @ViewChild('content') datumModal;

  vonatinfoForm: FormGroup;
  szemelyzetForm: FormGroup;
  inProgress = false;
  success = false;
  wasError = false;
  wasErrorSz = false;
  submitted = false;
  isLoading = false;
  myTrain = <any>{};
  allStations = {};
  kozeliAllomas = {};
  mozdony = {};
  error = "";
  errorSz = "";
  closeResult: string;
  storageVonatinfo;
  lastTrainNums;
  possibleIDs;
  osszeallitasVonatszam = "";

  constructor(private formBuilder: FormBuilder, private data: BackendService, private modalService: NgbModal, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.lastTrainNums = this.data.getLastTrainNumbers();
    /*if(localStorage.getItem('vonatszam') != "") {
      this.isLoading = true;
      this.data.getVonatinfo(localStorage.getItem('vonatszam')).subscribe(data => {
        this.isLoading = false;
        this.myTrain = data;
        //localStorage.setItem('vonatszam', this.myTrain.Vonat.Szam)
      })
      this.data.getVonatPozicio(localStorage.getItem('vonatszam')).subscribe(data => {
        this.kozeliAllomas = data;
      })
      this.data.getMozdonyNev(localStorage.getItem('vonatid')).subscribe(data => {
        this.mozdony = data;
        //console.log(this.myTrain.Vonat.ID);
      })
    }*/

    this.vonatinfoForm = this.formBuilder.group({
      szam: ['', Validators.required]
    });

    this.szemelyzetForm = this.formBuilder.group({
      szam: ['', Validators.required]
    })
    
    /*this.data.getAllomasLista().subscribe(data => {
      this.allStations = data;
    }, error => {
      console.log(error);
    })*/
  }

  onSubmit() {
    this.submitted = true;
    this.inProgress = true;

    if (this.vonatinfoForm.invalid) {
      this.inProgress = false;
      console.log("Érvénytelen form!")
      return;
    }

    this.data.checkVonatszam(this.vonatinfoForm.controls.szam.value).subscribe(data => {
      if(data.length > 1) {
        this.possibleIDs = data;
        this.open(this.datumModal);
        this.submitted = false;
        this.inProgress = false;
        this.wasError = false;
        this.error = '';
      }
      else {
        this.router.navigate([data[0]['VonatID']], {relativeTo: this.route})
      }
    },
    error => {
      this.wasError = true;
      this.error = error;
      this.submitted = false;
      this.inProgress = false;
    })

    /*this.data.getVonatinfo(this.vonatinfoForm.controls.szam.value).subscribe(data => {
      this.myTrain = data;
      this.data.addLastTrainNumber({
        vonatszam: this.myTrain.Vonat.Szam,
        viszonylat: this.myTrain.Menetrend[0].AllomasNev + ' - ' + this.myTrain.Menetrend[this.myTrain.Menetrend.length - 1].AllomasNev
      });
      localStorage.setItem('vonatszam', this.myTrain.Vonat.Szam)
      //this.router.navigate([this.myTrain.Vonat.Szam], {relativeTo: this.route})
      /*localStorage.setItem('vonatid', this.myTrain.Vonat.ID)
      this.data.getVonatPozicio(localStorage.getItem('vonatszam')).subscribe(data => {
        this.kozeliAllomas = data;
      })
      this.data.getMozdonyNev(localStorage.getItem('vonatid')).subscribe(data => {
        this.mozdony = data;
      })*/
      
    /*}, error => {
      this.wasError = true;
      this.error = error;
      this.submitted = false;
      this.inProgress = false;
    })*/
  }

  onSubmitSz() {
    this.submitted = true;
    this.inProgress = true;

    if (this.szemelyzetForm.invalid) {
      this.inProgress = false;
      console.log("Érvénytelen form!")
      return;
    }

    this.router.navigate(['/szemelyzet', 'vonat', this.szemelyzetForm.controls.szam.value])
  }

  ugrasSzemelyzetre() {
    this.modalService.dismissAll('Szemelyzet click');
  }

  goToOsszeallitas() {
    if(this.osszeallitasVonatszam != "") {
      this.router.navigate(['/osszeallitas', this.osszeallitasVonatszam]);
    }
  }

  resetVonatszam() {
    localStorage.removeItem('vonatszam');
    localStorage.removeItem('vonatid');
    this.myTrain = <any>{};
    this.mozdony = {};
    this.kozeliAllomas = {};
    this.inProgress = false;
    this.success = false;
    this.wasError = false;
    this.submitted = false;
  }

  displayViszonylatszam(fontId) {
    if(fontId) {
      return String.fromCharCode(fontId)
    }
    else {
      return '';
    }
    
  }

  checkColor(erkM, erkT, erkMSz, erkTSz) {
    if(erkM < erkT && erkMSz != erkTSz) {
      return "red";
    }
    else {
      return "green";
    }
  }

  getTenyIdoFromKeses(erkTenyDatum, erkMDatum, Keses) {
    if(erkTenyDatum != null) {
      return erkTenyDatum * 1000;
    }
    else {
      var becsult = erkMDatum + (Keses * 60);
      return becsult * 1000;
    }
  }

  goToEarlierNumber(vonatszam) {
    this.data.checkVonatszam(vonatszam).subscribe(data => {
      if(data.length > 1) {
        this.possibleIDs = data;
        this.open(this.datumModal);
      }
      else {
        this.router.navigate([data[0]['VonatID']], {relativeTo: this.route})
      }
    },
    error => {
      this.wasError = true;
      this.error = error;
      this.submitted = false;
      this.inProgress = false;
    })
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
