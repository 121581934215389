import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BackendService } from 'src/app/backend.service';
import { ActivatedRoute } from '@angular/router';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';

@Component({
  selector: 'app-newticket',
  templateUrl: './newticket.component.html',
  styleUrls: ['./newticket.component.css']
})
export class NewticketComponent implements OnInit {
  ujHibaForm: FormGroup;
  azonosito;
  submitted = false;
  inProgress = false;
  wasError = false;
  error = "";
  message = "";
  success = false;
  public model: any;
  states = [];

  constructor(public data: BackendService, private formBuilder: FormBuilder, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.azonosito = this.route.snapshot.params['azon'];
    this.ujHibaForm = this.formBuilder.group({
      hibaTipus: [null, Validators.required],
      hibaHely: ['', Validators.required],
      hibaReszletek: ['', Validators.required],
    });
    this.data.getAllomasLista(1).subscribe(data => {
      Object.values(data).forEach(function(value) {
        this.states.push(value);
      }.bind(this))
    }, error => {
      console.log(error);
    })
  }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  onSubmit() {
    this.submitted = true;
    this.inProgress = true;
    this.wasError = false;
    this.success = false;

    if (this.ujHibaForm.invalid) {
      this.inProgress = false;
      console.log("Érvénytelen form!")
      return;
    }

    this.data.addHibajegy(2, this.ujHibaForm.controls.hibaTipus.value, this.azonosito, this.ujHibaForm.controls.hibaHely.value, this.ujHibaForm.controls.hibaReszletek.value).subscribe(data => {
      this.inProgress = false;
      this.submitted = false;
      this.ujHibaForm.reset();
      this.success = true;
    },
    error => {
      this.inProgress = false;
      this.wasError = true;
      this.error = error;
    })
  }

}
