import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BackendService } from 'src/app/backend.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';

@Component({
  selector: 'app-szerkeszt',
  templateUrl: './szerkeszt.component.html',
  styleUrls: ['./szerkeszt.component.css']
})
export class SzerkesztComponent implements OnInit {
  vonatszam;
  szovegForm: FormGroup;
  submitted = false;
  inProgress = false;
  wasError = false;
  finished = false;
  message;
  states = [];
  model;

  constructor(private route: ActivatedRoute, private formBuilder: FormBuilder, private data: BackendService) { }

  search = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 2 ? []
      : this.states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )

  ngOnInit(): void {
    this.vonatszam = this.route.snapshot.params['szam'];
    this.szovegForm = this.formBuilder.group({
      allomas: ['', Validators.required],
      tipus: ['', Validators.required],
      magyar: ['', Validators.required],
      angol: [''],
      nemet: ['']
    });
    this.data.getAllomasLista(1).subscribe(data => {
      Object.values(data).forEach(function(value) {
        this.states.push(value);
      }.bind(this))
    }, error => {
      console.log(error);
    })
  }

  onSubmit() {
    this.submitted = true;
    this.inProgress = true;
    this.wasError = false;
    this.finished = false;

    if (this.szovegForm.invalid) {
      this.inProgress = false;
      console.log("Érvénytelen form!")
      return;
    }

    this.data.getAllomasinfo(this.szovegForm.controls.allomas.value, 'fromNow').subscribe(data => {
      let allomaskod = data['AllomasID'];
      this.data.addSzoveg(this.vonatszam, allomaskod, this.szovegForm.controls.tipus.value, this.szovegForm.controls.magyar.value, this.szovegForm.controls.angol.value, this.szovegForm.controls.nemet.value).subscribe(response => {
        this.inProgress = false;
        this.submitted = false;
        this.finished =  true;
        this.message = 'A szövegkönyvi bejegyzés sikeresen rögzítve!';
        this.szovegForm.reset();
      },
      error => {
        this.finished = true;
        this.inProgress = false;
        this.submitted = false;
        this.message = error;
        this.wasError = true;
      })
    },
    error => {
        this.finished = true;
        this.inProgress = false;
        this.submitted = false;
        this.message = error;
        this.wasError = true;
    })
  }

}
