<div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article class="card-body">
     <h2>DTC alkalmazás letöltése</h2>
     <p>
         Az alkalmazás jelenleg Android és iOS rendszerre telepíthető. A DTC használata teljeskörűen csak az alkalmazásban lehetséges, mivel a webes verzió már nem támogatott.</p>
         <p>A legfrissebb verzió: <b>2.0.1</b></p>
         <h3>Android</h3>
         <!--<div class="alert alert-danger">
            <i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i><b>Figyelem!</b><br> Androidon korábbi verziókról 1.3.2-re frissítés esetén a Play Protect miatt előfordulhat, hogy nem sikerül az alkalmazás telepítése. Ebben az esetben a korábbi telepített verziót törölni kell, és utána telepíthető az új.
         </div>-->
         <p>Az alkalmazás kézzel telepíthető, az alább letölthető .apk fájl segítségével. Telepítéskor a rendszer kérdezheti, hogy engedélyezed-e a külső forrásból telepített alkalmazásokat, valamint a Play Store Protect is figyelmeztethet veszélyre, ezeket jelen esetben figyelmen kívül kell hagyni.</p>
         <p>Ha a 32-bites verzió telepítése nem sikerülne, mert azt jelzi a telefon, hogy nem kompatibilis, akkor érdemes megpróbálni a 64-bites verzió telepítését.</p>
         <a href="https://www.mavdtc.xyz/fdroid/apk/2.0.1/dtcapp-armeabi-v7a-v201.apk"><button class="btn btn-lg btn-success"><i class="fa fa-android"></i> Legfrissebb verzió letöltése - 32-bit (.apk)</button></a>
         <br>
         <div style="margin: 20px;"></div>
         <a href="https://www.mavdtc.xyz/fdroid/apk/2.0.1/dtcapp-arm64-v8a-v201.apk"><button class="btn btn-lg btn-success"><i class="fa fa-android"></i> Legfrissebb verzió letöltése - 64-bit (.apk)</button></a>
         <hr>
         <h3>iOS</h3>
         <p>Az alkalmazás nektek köszönhetően a 2.0.0 verziótól kezdve egyszerűen letölthető az App Store-ból:</p>
         <a href="https://apps.apple.com/app/dtc/id6504115543"><img src="../../assets/appstore-badge-HU.svg"></a>
         <!--<p>Az alkalmazás telepítéséhez a következőkre van szükség: számítógép, USB kábel, Apple ID, iCloud Drive, AltServer szoftver, az alkalmazás letöltött .ipa fájlja</p>
         <p>Ha frissítés érkezik az alkalmazáshoz, és korábban már feltelepítettük az alkalmazást, akkor elég a 9. lépéstől, a letöltött .ipa fájl felmásolásától elvégezni a telepítést.</p>
         <p>Lépések:</p>
         <ol>
             <li>Csatlakoztassuk a telefonunkat a számítógéphez (a szükséges engedélyeket a telefonon adjuk meg)</li>
             <li>Telepítsük fel az iCloud-ot a számítógépünkre, a linken a "Ha nem sikerül elérni a Microsoft Store-t" szöveg alatt a megfelelő verzióra (32/64 bit) kattintva. (<a href="https://support.apple.com/hu-hu/HT210384">Letöltés</a>) Fontos, hogy nem a Microsoft Store-os verziót, hanem a hagyományos telepítős verziót kell letölteni.</li>
             <li>Lépjünk be az Apple ID-nkkal az iCloud-ba, és végezzük el a felkínált beállításokat</li>
             <li>Töltsük le az AltServer szoftvert innen: <a href="https://altstore.io/">letöltés</a></li>
             <li>A letöltött zip fájlt csomagoljuk ki, és indítsuk el a setup.exe fájlt. A telepítés folyamata egyértelmű, telepítés után a program a tálcára kerülve elindul. Ez a program szolgál arra, hogy a telefonunkra külső forrásból tudjunk alkalmazást telepíteni. Ennek az a korlátja, hogy az így telepített alkalmazások csak 7 napig érvényesek, ez után meg kell újítani. A most telepített program ezt a megújítást elvégzi helyettünk, amennyiben a számítógépen fut az AltServer program, és egy hálózaton/wifin van a telefonunkkal.</li>
             <li>A tálcán bal gombbal a program ikonjára (szürke rombusz) kattintva válasszuk az "Install AltStore" opciót, a felugró menüből pedig a telefonunk nevét.</li>
             <li>A felugró ablakban adjuk meg az Apple ID-nk belépési adatait, ezzel fogja aláírni ugyanis a program a telepítendő alkalmazásokat.</li>
             <li>Ha mindent jól csináltunk, a telefonunkon megjelent az AltStore alkalmazás, és meg is tudjuk nyitni. Ha a "Nem megbízható fejlesztő" üzenettel találkozunk, akkor a Beállítások->Általános->Profilok és eszközfelügyelet menüben engedélyezzük a fejlesztői profilunkat.</li>
             <li>A letöltött .ipa fájlt a számítógépünkön másoljuk fel az iCloud Drive-unkba. Ezt legegyszerűbben a Windows Intéző megnyitása után, a bal oldali gyors elérésnél található iCloud Drive ikonra kattintva tudjuk megtenni. (Alternatív megoldásként az iCloud webes felületén, az icloud.com-on is feltölthető a fájl.)</li>
             <li>Másolás után nincs más hátra, mint megnyitni a telefonunkon az AltStore alkalmazást, az alsó sávon a "My Apps" fül választása után pedig a bal felső sarokban található kis plusz ikonra kattintani. Itt válasszuk ki az iCloud Drive-unkba korábban feltöltött .ipa fájlt, és várjuk meg a telepítés végét.</li>
             <li>Ha kész, akkor a fő képernyőn megjelenik a DTC alkalmazás. A hátralévő napokat az AltStore-ban lehet követni, és innen lehet manuálisan frissíteni is, ha nincs lehetőség az automatikus frissítésre.</li>
         </ol>
         <a href="https://www.mavdtc.xyz/fdroid/apk/1.3.3/dtcapp_v133.ipa"><button class="btn btn-lg btn-success"><i class="fa fa-apple"></i> Legfrissebb verzió letöltése (.ipa)</button></a>-->
    </article>
    </div>