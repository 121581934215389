import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/backend.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-allomas-szemelyzet',
  templateUrl: './allomas-szemelyzet.component.html',
  styleUrls: ['./allomas-szemelyzet.component.css']
})
export class AllomasSzemelyzetComponent implements OnInit {
  szemelyzet;
  allStations = {};
  wasError = false;
  error = "";
  allomasNev;
  isLoading = true;

  constructor(private data: BackendService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.allomasNev = this.route.snapshot.params['allomasnev'];
    if(this.allomasNev != "") {
      this.data.getSzemelyzetFromStation(this.allomasNev).subscribe(data => {
        this.szemelyzet = data;
        this.isLoading = false;
        console.log(this.szemelyzet)
      }, error => {
        this.isLoading = false;
        this.wasError = true;
        this.error = error;
      })
    }
    else {
      this.isLoading = false;
      this.wasError = true;
      this.error = "Nem került megadásra állomás."
    }
    
    this.data.getAllomasLista().subscribe(data => {
      this.allStations = data;
    }, error => {
      console.log(error);
    })
  }

}
