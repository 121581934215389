import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { map, catchError, timeout } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, public JwtHelper: JwtHelperService) { }

  login(username: string, password: string) {
    return this.http.post<{token: string, refreshToken: string}>('https://nemzetijb.hu:3243/login', {username: username, password: password})
      .pipe(
        timeout(3000),
        map(result => {
          localStorage.setItem('access_token', result.token);
          localStorage.setItem('refresh_token', result.refreshToken);
        }),
        catchError(this.handleError)
      )
  }

  getUserData() {
    if(this.loggedIn) {
      let userdata = this.JwtHelper.decodeToken(localStorage.getItem('access_token'));
      return userdata;
    }
  }

  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
  }

  public get loggedIn(): boolean {
    if(this.JwtHelper.isTokenExpired(localStorage.getItem('access_token'))) {
      return false;
    }
    else {
      return true;
    }
    //return (localStorage.getItem('access_token') !== null);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('Hiba történt:', error.error.message);
      return throwError(
        'A kérés teljesítése közben hálózati hiba lépett fel, próbáld újra!');
    } 
    else if(error.status === 400) {
      return throwError(
        `${error.error}`
      )
    }
    else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
      return throwError(
        `A háttérrendszer hibája miatt a kérést nem sikerült teljesíteni, kérlek próbáld meg újra! (${error.status ? error.status : "Időtúllépés"})`);
    }
    // return an observable with a user-facing error message
    /*return throwError(
      'Hiba történt a kérés teljesítése közben, kérlek próbáld újra!');*/
  };
}
