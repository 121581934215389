import { Injectable } from '@angular/core';
import { IVonat } from './IVonat';

@Injectable({
  providedIn: 'root'
})
export class SzovegkonyvService {

  vonatok: IVonat[] = [
    {
      id: 1,
      nev: 'BALATON',
      vonatnem: 'InterCity',
      irany: 'Budapest',
      induloallomas: 'Keszthely',
      vegallomas: 'Budapest-Déli',
      friendlyVegallomas: 'Budapest-Déli pályaudvar',
      etkezo: true,
      megallasirendId: 0
    },
    {
      id: 2,
      nev: 'BALATON',
      vonatnem: 'InterCity',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Keszthely',
      friendlyVegallomas: 'Keszthely',
      etkezo: true,
      megallasirendId: 0
    },
    {
      id: 3,
      nev: 'TÓPART',
      vonatnem: 'InterCity',
      irany: 'Budapest',
      induloallomas: 'Nagykanizsa',
      vegallomas: 'Budapest-Déli',
      friendlyVegallomas: 'Budapest-Déli pályaudvar',
      etkezo: false,
      megallasirendId: 1
    },
    {
      id: 4,
      nev: 'TÓPART',
      vonatnem: 'InterCity',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Nagykanizsa',
      friendlyVegallomas: 'Nagykanizsa',
      etkezo: false,
      megallasirendId: 1
    },
    {
      id: 5,
      nev: 'DÉLI<-PARTI',
      vonatnem: 'sebesvonat',
      irany: 'Budapest',
      induloallomas: 'Balatonszentgyörgy',
      vegallomas: 'Budapest-Déli',
      friendlyVegallomas: 'Budapest-Déli pályaudvar',
      etkezo: false,
      megallasirendId: 2,
      szakaszhatar: 'Székesfehérvár'
    },
    {
      id: 6,
      nev: 'DÉLI->PARTI',
      vonatnem: 'sebesvonat',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Balatonszentgyörgy',
      friendlyVegallomas: 'Balatonszentgyörgy',
      etkezo: false,
      megallasirendId: 2,
      szakaszhatar: 'Székesfehérvár'
    },
    {
      id: 7,
      nev: 'TISZA-TÓ',
      vonatnem: 'Expresszvonat',
      irany: 'Balaton',
      induloallomas: 'Budapest-Keleti',
      vegallomas: 'Tiszafüred',
      friendlyVegallomas: 'Tiszafüred',
      etkezo: false,
      megallasirendId: 3
    },
    {
      id: 8,
      nev: 'TISZA-TÓ',
      vonatnem: 'Expresszvonat',
      irany: 'Budapest',
      induloallomas: 'Tiszafüred',
      vegallomas: 'Budapest-Keleti',
      friendlyVegallomas: 'Budapest-Keleti pályaudvar',
      etkezo: false,
      megallasirendId: 3
    },
    {
      id: 9,
      nev: 'KÉK HULLÁM',
      vonatnem: 'sebesvonat',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Tapolca',
      friendlyVegallomas: 'Tapolca',
      etkezo: false,
      megallasirendId: 4,
      szakaszhatar: 'Balatonfüred'
    },
    {
      id: 10,
      nev: 'KÉK HULLÁM',
      vonatnem: 'sebesvonat',
      irany: 'Budapest',
      induloallomas: 'Tapolca',
      vegallomas: 'Budapest-Déli',
      friendlyVegallomas: 'Budapest-Déli pályaudvar',
      etkezo: false,
      megallasirendId: 4,
      szakaszhatar: 'Balatonfüred'
    }
  ]

  megallasok = [
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Siófok',
      'Zamárdi',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyód',
      'Balatonfenyves',
      'Balatonmáriafürdő',
      'Balatonberény',
      'Balatonszentgyörgy',
      'Keszthely'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Lepsény',
      'Siófok',
      'Zamárdi',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyód',
      'Balatonfenyves',
      'Balatonmáriafürdő',
      'Balatonszentgyörgy',
      'Vörs',
      'Zalakomár',
      'Zalaszentjakab',
      'Nagykanizsa'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Érd alsó',
      'Székesfehérvár',
      'Székesfehévár-Repülőtér',
      'Szabadbattyán',
      'Kiscséripuszta',
      'Polgárdi-Tekerespuszta',
      'Lepsény',
      'Balatonaliga',
      'Balatonvilágos',
      'Szabadisóstó',
      'Szabadifürdő',
      'Siófok',
      'Balatonszéplak felső',
      'Balatonszéplak alsó',
      'Zamárdi felső',
      'Zamárdi',
      'Szántód',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle felső',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyódliget',
      'Fonyód',
      'Bélatelep',
      'Alsóbélatelep',
      'Balatonfenyves',
      'Balatonfenyves alsó',
      'Máriahullámtelep',
      'Máriaszőlőtelep',
      'Balatonmáriafürdő',
      'Balatonberény',
      'Balatonszentgyörgy'
    ],
    [
      'Budapest-Keleti',
      'Gödöllő',
      'Füzesabony',
      'Poroszló',
      'Tiszafüred'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Balatonalmádi',
      'Balatonfüred',
      'Aszófő',
      'Örvényes',
      'Balatonudvari',
      'Fövenyes',
      'Balatonakali-Dörgicse',
      'Zánkafürdő',
      'Zánka-Köveskál',
      'Balatonszepezd',
      'Szepezdfürdő',
      'Révfülöp',
      'Balatonrendes',
      'Ábrahámhegy',
      'Badacsonyörs',
      'Badacsonytomaj',
      'Badacsony',
      'Badacsonylábdihegy',
      'Badacsonytördemic-Szigliget',
      'Nemesgulács-Kisapáti',
      'Tapolca'
    ]
  ]

  elagazoAllomasok = [
    'Budapest-Kelenföld',
    'Székesfehérvár',
    'Siófok',
    'Fonyód',
    'Balatonszentgyörgy',
    'Balatonfüred',
    'Révfülöp'
  ]

  angolVonatnem = {
    'Expresszvonat': 'express train',
    'sebesvonat': 'semi-fast train',
    'gyorsvonat': 'fast train',
    'InterCity': 'InterCity train',
    'személyvonat': 'passenger train'
  }

  constructor() { }

  getMegallasiHelyek(id) {
    return this.megallasok[id].slice();
  }
}
