<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Rendkívüli bemondások</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <select class="form-control" [(ngModel)]="kivalasztottSzoveg">
      <option [ngValue]="null">Válassz bemondást!</option>
      <option *ngFor="let bemondas of kulonlegesSzovegek | keyvalue" [value]="bemondas.key" >{{bemondas.value.megnevezes}}</option>
    </select>
    <div *ngIf="kivalasztottSzoveg != null">
      <hr>
      <b>{{kulonlegesSzovegek[kivalasztottSzoveg].megnevezes}}</b>
      <p>🇭🇺 <span [innerHTML]="kulonlegesSzovegek[kivalasztottSzoveg].magyar"></span></p>
      <p>🇬🇧 <span [innerHTML]="kulonlegesSzovegek[kivalasztottSzoveg].angol"></span></p>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">OK</button>
  </div>
</ng-template>

<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
  <article *ngIf="!myTrain && isLoading" class="card-body">
    <p style="color: white;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Szövegkönyv betöltése, kis türelmet...</p>
  </article>
  <article *ngIf="wasError" class="card-body">
    <div class="alert alert-danger" role="alert">
      A szövegkönyv betöltése sajnos nem sikerült:<br>
      {{errorMsg}}
    </div>
  </article>
  <div id="refresh">
    <article *ngIf="myTrain" class="card-body">
      <h4 class="text-center">{{myTrain.Vonat.Szam}} sz. vonat szövegkönyve</h4>
      <div (click)="jumpToTrain()" [ngStyle]="{'background-color': 'white', 'color': 'black', 'padding': '5px', 'margin-bottom': '8px', 'overflow': 'hidden', 'width': '100%'}">
        <p><b>{{myTrain.Vonat.Szam}}</b><span [ngStyle]="{'color': myTrain.Vonat.Tipus[0].Szin}"> {{myTrain.Vonat.Nev}} {{myTrain.Vonat.Tipus[0].Vonatnem}}</span> <span *ngIf="myTrain.Vonat.ViszonylatJeloles.Jel"> ({{myTrain.Vonat.ViszonylatJeloles.Jel}})</span>
        <span *ngIf="myTrain.Vonat.Info && myTrain.Vonat.Info.Keses" [style.color]="myTrain.Vonat.Info.Keses > 0 ? 'red' : 'green'" style="float: right; clear: both; font-size: 48px; font-style: italic">{{myTrain.Vonat.Info.Keses}}'</span><span *ngIf="!myTrain.Vonat.Info?.Keses && myTrain.Vonat.Kozlekedik" style="float: right; font-size: 48px; font-style: italic; color: green;">0'</span></p>
        ({{myTrain.Menetrend[0].AllomasNev}} - {{myTrain.Menetrend[myTrain.Menetrend.length - 1].AllomasNev}})
        <span *ngIf="myTrain.Vonat.Kozlekedik && kozeliAllomas.longName" style="float: right; clear: both;"><i class="fa fa-map-marker"></i> {{kozeliAllomas.longName}}</span>
        <span *ngIf="myTrain.Vonat.Kozlekedik && !kozeliAllomas.longName" style="float: right; clear: both;"><i class="fa fa-map-marker"></i> Nincs adat</span>
        <span *ngIf="myTrain.Vonat.Kozlekedik" style="float: right; clear: both;"><i class="fa fa-arrow-right"></i> {{myTrain.Vonat.kovetkezoAllomas}}</span>
        <div *ngIf="myTrain.Vonat.Info && myTrain.Vonat.Info.KesesiOk" style="background-color: red; color: white; font-weight: bold; padding-left: 2px; clear: both;">{{myTrain.Vonat.Info.KesesiOk}}</div>
        <div *ngIf="myTrain.Vonat.Info && myTrain.Vonat.Info.HavariaInfo">
            <div *ngFor="let havaria of myTrain.Vonat.Info.HavariaInfo"  style="background-color: red; color: white; font-weight: bold; padding-left: 2px; clear: both;">{{havaria}}</div>
        </div>
        <div *ngIf="!myTrain.Vonat.Kozlekedik && !checkLekozlekedett()"><i>A vonat jelenleg nem közlekedik.</i></div>
        <div *ngIf="checkLekozlekedett()"><i>A vonat már leközlekedett.</i></div>
      </div>
      <!-- amik itt kellenek:
        * állomás, amihez a bemondás köthető
        * a bemondás menetrend szerinti ideje (indulás előtt 5 perccel: indulási idő - 5 perc, indulás után azonnal: indulási idő, érkezés előtt 4-5 perccel, érkezési idő - 4 perc)
        * a bemondás valós ideje, ha van hozzá adat
      -->
      <!--<div *ngIf="!generaltSzovegek[currentIndex]">
        <div class="alert alert-danger" role="alert">
          A funkció még nem elérhető. Ehhez a vonathoz nem tartozik szövegkönyv. 
        </div>
      </div>-->
      <div *ngIf="generaltSzovegek[currentIndex]" style="background-color: #4287f5; padding: 10px; border-radius: 3px;">
        <i class="fa fa-map-marker"></i> {{generaltSzovegek[currentIndex].stationName}}
        <span style="float: right; clear: both;"><i class="fa fa-clock-o"></i> {{getIdoForBemondas(generaltSzovegek[currentIndex].stationId, generaltSzovegek[currentIndex].type)}} <span *ngIf="myTrain.Vonat.Kozlekedik" style="color: red; font-style: italic;">({{getValosIdoForBemondas(generaltSzovegek[currentIndex].stationId, generaltSzovegek[currentIndex].type)}})</span></span>
        <p><span>{{szovegTipusok[generaltSzovegek[currentIndex].type]}}</span></p>
        <p><b>🇭🇺 - Magyar szöveg</b></p>
        <p>{{generaltSzovegek[currentIndex].hungarian}}</p>
        <p *ngIf="generaltSzovegek[currentIndex].english"><b>🇬🇧 - Angol szöveg</b></p>
        <p>{{generaltSzovegek[currentIndex].english}}</p>
        <p *ngIf="generaltSzovegek[currentIndex].german"><b>🇩🇪 - Német szöveg</b></p>
        <p>{{generaltSzovegek[currentIndex].german}}</p>
        <p *ngIf="myTrain.Vonat.Info?.Keses >= 5 && generaltSzovegek[currentIndex].type != 0"><b>Késés bemondása</b></p>
        <p *ngIf="myTrain.Vonat.Info?.Keses >= 5 && generaltSzovegek[currentIndex].type != 0">🇭🇺 Felhívjuk szíves figyelmüket, hogy járatunk {{generaltSzovegek[currentIndex].stationName}} állomásra <i>{{myTrain.Vonat.Info?.KesesiOk ? myTrain.Vonat.Info?.KesesiOk : 'késés oka'}}</i> miatt előreláthatólag {{myTrain.Vonat.Info?.Keses}} perc késéssel fog érkezni, amiért szíves elnézésüket kérjük.</p>
        <p *ngIf="myTrain.Vonat.Info?.Keses >= 5 && generaltSzovegek[currentIndex].type != 0">🇬🇧 Please note that this train will arrive at {{generaltSzovegek[currentIndex].stationName}} station with an expected delay of {{myTrain.Vonat.Info?.Keses}} minutes due to <i>{{myTrain.Vonat.Info?.KesesiOk ? myTrain.Vonat.Info?.KesesiOk : 'késés oka'}}</i>, for which we wish to apologise.</p>
        <!--<b>Egyéni megjegyzés a bemondáshoz</b>
        <textarea [(ngModel)]="customText" class="form-control mt-1"></textarea>-->
      </div>
      <div *ngIf="generaltSzovegek[currentIndex]" class="actions" style="margin-top: 5px;">
        <div class="row">
          <div class="col-6 text-center">
            <button class="btn btn-lg btn-danger" [disabled]="currentIndex == 0" (click)="moveBemondas(-1)"><i class="fa fa-arrow-left"></i> Előző bemondás</button>
          </div>
          <div class="col-6 text-center"> 
            <button class="btn btn-lg btn-success" [disabled]="currentIndex == (generaltSzovegek.length - 1)" (click)="moveBemondas(1)"><i class="fa fa-arrow-right"></i> Következő bemondás</button> 
          </div>
        </div>
        <br>
        <div class="row">
          <div class="col-12 text-center">
            <button class="btn btn-block btn-warning" (click)="open(content)"><i class="fa fa-exclamation-triangle"></i> Rendkívüli bemondások</button>
          </div>
        </div>
      </div>
    </article>
  </div>
</div>