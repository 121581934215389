<div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
  <article *ngIf="szolgalatStatusz === '0'" class="card-body">
    <h4 class="text-center">Jelentkezés szolgálatra</h4>
    <form [formGroup]="fordaForm" (ngSubmit)="onSubmit()">
      <div *ngIf="wasError" class="alert alert-danger" role="alert">
          {{error}}
      </div>
      <div class="form-group">
        <label>Szolgálat típusa</label>
          <div *ngIf="submitted && fordaForm.controls.szolgTipus.errors">
            <div *ngIf="fordaForm.controls.szolgTipus.errors.required">
              <div class="alert alert-danger" role="alert">
                A szolgálat típusát meg kell adni!
              </div>
            </div>
          </div>
          <select class="form-control" id="szolgTipus" formControlName="szolgTipus" [(ngModel)]="selectedHely">
            <option [ngValue]="null" [disabled]="true">Válassz típust!</option>
            <option value="1">Állomási szolgálat</option>
            <option value="2">Vonatos szolgálat</option>
            <!--<option value="005501024">Budapest-Kelenföld</option>
            <option value="005501016">Budapest-Déli</option>
            <option value="005510017">Budapest-Keleti</option>
            <option value="005510033">Budapest-Nyugati</option>
            <option value="005510090">Zugló</option>
            <option value="005510173">Kőbánya-Kispest</option>
            <option value="005503269">Székesfehérvár</option>-->
          </select>
      </div>
      <div id="vonat" *ngIf="selectedHely === '2'">
        <div class="form-group">
          <label>Telephely</label>
          <div *ngIf="submitted && fordaForm.controls.tipus.errors">
            <div *ngIf="fordaForm.controls.tipus.errors.required">
              <div class="alert alert-danger" role="alert">
                A telephelyet ki kell választani!
              </div>
            </div>
          </div>
          <select class="form-control" id="telep" formControlName="telep" [(ngModel)]="selectedTelep">
            <option value="Budapest">Budapest</option>
            <option value="Nagykanizsa">Nagykanizsa</option>
            <option value="Szombathely">Szombathely</option>
            <option value="Miskolc">Miskolc</option>
            <option value="Debrecen">Debrecen</option>
            <option value="Pécs">Pécs</option>
            <option value="Tapolca">Tapolca</option>
            <option value="Szeged">Szeged</option>
          </select>
        </div>
        <div class="form-group">
          <label>Forda típusa</label>
            <div *ngIf="submitted && fordaForm.controls.tipus.errors">
              <div *ngIf="fordaForm.controls.tipus.errors.required">
                <div class="alert alert-danger" role="alert">
                  A forda típusát meg kell adni!
                </div>
              </div>
            </div>
            <select class="form-control" id="type" formControlName="tipus" value="GY">
              <option [ngValue]="null" [disabled]="true">Válassz típust!</option>
              <option *ngFor="let forda of fordaTipusok[selectedTelep]" [value]="forda.rovidites">{{forda.megnevezes}}</option>
              <!--<option value="H">Hangos</option>
              <option value="HK">Hangos és kerékpáros</option>
              <option value="BEX">BEX hangos</option>
              <option value="K">Kerékpáros</option>-->
            </select>
        </div> <!-- form-group// -->
        <div class="form-group">
          <label>Forda száma</label>
            <div *ngIf="submitted && fordaForm.controls.szam.errors">
              <div *ngIf="fordaForm.controls.szam.errors.required">
                <div class="alert alert-danger" role="alert">
                  A forda számát meg kell adni!
                </div>
              </div>
            </div>
            <input class="form-control" placeholder="Forda száma" type="number" formControlName="szam">
        </div> <!-- form-group// --> 
      </div>
      <div id="allomas" *ngIf="selectedHely === '1'">
        <div class="form-group">
          <label>Szolgálat helye</label>
            <div *ngIf="submitted && fordaForm.controls.allomas.errors">
              <div *ngIf="fordaForm.controls.allomas.errors.required">
                <div class="alert alert-danger" role="alert">
                  A szolgálat helyét meg kell adni!
                </div>
              </div>
            </div>
            <select class="form-control" id="allomas" formControlName="allomas">
              <option [ngValue]="null" [disabled]="true">Válassz helyet!</option>
              <option value="005501024">Budapest-Kelenföld</option>
              <option value="005501016">Budapest-Déli</option>
              <option value="005510017">Budapest-Keleti</option>
              <option value="005510033">Budapest-Nyugati</option>
              <option value="005510090">Zugló</option>
              <option value="005510173">Kőbánya-Kispest</option>
              <option value="005503269">Székesfehérvár</option>
              <option value="005510140">Rákospalota-Újpest</option>
              <option value="005504507">Révfülöp</option>
              <option value="005504747">Keszthely</option>
              <option value="005504598">Tapolca</option>
              <option value="005504556">Badacsony</option>
              <option value="005504366">Balatonalmádi</option>
              <option value="005504416">Balatonfüred</option>
              <option value="005503350">Siófok</option>
              <option value="005503491">Fonyód</option>
              <!--<option value="005503210">Velence</option>
              <option value="005503228">Velencefürdő</option>
              <option value="005503236">Gárdony</option>
              <option value="005503244">Agárd</option>-->
            </select>
        </div>
        <div class="form-group">
          <label>Munkakör</label>
            <div *ngIf="submitted && fordaForm.controls.munkakor.errors">
              <div *ngIf="fordaForm.controls.munkakor.errors.required">
                <div class="alert alert-danger" role="alert">
                  A munkakört meg kell adni!
                </div>
              </div>
            </div>
            <select class="form-control" id="munkakor" formControlName="munkakor">
              <option [ngValue]="null" [disabled]="true">Válassz munkakört!</option>
              <option value="1">Automata</option>
              <option value="2">Egysoros</option>
              <option value="3">Nemzetközi info</option>
              <option value="4">Ügyfélszolgálat</option>
              <option value="5">Állomási kerékpáros</option>
              <option value="6">Nemzetközi host</option>
              <option value="7">Táblázó</option>
              <option value="8">Vízosztó</option>
              <option value="9">Vágányzári info</option>
              <option value="10">Állomási info</option>
              <option value="11">Egyéb</option>
            </select>
        </div>
        <div class="form-group">
          <label>Munkaidő vége</label>
          <ngb-timepicker formControlName="szolgVege" id="szolgvege"></ngb-timepicker>
        </div>
      </div>
      <div class="form-group">
          <button [disabled]="inProgress" type="submit" class="btn btn-success btn-block"> 
            <span *ngIf="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Mehet!  </button>
      </div> <!-- form-group// -->                                                           
  </form>
  </article>
  <article *ngIf="szolgalatStatusz === '2'" class="card-body">
      <h4 class="text-center">Forda részletei ({{ fordatipus }}{{ fordaszam }})</h4>
      <div *ngFor="let fordulo of fordaAdatok; let index = index" style="background-color: #4287f5; margin: 12px; padding: 10px; border-radius: 3px;">
        <h4>{{index + 1}}. forduló</h4>
        <span (click)="getVonatID(fordulo.TrainNumber)">Vonatszám<br></span>
        <span (click)="getVonatID(fordulo.TrainNumber)" class="nagy">{{fordulo.TrainNumber}}<br></span>
        <span *ngIf="fordulo.Start">Feljelentkezés<br></span>
        <span *ngIf="fordulo.Start" class="nagy">{{fordulo.Start}}<br></span>
        <span>Indulás ideje<br></span>
        <span class="nagy">{{fordulo.Dep}}<br></span>
        <span>Induló állomás<br></span>
        <span class="nagy">{{allStations['00' + fordulo.DepStation]}}<br></span>
        <span>Érkezés ideje<br></span>
        <span class="nagy">{{fordulo.Arrive}}<br></span>
        <span>Érkező állomás<br></span>
        <span class="nagy">{{allStations['00' + fordulo.ArriveStation]}}<br></span>
        <span *ngIf="fordulo.Stop">Lejelentkezés<br></span>
        <span *ngIf="fordulo.Stop" class="nagy">{{fordulo.Stop}}<br></span>
        <span *ngIf="fordulo.Note">Megjegyzés<br></span>
        <span *ngIf="fordulo.Note" class="nagy">{{fordulo.Note}}</span>
      </div>
  </article>
  <article *ngIf="szolgalatStatusz != '0'" class="card-body">
    <h4 class="text-center" *ngIf="szolgalatStatusz === '2'">Biztosan kijelentkezel szolgálatból? ({{ fordatipus }}{{ fordaszam }})</h4>
    <h4 class="text-center" *ngIf="szolgalatStatusz === '1'">Biztosan kijelentkezel szolgálatból? ({{ allStations['00' + allomasKod] }} - {{munkakorok[munkakorKod]}})</h4>
      <button [disabled]="inProgress" class="btn btn-success btn-block" (click)="onConfirm()">
          <span *ngIf="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
           Igen</button>
      <button [disabled]="inProgress" class="btn btn-warning btn-block" routerLink="/"> Nem</button>
  </article>
</div>

