<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Rendkívüli bemondások</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <select class="form-control" [(ngModel)]="kivalasztottSzoveg">
        <option [ngValue]="null">Válassz bemondást!</option>
        <option *ngFor="let bemondas of kulonlegesSzovegek | keyvalue" [value]="bemondas.key" >{{bemondas.value.megnevezes}}</option>
      </select>
      <div *ngIf="kivalasztottSzoveg != null">
        <hr>
        <b>{{kulonlegesSzovegek[kivalasztottSzoveg].megnevezes}}</b>
        <p>🇭🇺 <span [innerHTML]="kulonlegesSzovegek[kivalasztottSzoveg].magyar"></span></p>
        <p>🇬🇧 <span [innerHTML]="kulonlegesSzovegek[kivalasztottSzoveg].angol"></span></p>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">OK</button>
    </div>
  </ng-template>

<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article *ngIf="wasError" class="card-body">
        <div class="alert alert-danger" role="alert">
          A szövegkönyv ehhez a vonathoz sajnos nem elérhető.
        </div>
      </article>
    <article *ngIf="chosenTrain" class="card-body">
        <h4 class="text-center">Offline szövegkönyv</h4>
        <div [ngStyle]="{'background-color': 'white', 'color': 'black', 'padding': '5px', 'margin-bottom': '8px', 'overflow': 'hidden', 'width': '100%'}">
        <p><span style="font-weight: bold;">{{trainNumber}}</span> {{chosenTrain.nev}} {{chosenTrain.vonatnem}}
        <span style="float: right; font-size: 48px; font-style: italic; color: green;">-'</span></p>
        ({{chosenTrain.induloallomas}} - {{chosenTrain.vegallomas}})
        <span style="float: right; clear: both;"><i class="fa fa-map-marker"></i> Nincs adat</span>
        <span style="float: right; clear: both;"><i class="fa fa-arrow-right"></i> Nincs adat</span>
        </div>
        <!-- amik itt kellenek:
        * állomás, amihez a bemondás köthető
        * a bemondás menetrend szerinti ideje (indulás előtt 5 perccel: indulási idő - 5 perc, indulás után azonnal: indulási idő, érkezés előtt 4-5 perccel, érkezési idő - 4 perc)
        * a bemondás valós ideje, ha van hozzá adat
        -->
        <!--<div *ngIf="!generaltSzovegek[currentIndex]">
        <div class="alert alert-danger" role="alert">
            A funkció még nem elérhető. Ehhez a vonathoz nem tartozik szövegkönyv. 
        </div>
        </div>-->
        <div *ngIf="generaltSzovegek[currentIndex]" style="background-color: #4287f5; padding: 10px; border-radius: 3px;">
        <i class="fa fa-map-marker"></i> {{generaltSzovegek[currentIndex].stationName}}
        <span style="float: right; clear: both;"><i class="fa fa-clock-o"></i> --:--</span>
        <p><span>{{szovegTipusok[generaltSzovegek[currentIndex].type]}}</span></p>
        <p><b>🇭🇺 - Magyar szöveg</b></p>
        <p>{{generaltSzovegek[currentIndex].hungarian}}</p>
        <p *ngIf="generaltSzovegek[currentIndex].english"><b>🇬🇧 - Angol szöveg</b></p>
        <p>{{generaltSzovegek[currentIndex].english}}</p>
        <p *ngIf="generaltSzovegek[currentIndex].german"><b>🇩🇪 - Német szöveg</b></p>
        <p>{{generaltSzovegek[currentIndex].german}}</p>
        <!--<b>Egyéni megjegyzés a bemondáshoz</b>
        <textarea [(ngModel)]="customText" class="form-control mt-1"></textarea>-->
        </div>
        <div *ngIf="generaltSzovegek[currentIndex]" class="actions" style="margin-top: 5px;">
            <div class="row">
                <div class="col-6 text-center">
                <button class="btn btn-lg btn-danger" [disabled]="currentIndex == 0" (click)="moveBemondas(-1)"><i class="fa fa-arrow-left"></i> Előző bemondás</button>
                </div>
                <div class="col-6 text-center"> 
                <button class="btn btn-lg btn-success" [disabled]="currentIndex == (generaltSzovegek.length - 1)" (click)="moveBemondas(1)"><i class="fa fa-arrow-right"></i> Következő bemondás</button> 
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-12 text-center">
                <button class="btn btn-block btn-warning" (click)="open(content)"><i class="fa fa-exclamation-triangle"></i> Rendkívüli bemondások</button>
                </div>
            </div>
        </div>
    </article>