<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article class="card-body" style="padding-left: 0; padding-right: 0">
        <p *ngIf="terkepLoading && !wasError"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Térkép adatok betöltése...</p>
        <div *ngIf="wasError" class="alert alert-danger" role="alert">
            {{error}}
        </div>
        <div *ngIf="selected && selectedVonatinfo" class="train-details">
            <a [routerLink]="['/vonatinfo', selected.VonatID]"><button class="btn btn-lg btn-primary" style="float: right"><i class="fa fa-info-circle"></i></button></a>
            <div *ngIf="vonatLoading"><b>{{selected.Vonatszam}}</b> Adatok betöltése...</div>
            <div *ngIf="!vonatLoading">
                <b>{{selected.Vonatszam}}</b> <span [ngStyle]="{'color': selectedVonatinfo.Vonat.Tipus[0].Szin}"> {{selectedVonatinfo.Vonat.Nev}} {{selected.Tipus}}</span>
                <div style="font-family: 'mnr'; font-size: 20px" [ngStyle]="{'color': '#' + selectedVonatinfo.Vonat.ViszonylatJeloles.FontSzin, 'background-color': '#' + selectedVonatinfo.Vonat.ViszonylatJeloles.HatterSzin, 'border-radius': '3pt', 'height': '17px', 'display': 'inline-block', 'vertical-align': 'text-bottom', 'margin-left': '8px'}" *ngIf="selectedVonatinfo.Vonat.ViszonylatJeloles.Jel"> <span style="position: relative; top: -5px;">{{this.displayViszonylatszam(selectedVonatinfo.Vonat.ViszonylatJeloles.Logo.FontKod)}}</span></div><br></div>
            <!--<div style="font-family: 'mnr'; font-size: 20px; display: inline-block; height: 12px; width: 24px;" class="zonaHatter">ſ</div>-->
            <!--<div style="font-family: 'mnr'; font-size: 20px; background-color: #F9C92A; border-radius: 3pt; height: 17px; display: inline-block; vertical-align: text-bottom;"><span style="position: relative; top: -5px;">ſ</span></div>-->
            Sebesség: <b>{{selected.Sebesseg}} km/h</b><br>
            Késés: <b>{{selected.Keses}} perc</b><br>
            Vontatójármű: <b><span *ngIf="gotMozdonyData">{{mozdony.str6}} {{mozdony.sb2}}</span><span *ngIf="!gotMozdonyData && mozdonyLoading">Betöltés...</span><span *ngIf="!gotMozdonyData && !mozdonyLoading">Nincs adat</span></b>
            <!--<br>Menetrendszerűség: {{ 1 - (menetrendszeruseg.kesoVonatok / menetrendszeruseg.osszesVonat) | percent }}-->
        </div>
        <div class="map-container">
            <div id="map">
            </div>
        </div>
    </article>
</div>