import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BackendService } from '../backend.service';
import { Router } from '@angular/router';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import * as textMask from "vanilla-text-mask/dist/vanillaTextMask.js"

@Component({
  selector: 'app-tickets',
  templateUrl: './tickets.component.html',
  styleUrls: ['./tickets.component.css']
})
export class TicketsComponent implements OnInit {
  hibajegyForm: FormGroup;
  public model: any;
  selectedTipus;
  submitted = false;
  inProgress = false;
  wasError = false;
  error = "";
  message = "";
  states = [];

  constructor(private data: BackendService, private formBuilder: FormBuilder, private router: Router) { }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 5))
    )

  ngOnInit(): void {
    this.data.getJarmuLista().subscribe(data => {
      data.forEach(jarmu => {
        this.states.push(jarmu['UIC']);
      })
      /*Object.values(data).forEach(function(value) {
        this.states.push(value);
      }.bind(this))*/
    }, error => {
      console.log(error);
    })
    this.buildForm();
    this.setUserCategoryValidators();
  }

  buildForm() {
    this.hibajegyForm = this.formBuilder.group({
      hibajegyTipus: [null, Validators.required],
      allomas: ['', Validators.required],
      uic: ['', Validators.required],
    });
  }

  setUserCategoryValidators() {
    const hibajegytipusCtrl = this.hibajegyForm.get('hibajegyTipus');
    const allomasCtrl = this.hibajegyForm.get('allomas');
    const uicCtrl = this.hibajegyForm.get('uic');

    this.hibajegyForm.get('hibajegyTipus').valueChanges
      .subscribe(userCategory => {

        if (userCategory === '1') { // állomás
          allomasCtrl.setValidators([Validators.required]);
          uicCtrl.setValidators(null);
        }

        if (userCategory === '2') { // jármű
          allomasCtrl.setValidators(null);
          uicCtrl.setValidators([Validators.required]);
        }

        uicCtrl.updateValueAndValidity();
        allomasCtrl.updateValueAndValidity();
      });
  }

  onSubmit() {
    this.submitted = true;
    this.inProgress = true;
    this.wasError = false;

    if (this.hibajegyForm.invalid) {
      this.inProgress = false;
      console.log("Érvénytelen form!")
      return;
    }
    if(this.selectedTipus == '1') {
      this.router.navigate(['tickets', this.hibajegyForm.controls.allomas.value])
    }
    else {
      this.router.navigate(['tickets', this.hibajegyForm.controls.uic.value])
    }
  }

}
