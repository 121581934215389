import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxIndexedDBModule, DBConfig } from 'ngx-indexed-db';
import { JwtModule } from '@auth0/angular-jwt';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { DataTablesModule } from 'angular-datatables';
import { NgxMaskModule } from 'ngx-mask';

import { AuthGuard } from './auth.guard';
import { AuthService } from './auth.service';
import { BackendService } from './backend.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RegisterComponent } from './register/register.component';
//import { BusesComponent } from './buses/buses.component';
//import { PrevdataComponent } from './prevdata/prevdata.component';
import { NavComponent } from './nav/nav.component';
import { LoginComponent } from './login/login.component';
import { MainComponent } from './main/main.component';
//import { ArrdepComponent } from './arrdep/arrdep.component';
//import { AddBusComponent } from './add-bus/add-bus.component';
//import { BusDataComponent } from './bus-data/bus-data.component';
import { VonatinfoComponent } from './vonatinfo/vonatinfo.component';
import { ChooseFordaComponent } from './choose-forda/choose-forda.component';
import { AllomasinfoComponent } from './allomasinfo/allomasinfo.component';
import { MavinformComponent } from './mavinform/mavinform.component';
import { SzemelyzetComponent } from './szemelyzet/szemelyzet.component';
import { KerekparokComponent } from './kerekparok/kerekparok.component';
import { VonatComponent } from './vonatinfo/vonat/vonat.component';
import { HirComponent } from './mavinform/hir/hir.component';
import { AllomasComponent } from './allomasinfo/allomas/allomas.component';
import { AllomasSzemelyzetComponent } from './szemelyzet/allomas-szemelyzet/allomas-szemelyzet.component';
import { VonatSzemelyzetComponent } from './szemelyzet/vonat-szemelyzet/vonat-szemelyzet.component';
import { SzovegkonyvComponent } from './szovegkonyv/szovegkonyv.component';
import { SzovegNezetComponent } from './szovegkonyv/szoveg-nezet/szoveg-nezet.component';
import { KerekparComponent } from './kerekparok/kerekpar/kerekpar.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { TerkepComponent } from './terkep/terkep.component';
import { AdminComponent } from './admin/admin.component';
import { UsersComponent } from './admin/users/users.component';
import { UserComponent } from './admin/users/user/user.component';
import { SzovegComponent } from './admin/szoveg/szoveg.component';
import { SzerkesztComponent } from './admin/szoveg/szerkeszt/szerkeszt.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketlistComponent } from './tickets/ticketlist/ticketlist.component';
import { TicketComponent } from './tickets/ticketlist/ticket/ticket.component';
import { NewticketComponent } from './tickets/ticketlist/newticket/newticket.component';
import { UicMaskDirective } from './uic-mask.directive';
import { SzovegkonyvOfflineComponent } from './szovegkonyv-offline/szovegkonyv-offline.component';
import { OfflineSzovegNezetComponent } from './szovegkonyv-offline/offline-szoveg-nezet/offline-szoveg-nezet.component';
import { KerekparokOfflineComponent } from './kerekparok-offline/kerekparok-offline.component';
import { KerekparOfflineComponent } from './kerekparok-offline/kerekpar-offline/kerekpar-offline.component';
import { UtasokComponent } from './utasok/utasok.component';
import { UtasComponent } from './utasok/utas/utas.component';
import { AllomasElviraComponent } from './allomasinfo/allomas-elvira/allomas-elvira.component';
import { OsszeallitasComponent } from './osszeallitas/osszeallitas.component';
import { DownloadComponent } from './download/download.component';
import { AdatkezelesComponent } from './adatkezeles/adatkezeles.component';
import { KapcsolatComponent } from './kapcsolat/kapcsolat.component';

const dbConfig: DBConfig = {
  name: 'DTC',
  version: 1,
  objectStoresMeta: [{
    store: 'szovegkonyv',
    storeConfig: { keyPath: 'id', autoIncrement: true },
    storeSchema: [
      /*{ name: 'trainnum', keypath: 'trainnum', options: { unique: false } },
      { name: 'station', keypath: 'station', options: { unique: false } },
      { name: 'type', keypath: 'type', options: { unique: false } },
      { name: 'hungarian', keypath: 'hungarian', options: { unique: false } },
      { name: 'english', keypath: 'english', options: { unique: false }},
      { name: 'german', keypath: 'german', options: {unique: false }},
      { name: 'valid', keypath: 'valid', options: {unique: false }}*/
      { name: 'szovegobject', keypath: 'szovegobject', options: { unique: false } }
    ]
  }]
};

export function tokenGetter() {
  return localStorage.getItem('access_token');
}

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    //BusesComponent,
    //PrevdataComponent,
    NavComponent,
    LoginComponent,
    MainComponent,
    //ArrdepComponent,
    //AddBusComponent,
    //BusDataComponent,
    VonatinfoComponent,
    ChooseFordaComponent,
    AllomasinfoComponent,
    MavinformComponent,
    SzemelyzetComponent,
    KerekparokComponent,
    VonatComponent,
    HirComponent,
    AllomasComponent,
    AllomasSzemelyzetComponent,
    VonatSzemelyzetComponent,
    SzovegkonyvComponent,
    SzovegNezetComponent,
    KerekparComponent,
    TerkepComponent,
    AdminComponent,
    UsersComponent,
    UserComponent,
    SzovegComponent,
    SzerkesztComponent,
    TicketsComponent,
    TicketlistComponent,
    TicketComponent,
    NewticketComponent,
    UicMaskDirective,
    SzovegkonyvOfflineComponent,
    OfflineSzovegNezetComponent,
    KerekparokOfflineComponent,
    KerekparOfflineComponent,
    UtasokComponent,
    UtasComponent,
    AllomasElviraComponent,
    OsszeallitasComponent,
    DownloadComponent,
    AdatkezelesComponent,
    KapcsolatComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    FormsModule,
    NgxIndexedDBModule.forRoot(dbConfig),
    BrowserAnimationsModule,
    LeafletModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ['jail.nemzetijb.hu:3200', 'nemzetijb.hu:3243']
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production, registrationStrategy: 'registerImmediately' }),
    DataTablesModule,
    NgxMaskModule.forRoot()
  ],
  providers: [
    AuthGuard,
    AuthService,
    BackendService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
