import { Component, OnInit } from '@angular/core';
import { BackendService } from '../backend.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, distinctUntilChanged, map} from 'rxjs/operators';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router, ActivatedRoute } from '@angular/router';

/*const states = ['Alabama', 'Alaska', 'American Samoa', 'Arizona', 'Arkansas', 'California', 'Colorado',
  'Connecticut', 'Delaware', 'District Of Columbia', 'Federated States Of Micronesia', 'Florida', 'Georgia',
  'Guam', 'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa', 'Kansas', 'Kentucky', 'Louisiana', 'Maine',
  'Marshall Islands', 'Maryland', 'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri', 'Montana',
  'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey', 'New Mexico', 'New York', 'North Carolina', 'North Dakota',
  'Northern Mariana Islands', 'Ohio', 'Oklahoma', 'Oregon', 'Palau', 'Pennsylvania', 'Puerto Rico', 'Rhode Island',
  'South Carolina', 'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont', 'Virgin Islands', 'Virginia',
  'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'];*/

@Component({
  selector: 'app-allomasinfo',
  templateUrl: './allomasinfo.component.html',
  styleUrls: ['./allomasinfo.component.css']
})
export class AllomasinfoComponent implements OnInit {
  public model: any;
  allomasinfoForm: FormGroup;
  inProgress = false;
  success = false;
  wasError = false;
  submitted = false;
  myStation = <any>{};
  allStations = {};
  states = [];
  error = "";
  closeResult: string;
  lastStations = [];
  reqType = 'fromNow';
  allTrainButton = 'btn-primary';
  fromNowButton = 'btn-success';

  constructor(private formBuilder: FormBuilder, private data: BackendService, private modalService: NgbModal, private router: Router, private route: ActivatedRoute) { }

  search = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term.length < 2 ? []
        : this.states.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
    )

  ngOnInit() {
    if(!localStorage.getItem('reqType')) {
      localStorage.setItem('reqType', 'fromNow');
    }
    if(localStorage.getItem('reqType') == 'allTrains') {
      this.reqType = 'allTrains';
      this.allTrainButton = "btn-success"
      this.fromNowButton = "btn-primary"
      localStorage.setItem('reqType', 'allTrains');
    }
    this.lastStations = this.data.getLastStations();
    this.allomasinfoForm = this.formBuilder.group({
      allomas: ['', Validators.required]
    });

    // VIM
    /*this.data.getAllomasLista(1).subscribe(data => {
      Object.values(data).forEach(function(value) {
        this.states.push(value);
      }.bind(this))
    }, error => {
      console.log(error);
    })*/

    // ELVIRA
    this.data.getElviraAllomasok().subscribe(data => {
      Object.values(data['mav']).forEach(function(value) {
        this.states.push(value);
      }.bind(this))
    }, error => {
      console.log(error);
    })
  }

  onSubmit() {
    this.submitted = true;
    this.inProgress = true;

    if (this.allomasinfoForm.invalid) {
      this.inProgress = false;
      console.log("Érvénytelen form!")
      return;
    }

    // VIM
    /*this.data.getAllomasinfo(this.allomasinfoForm.controls.allomas.value, this.reqType).subscribe(data => {
      this.myStation = data;
      this.data.addLastStation(this.myStation.allomasNev);
      this.router.navigate([this.allomasinfoForm.controls.allomas.value], {relativeTo: this.route})

    }, error => {
      this.wasError = true;
      this.error = error;
      this.submitted = false;
      this.inProgress = false;
    })*/

    // ELVIRA
    this.data.getElviraAllomasinfo(this.allomasinfoForm.controls.allomas.value, this.reqType).subscribe(data => {
      this.myStation = data;
      this.data.addLastStation(this.myStation.station);
      //this.router.navigate([this.allomasinfoForm.controls.allomas.value], {relativeTo: this.route})
      this.router.navigate(['/allomasinfov2', this.allomasinfoForm.controls.allomas.value])

    }, error => {
      this.wasError = true;
      this.error = error;
      this.submitted = false;
      this.inProgress = false;
    })
  }

  switchMode() {
    if(this.reqType == 'fromNow') {
      this.reqType = 'allTrains';
      this.allTrainButton = "btn-success"
      this.fromNowButton = "btn-primary"
      localStorage.setItem('reqType', 'allTrains');
    }
    else {
      this.reqType = 'fromNow';
      this.fromNowButton = "btn-success"
      this.allTrainButton = "btn-primary"
      localStorage.setItem('reqType', 'fromNow');
    }
  }

}
