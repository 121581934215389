<h4 class="text-center">{{allomasNev}} szolgálati hely diákszemélyzete</h4>
<h5>A szolgálati helyen szolgálatot teljesítő diák(ok):</h5>
<div *ngIf="isLoading">
  <i class="fa fa-circle-o-notch fa-spin"></i> Az adatok betöltése folyamatban...
</div>
<div *ngIf="wasError && !isLoading" class="alert alert-danger" role="alert">
  {{error}}
</div>
<div *ngFor="let diak of szemelyzet" class="card text-white bg-primary mb-3" style="width: 100%">
  <div class="card-body" *ngIf="szemelyzet">
    <h5 class="card-title"><i class="fa fa-user"></i> {{diak.teljesnev}}</h5>
    <p class="card-text m-0"><i class="fa fa-briefcase"></i> {{data.munkakorok[diak.munkakorKod]}}
    </p>
    <p class="card-text"><i class="fa fa-phone"></i> <a *ngIf="diak.telefon != ''" style="text-decoration: none; color: white;" href="tel:{{diak.telefon}}">{{diak.telefon != '' ? diak.telefon : 'Nincs' }}</a>
      <span class="float-right"><i class="fa fa-clock-o"></i> {{diak.szolgalatVege != '' ? diak.szolgalatVege + '-ig' : 'Nincs' }}</span>
    </p>
  </div>
</div>
<!--<table style="width: 100%">
  <tr *ngFor="let diak of szemelyzet">
    <td style="width: 50%">
      <div class="name">{{diak.teljesnev}}</div>
      <div class="munkakor"><i>{{data.munkakorok[diak.munkakorKod]}}</i></div>
      <div *ngIf="diak.szolgalatVege != ''" class="ido">{{diak.szolgalatVege}}-ig</div>
    </td>
    <td style="width: 50%" *ngIf="diak.telefon != ''"><a href="tel:{{diak.telefon}}">{{diak.telefon}}</a></td>
    <td style="width: 50%" *ngIf="diak.telefon == ''"><i>Nincs megadva telefonszám</i></td>
    <td style="width: 25%">{{data.munkakorok[diak.munkakorKod]}}</td>
  </tr>
</table>-->
