import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable } from 'rxjs';
import { BackendService } from './backend.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(public jwtHelper: JwtHelperService, private router: Router, private data: BackendService) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(localStorage.getItem('access_token')) {
      if(this.jwtHelper.isTokenExpired()) {
        this.data.gotLoggedOut = true;
        this.data.loggedOutMsg = 'A munkamenet lejárt, kérlek jelentkezz be újra!';
        this.router.navigate(['login'])
        return false;
      }
      else {
        return true;
      }
    }
    else {
      this.data.gotLoggedOut = true;
      this.data.loggedOutMsg = 'Az oldal eléréséhez be kell jelentkezned.';
      this.router.navigate(['login'])
      return false;
    }
  }
}
