import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/backend.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { VonatokService } from 'src/app/kerekparok-offline/vonatok.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-utas',
  templateUrl: './utas.component.html',
  styleUrls: ['./utas.component.css']
})
export class UtasComponent implements OnInit {

  utasokForm: FormGroup;
  trainNumber: number;
  chosenTrain;
  megallasiRend = [];
  allPassengers = {
    szakaszeleje: '',
    szakaszvege: '',
    masodosztaly: 0,
    elsoosztaly: 0,
    etkezo: 0,
    ic: 0,
    kerekpar: 0,
    all: 0
  }
  submitted = false;

  copyText = '';
  //bicycleCounts: IBicycleCount[] = [];
  closeResult;
  osszesites = 0;
  /* mentés visszajelzése */
  isLoading = false;
  response;
  wasError = false;
  success = false;
  saved = false;

  constructor(private route: ActivatedRoute, private data: BackendService, private router: Router, private modalService: NgbModal, private vonatService: VonatokService, private formBuilder: FormBuilder) { }

  ngOnInit(): void {

    this.trainNumber = Number(this.route.snapshot.params['szam']);

    this.chosenTrain = this.vonatService.vonatok.find((vonat) => {
      return vonat.vonatszamok.includes(this.trainNumber);
    })

    if(localStorage.getItem('utasSzamok')) {
      this.allPassengers = JSON.parse(localStorage.getItem('utasSzamok'));
    }

    if(this.chosenTrain) {
      this.megallasiRend = this.vonatService.getMegallasiRend(this.chosenTrain.megallasiRendId, this.chosenTrain.reverse)
    }

    this.utasokForm = this.formBuilder.group({
      szakaszeleje: [this.megallasiRend[0], Validators.required],
      szakaszvege: [this.megallasiRend[1], Validators.required]
    })
  }

  onSubmit() {
    this.submitted = true;

    if(this.utasokForm.invalid) {
      console.log(this.utasokForm.controls)
      this.submitted = false;
      return;
    }

    this.allPassengers.szakaszeleje = this.utasokForm.controls.szakaszeleje.value;
    this.allPassengers.szakaszvege = this.utasokForm.controls.szakaszvege.value;
  }

  changeCount(how, type) {
    if(how > 0) {
      this.allPassengers[type]++;
    }
    else {
      this.allPassengers[type]--;
    }

    this.checkInput(type);
  }

  checkInput(type) {
    if(this.allPassengers[type] < 0) {
      this.allPassengers[type] = 0;
    }

    this.calculateAll();
  }

  calculateAll() {
    this.allPassengers.all = this.allPassengers.elsoosztaly + this.allPassengers.masodosztaly + this.allPassengers.etkezo + this.allPassengers.ic;

    localStorage.setItem('utasSzamok', JSON.stringify(this.allPassengers));
    localStorage.setItem('utasVonatszam', this.trainNumber.toString());
  }

  resetSzamlalo() {
    localStorage.removeItem('utasSzamok');
    localStorage.removeItem('utasVonatszam');
    this.router.navigate(['/utasok']);
  }

  getTextFromCount() {
    this.copyText = '#utasszam\n' + this.trainNumber + ' sz. vonat\n' + this.allPassengers.szakaszeleje + ' - ' + this.allPassengers.szakaszvege + '\n';
    if(this.chosenTrain.elsoosztaly) {
      this.copyText += '1️⃣: ' + this.allPassengers.elsoosztaly + '\n';
    }
    if(this.chosenTrain.ic) {
      this.copyText += '2️⃣ IC: ' + this.allPassengers.ic + '\n';
    }
    if(this.chosenTrain.etkezo) {
      this.copyText += '🍴: ' + this.allPassengers.etkezo + '\n';
    }
    this.copyText += '2️⃣: ' + this.allPassengers.masodosztaly + '\n';
    this.copyText += '🚲: ' + this.allPassengers.kerekpar;
  }

  copy(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    inputElement.blur();
  }

  saveData(modalRef) {
    this.isLoading = true;
    this.wasError = false;
    
    this.data.savePassengerCount(this.trainNumber, this.allPassengers).subscribe(data => {
      this.isLoading = false;
      this.wasError = false;
      this.saved = true;
      this.response = data;
      this.success = true;

      localStorage.removeItem('utasSzamok');
      localStorage.removeItem('utasVonatszam');
      this.modalService.dismissAll();
    }, error => {
      this.isLoading = false;
      this.wasError = true;
      this.saved = false;
      this.success = false;
      this.response = error;
    })
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
