<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
  <article class="card-body">
    <router-outlet></router-outlet>
    <!--<h4 class="text-center">{{trainNumber}} sz. vonat diákszemélyzete</h4>
    <h5>A vonaton szolgálatot teljesítő fordá(k):</h5>
    <div *ngIf="!szemelyzet.fordak && !isLoading" class="alert alert-danger" role="alert">
      A vonaton egy forda sem teljesít szolgálatot.
    </div>
    <div *ngIf="isLoading">
      <i class="fa fa-circle-o-notch fa-spin"></i> Az adatok betöltése folyamatban...
    </div>
    <div *ngFor="let forda of szemelyzet.fordak">
      {{forda.Sign}}: {{allStations['00' + forda.DepStation]}} - {{allStations['00' + forda.ArriveStation]}}
    </div>
    <br>
    <p>A vonaton szolgálatot teljesítő diák(ok):</p>
    <div *ngIf="isLoading">
      <i class="fa fa-circle-o-notch fa-spin"></i> Az adatok betöltése folyamatban...
    </div>
    <div *ngIf="wasError && !isLoading" class="alert alert-danger" role="alert">
      {{error}}
    </div>
    <div *ngIf="szemelyzet.diakok?.length == 0 && !isLoading" class="alert alert-danger" role="alert">
      A vonaton a forda adatok alapján utazik diákszemélyzet, de nincs bejelentkezve senki.
    </div>
    <table style="width: 100%">
      <tr *ngFor="let diak of szemelyzet.diakok">
        <td style="width: 50%">{{diak.teljesnev}}</td>
        <td style="width: 50%" *ngIf="diak.telefon != ''"><a href="tel:{{diak.telefon}}">{{diak.telefon}}</a></td>
        <td style="width: 50%" *ngIf="diak.telefon == ''"><i>Nincs megadva telefonszám</i></td>
      </tr>
    </table>-->
  </article>
</div>