<div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article class="card-body">
        <h4 class="card-title mb-4 mt-1">DTC adminisztráció</h4>
        <p>Válassz műveletet!</p>
        <ul class="list-group" style="color: black;">
            <li routerLink="users" class="list-group-item"><i class="fa fa-users"></i> Felhasználók kezelése</li>
            <li routerLink="szoveg" class="list-group-item"><i class="fa fa-file-text"></i> Szövegkönyv hozzáadása</li>
        </ul>
        <hr>
        <router-outlet></router-outlet>
    </article>
</div>