<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article class="card-body">
      <h4 class="text-center">Új hibajegy</h4>
      <h5 class="text-center">{{azonosito}}</h5>
      <form [formGroup]="ujHibaForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label>Hiba típusa</label>
            <div *ngIf="submitted && ujHibaForm.controls.hibaTipus.errors">
            <div *ngIf="ujHibaForm.controls.hibaTipus.errors.required">
                <div class="alert alert-danger" role="alert">
                A hiba típusát ki kell választani!
                </div>
            </div>
            </div>
            <select class="form-control" id="hibaTipus" formControlName="hibaTipus">
                <option [ngValue]="null" [disabled]="true">Válassz típust!</option>
                <option *ngFor="let tipus of data.jarmuHibak | slice:1; let i = index" [value]="i+1">{{tipus}}</option>
            </select>
          </div>
          <div class="form-group">
            <label>Hiba észlelésének helye</label>
            <div *ngIf="submitted && ujHibaForm.controls.hibaHely.errors">
                <div *ngIf="ujHibaForm.controls.hibaHely.errors.required">
                    <div class="alert alert-danger" role="alert">
                    A hiba helyét meg kell adni!
                    </div>
                </div>
            </div>
            <!--<input type="text" class="form-control" id="hibaHely" formControlName="hibaHely">-->
            <input id="typeahead-basic" placeholder="Kezdj el írni egy állomást..." formControlName="hibaHely" type="text" class="form-control" [(ngModel)]="model" [ngbTypeahead]="search"/>  
          </div>
          <div class="form-group">
            <label>Hiba leírása</label>
            <div *ngIf="submitted && ujHibaForm.controls.hibaReszletek.errors">
                <div *ngIf="ujHibaForm.controls.hibaReszletek.errors.required">
                    <div class="alert alert-danger" role="alert">
                        A hibát röviden, de kötelező kifejteni, a pontosabb felismerés érdekében!
                    </div>
                </div>
            </div>
            <textarea class="form-control" id="hibaReszletek" formControlName="hibaReszletek"></textarea>
          </div>
          <div class="form-group">
            <div class="alert alert-success" role="alert" *ngIf="success && !wasError && !inProgress">
                A hibajegy sikeresen rögzítésre került. Köszönjük!
            </div>
            <div class="alert alert-danger" role="alert" *ngIf="submitted && wasError && !inProgress">
                {{error}}
            </div>
            <button [disabled]="inProgress" type="submit" class="btn btn-success btn-block"> 
                <span *ngIf="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                <i class="fa fa-plus"></i> Hiba rögzítése</button>
          </div>
      </form>
    </article>
</div>