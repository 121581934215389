import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { BackendService } from '../backend.service';
import { AuthService } from '../auth.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {

  constructor(private router: Router, public data: BackendService, private modalService: NgbModal, private auth: AuthService, public updates: SwUpdate) { }
  userData;
  username = "";
  teljesnev = "";
  fordatipus = "";
  fordaszam = "";
  telephely = "";
  szolgalatStatusz = "";
  allomasKod = "";
  munkakorKod = "";
  fordaAdatok = {};
  userForda = <any>{};
  isLoading = true;
  isOffline = false;
  closeResult: string;
  allStations = {};
  munkakorok = this.data.munkakorok;

  ngOnInit() {
    this.updates.checkForUpdate();
    /*if(!localStorage.getItem('currentUser')) {
      this.router.navigate(['/login']);
    }*/
    this.userData = this.auth.getUserData();

    this.data.getAllomasLista().subscribe(data => {
      this.allStations = data;
    }, error => {
      console.log(error);
    })

    this.data.checkForda(this.userData.username).subscribe(data => {
      this.userForda = data[0];
      this.szolgalatStatusz = this.userForda.szolgalatStatusz;
      localStorage.setItem('szolgalatStatusz', this.szolgalatStatusz);
      this.fordatipus = this.userForda.fordatipus;
      localStorage.setItem('fordatipus', this.fordatipus);
      this.fordaszam = this.userForda.fordaszam;
      localStorage.setItem('fordaszam', this.fordaszam)
      this.allomasKod = this.userForda.allomasKod;
      localStorage.setItem('allomasKod', this.allomasKod);
      this.munkakorKod = this.userForda.munkakorKod;
      localStorage.setItem('munkakorKod', this.munkakorKod);
      this.telephely = this.userForda.telephely;
      localStorage.setItem('telephely', this.telephely);
      this.isLoading = false;
    },
    error => {
      this.isOffline = true;
    })

    this.username = this.userData.username;
    this.teljesnev = this.userData.teljesnev;
    /*this.fordatipus = localStorage.getItem('fordatipus');
    this.fordaszam = localStorage.getItem('fordaszam');*/

    if(this.szolgalatStatusz != "0") {
      var sign = this.fordatipus + "" + this.fordaszam;
    }

    if(this.fordatipus != 'N/A' && this.fordatipus != '') {
      /*this.data.getFordaData(sign).subscribe(data => {
        this.fordaAdatok = data;
      }, error => {
        console.log(error);
      })*/
    }
  }

  logout() {
    this.auth.logout();
    localStorage.clear();
    this.modalService.dismissAll('Igen click')
    this.router.navigate(['/login']);
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', centered: true}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
