import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendService } from '../backend.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { VonatokService } from '../kerekparok-offline/vonatok.service';

@Component({
  selector: 'app-utasok',
  templateUrl: './utasok.component.html',
  styleUrls: ['./utasok.component.css']
})
export class UtasokComponent implements OnInit {

  vonatinfoForm: FormGroup;
  earlierTrainnum;
  earlierTrainID;
  inProgress = false;
  success = false;
  wasError = false;
  submitted = false;
  isLoading = false;
  error = "";
  myTrain = <any>{};
  //bicycleCount = new BicycleData(0, 0, [], []);
  closeResult: string;

  constructor(private formBuilder: FormBuilder, private data: BackendService, private modalService: NgbModal, private router: Router, private vonatService: VonatokService) { }

  ngOnInit(): void {
    this.vonatinfoForm = this.formBuilder.group({
      szam: ['', Validators.required]
    });

    if(localStorage.getItem('utasVonatszam')) {
      this.earlierTrainnum = localStorage.getItem('utasVonatszam');
    }
  }

  onSubmit() {
    this.submitted = true;
    this.inProgress = true;

    if (this.vonatinfoForm.invalid) {
      this.inProgress = false;
      console.log("Érvénytelen form!")
      return;
    }

    let chosenTrain = this.vonatService.vonatok.find((vonat) => {
      return vonat.vonatszamok.includes(Number(this.vonatinfoForm.controls.szam.value));
    })

    if(chosenTrain != undefined) {
      localStorage.removeItem('utasVonatszam');
      localStorage.removeItem('utasAdatok');
      localStorage.removeItem('utasSzamok');
      this.router.navigate(['/utasok', Number(this.vonatinfoForm.controls.szam.value)]);
    }
    else {
      this.wasError = true;
      this.error = "A megadott vonatszámhoz az utasszámlálás jelenleg nem elérhető.";
      this.submitted = false;
      this.inProgress = false;
    }
  }

}
