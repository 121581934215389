<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Biztos?</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <h4>Ha kijelentkezel, a folyamatban lévő számlálások, és a szövegkönyv állapot is elvész. Biztosan ki szeretnél jelentkezni?</h4>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-success" (click)="logout()"><i class="fa fa-check"></i> Igen</button>
    <button type="button" class="btn btn-danger" (click)="modal.close('Save click')"><i class="fa fa-times"></i> Nem</button>
  </div>
</ng-template>

<div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
  <article class="card-body">
    <div class="alert alert-danger">
      <i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i><b>Figyelem!</b><br> A DTC webes verziója már nem támogatott. Kérlek, <a href="/download">töltsd le az alkalmazást!</a>
   </div>
    <h4 (click)="open(content)" class="text-center"><i class="fa fa-user"></i> {{ teljesnev }}</h4>
    <div *ngIf="isOffline">
      <h4 class="text-center" style="color: red"><i class="fa fa-warning"></i> Offline mód</h4>
    </div>
    <div *ngIf="(szolgalatStatusz == '0') && !isLoading">
      <h4 class="text-center" style="color: red"><i class="fa fa-warning"></i> Nincs szolgálatban</h4>
    </div>
    <div *ngIf="szolgalatStatusz == '2'">
      <h4 class="text-center"><i class="fa fa-briefcase" style="margin-right: 4px"></i><i *ngIf="fordatipus == 'H' || fordatipus == 'HK' || fordatipus == 'BEX'" class="fa fa-volume-up"></i><i *ngIf="fordatipus == 'K' || fordatipus == 'HK'" class="fa fa-bicycle"></i> {{fordatipus}}{{fordaszam}} ({{telephely}})</h4>
    </div>
    <div *ngIf="szolgalatStatusz == '1'">
      <h4 class="text-center"><i class="fa fa-briefcase" style="margin-right: 4px"></i>{{allStations['00' + allomasKod]}} - {{munkakorok[munkakorKod]}}</h4>
    </div>
    <div class="grid-container">
      <a *ngIf="szolgalatStatusz == '0'" routerLink="/chooseForda"><div class="fokocka grid-item"><img src="./assets/fordabe.svg" width="100px"></div></a>
      <a *ngIf="szolgalatStatusz != '0'" routerLink="/chooseForda"><div class="fokocka grid-item"><img src="./assets/fordaki.svg" width="100px"></div></a>
      <a routerLink="/vonatinfo"><div class="fokocka grid-item"><img src="./assets/vonatinfo.svg" width="100px"></div></a>
      <a routerLink="/allomasinfo"><div class="fokocka grid-item"><img src="./assets/allomasinfo.svg" width="100px"></div></a>
      <a routerLink="/mavinform"><div class="fokocka grid-item"><img src="./assets/mavinform.svg" width="100px"></div></a>
      <a routerLink="/kerekparokoff"><div class="fokocka grid-item"><img src="./assets/kerekpar.svg" width="100px"></div></a>
      <a routerLink="/szkonyvoffline"><div class="fokocka grid-item"><img src="./assets/szoveg.svg" width="100px"></div></a>
      <a routerLink="/terkep"><div class="fokocka grid-item"><img src="./assets/terkep.svg" width="100px"></div></a>
      <a routerLink="/tickets"><div class="fokocka grid-item"><img src="./assets/hibajegy.svg" width="100px"></div></a>
      <a routerLink="/utasok"><div class="fokocka grid-item"><img src="./assets/utasok.svg" width="100px"></div></a>
      <a *ngIf="userData.level >= 2" routerLink="/admin"><div class="fokocka grid-item"><img src="./assets/uzenet.svg" width="100px"></div></a>
    </div>
  </article>
</div>

<footer>
  <p>v{{data.appVersion.num}} - {{data.appVersion.branch}}</p>
</footer>