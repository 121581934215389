import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendService } from '../backend.service';
import { AuthService } from '../auth.service';
import { Router } from "@angular/router"
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  messageForm: FormGroup;
  submitted = false;
  inProgress = false;
  success = false;
  wasError = false;
  error = "";
  fieldTextType: boolean;

  constructor(private formBuilder: FormBuilder, public data: BackendService, private router: Router, private auth: AuthService) { }

  ngOnInit() {
    if(this.auth.loggedIn) {
      this.router.navigate(['/']);
    }
    this.messageForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    this.submitted = true;
    this.wasError = false;
    this.inProgress = true;

    if (this.messageForm.invalid) {
      this.inProgress = false;
      return;
    }

    this.auth.login(this.messageForm.controls.username.value, this.messageForm.controls.password.value)
      .pipe(first())
      .subscribe(result => {
        this.data.gotLoggedOut = false;
        this.data.loggedOutMsg = '';
        this.router.navigate(['/']);
      }, error => {
        this.wasError = true;
        this.error = error;
        this.submitted = false;
        this.inProgress = false;
      })

    /*this.data.authUser(this.messageForm.controls.username.value, this.messageForm.controls.password.value).subscribe(data => {
      try {
        localStorage.setItem('currentUser', data["username"])
        localStorage.setItem('teljesNev', data["teljesnev"])
        localStorage.setItem('fordatipus', data["fordatipus"])
        localStorage.setItem('fordaszam', data["fordaszam"])
        localStorage.setItem('email', data["email"]);
        localStorage.setItem('telefon', data["telefon"])
      }
      catch(err) {
        this.wasError = true;
        this.error = `Sikertelen volt a bejelentkeztetési folyamat! Lehet, hogy inkognitómódban van a böngésződ?`;
        this.submitted = false;
        this.inProgress = false;
      }
      if(!this.wasError) {
        this.router.navigate(['/']);
      }
    },
    error => {
      this.wasError = true;
      this.error = error;
      this.submitted = false;
      this.inProgress = false;
    })*/
  }

  toggleFieldTextType() {
    this.fieldTextType = !this.fieldTextType;
  }

}
