import { Component, OnInit } from '@angular/core';
import { BackendService } from '../../backend.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-allomas',
  templateUrl: './allomas.component.html',
  styleUrls: ['./allomas.component.css']
})
export class AllomasComponent implements OnInit {

  myStation;
  allStations = {};
  states = [];
  error = "";
  closeResult: string;
  wasError;
  reqType = "";

  constructor(private data: BackendService, private modalService: NgbModal, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.reqType = localStorage.getItem('reqType');
    this.data.getAllomasLista().subscribe(data => {
      this.allStations = data;
    }, error => {
      console.log(error);
    })
    this.data.getAllomasinfo(this.route.snapshot.params['id'], localStorage.getItem('reqType')).subscribe(data => {
      this.myStation = data;
    }, error => {
      this.wasError = true;
      this.error = error;
    })
  }

  ugrasSzemelyzetre() {
    this.modalService.dismissAll('Szemelyzet click');
  }

  jumpToTrain(vonatszam) {
    this.router.navigate(['vonatinfo', vonatszam]);
  }

  checkColor(erkM, erkT, erkMSz, erkTSz) {
    if(erkM < erkT && erkMSz != erkTSz) {
      return "red";
    }
    else {
      return "green";
    }
  }

  displayViszonylatszam(fontId) {
    if(fontId) {
      return String.fromCharCode(fontId)
    }
    else {
      return '';
    }
    
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
