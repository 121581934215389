<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article class="card-body">
        <h4 class="text-center">Hibalista - {{azonosito}}</h4>
        <h5 class="text-center" *ngIf="jarmu">{{jarmu.sorozatjel}} ({{jarmu.honallomas}})</h5>
        <h5 class="text-center" *ngIf="!jarmu && !jarmuLoading">Ismeretlen jármű</h5>
        <p *ngIf="isLoading" style="color: white;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Hibalista betöltése...</p>
        <div *ngIf="wasError" class="alert alert-danger" role="alert">
            {{error}}
          </div>
        <div *ngIf="ticketLista && ticketLista.length == 0" class="alert alert-success" role="alert">
            Még nincs rögzítve hibajegy. Hurrá!
        </div>
        <div *ngIf="ticketLista">
            <div (click)="jumpToTicket(ticket.id)" *ngFor="let ticket of ticketLista" class="card text-white mb-3" [ngClass]="ticket.state == '0' ? 'bg-danger' : 'bg-success'" style="width: 100%">
                <div class="card-body">
                    <h5 class="card-title"><i class="fa" [ngClass]="ticket.state == '0' ? 'fa-times' : 'fa-check'"></i> {{data.jarmuHibak[ticket.type]}}</h5>
                    <p class="card-text m-0"><i class="fa fa-user"></i> {{ticket.rogzitoNeve}}</p>
                    <p class="card-text"><i class="fa fa-calendar"></i> {{ticket.rogzitesIdeje}}</p>
                </div>
            </div>
            <!--<div class="card text-white bg-success mb-3" style="width: 100%">
                <div class="card-body">
                    <h5 class="card-title"><i class="fa fa-check"></i> Hibás feljáróajtó</h5>
                    <p class="card-text m-0"><i class="fa fa-user"></i><i class="fa fa-check"></i> Czink Bendegúz</p>
                    <p class="card-text"><i class="fa fa-calendar"></i><i class="fa fa-check"></i> 2020. 07. 03. 10:17</p>
                </div>
            </div>-->
        </div>
        <hr>
        <button routerLink="new" class="btn btn-block btn-success"><i class="fa fa-plus"></i> Új hibajegy felvétele</button>
    </article>
</div>