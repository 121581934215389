<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Válassz dátumot!</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Válaszd ki a dátumot, amelyik napon a megtekinteni kívánt vonat elindult!
      <ul *ngIf="possibleIDs" class="list-group"> 
        <li (click)="modal.close('close')" [routerLink]="[ID.VonatID]" *ngFor="let ID of possibleIDs" class="list-group-item">{{ID.Datum}}</li>
      </ul>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Mégsem</button>
    </div>
  </ng-template>

<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
  <article *ngIf="!myTrain.Vonat" class="card-body">
    <h4 class="text-center">Vonatinformáció</h4>
    <h4 class="text-center">Kérem a vonatszámot!</h4>
    <form [formGroup]="vonatinfoForm" (ngSubmit)="onSubmit()">
      <div *ngIf="wasError" class="alert alert-danger" role="alert">
          {{error}}
      </div>
      <div class="form-group">
          <div *ngIf="submitted && vonatinfoForm.controls.szam.errors">
            <div *ngIf="vonatinfoForm.controls.szam.errors.required">
              <div class="alert alert-danger" role="alert">
                Vonatszám nélkül vonatinfó? Kérem a vonatszámot!
              </div>
            </div>
          </div>
          <input class="form-control" type="tel" formControlName="szam" style="text-align: center; font-size: 24px; font-weight: bold;">
      </div> <!-- form-group// --> 
      <div class="form-group">
          <button [disabled]="inProgress" type="submit" class="btn btn-success btn-block"> 
            <span *ngIf="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Mehet!  </button>
      </div> <!-- form-group// -->
      <div class="recent">
        <h4>Legutóbbi vonatszámok</h4>
        <table style="width: 100%">
          <tr class="hatter" *ngFor="let vonat of lastTrainNums">
            <td>
              <a (click)="goToEarlierNumber(vonat.vonatszam)">{{vonat.vonatszam}} ({{vonat.viszonylat}})</a>
            </td>
          </tr>
        </table>
      </div>    
    </form>
    <hr>
    <h4 class="text-center">Személyzet ellenőrzés</h4>
    <h4 class="text-center">Kérem a vonatszámot!</h4>
    <form [formGroup]="szemelyzetForm" (ngSubmit)="onSubmitSz()">
      <div *ngIf="wasErrorSz" class="alert alert-danger" role="alert">
          {{errorSz}}
      </div>
      <div class="form-group">
          <div *ngIf="submitted && szemelyzetForm.controls.szam.errors">
            <div *ngIf="szemelyzetForm.controls.szam.errors.required">
              <div class="alert alert-danger" role="alert">
                A vonatszám megadása kötelező!
              </div>
            </div>
          </div>
          <input class="form-control" type="tel" formControlName="szam" style="text-align: center; font-size: 24px; font-weight: bold;">
      </div> <!-- form-group// --> 
      <div class="form-group">
          <button [disabled]="inProgress" type="submit" class="btn btn-success btn-block"> 
            <span *ngIf="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Mehet!  </button>
      </div> <!-- form-group// -->
    </form>
    <hr>
    <h4 class="text-center">Tervezett összeállítás</h4>
    <h4 class="text-center">Kérem a vonatszámot!</h4>
    <form>
      <div class="form-group">
        <input [(ngModel)]="osszeallitasVonatszam" name="vonatszam" class="form-control" type="tel" style="text-align: center; font-size: 24px; font-weight: bold;">
      </div>
      <div class="form-group">
        <button (click)="goToOsszeallitas()" [disabled]="osszeallitasVonatszam == ''" type="button" class="btn btn-success btn-block">Mehet!</button>
      </div>
    </form>
  </article>

  
</div>
