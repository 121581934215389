<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title"><i class="fa fa-exclamation-triangle"></i> Figyelem!</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    Arra készülsz, hogy töröld az eddig rögzített számlálási adatokat.<br><br>
    FIGYELEM! Ez a művelet NEM vonható vissza. Biztos?
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-danger" (click)="resetSzamlalo(); modal.close('Delete click');"><i class="fa fa-trash"></i> Törlés</button>
    <button type="button" class="btn btn-success" (click)="modal.close('Save click')"><i class="fa fa-check"></i> Mégsem</button>
  </div>
</ng-template>

<div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
  <article *ngIf="!myTrain.Vonat" class="card-body">
    <h4 class="text-center">Kerékpárszámlálás</h4>
    <div *ngIf="earlierTrainnum">
      <h4 class="text-center">Korábbi számlálás folytatása</h4>
      <button [routerLink]="['/kerekparok', earlierTrainID]" class="btn btn-success btn-block">{{earlierTrainnum}} sz. vonat számlálása</button>
    </div>
    <hr *ngIf="earlierTrainnum">
    <h4 class="text-center">Add meg a vonatszámot, ahol kerékpárt számolsz!</h4>
    <h6 class="text-center" style="color: red" *ngIf="earlierTrainnum"><i class="fa fa-exclamation-triangle"></i> <b>Figyelem!</b> Ha új vonatszámot adsz meg, akkor a korábbi vonat ({{earlierTrainnum}}) számlálási adatai elvesznek! Ez nem vonható vissza.</h6>
    <form [formGroup]="vonatinfoForm" (ngSubmit)="onSubmit()">
      <div *ngIf="wasError" class="alert alert-danger" role="alert">
          {{error}}
      </div>
      <div class="form-group">
          <div *ngIf="submitted && vonatinfoForm.controls.szam.errors">
            <div *ngIf="vonatinfoForm.controls.szam.errors.required">
              <div class="alert alert-danger" role="alert">
                A vonatszám megadása kötelező!
              </div>
            </div>
          </div>
          <input class="form-control" type="tel" formControlName="szam" style="text-align: center; font-size: 24px; font-weight: bold;">
      </div> <!-- form-group// --> 
      <div class="form-group">
          <button [disabled]="inProgress" type="submit" class="btn btn-success btn-block"> 
            <span *ngIf="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Mehet!  </button>
      </div> <!-- form-group// -->    
    </form>
  </article>
  <article *ngIf="myTrain.Vonat" class="card-body">
    <h4 class="text-center">{{myTrain.Vonat.Szam}} sz. vonat kerékpárjai</h4>
    <h4 class="text-center">A vonaton jelenleg <b>{{bicycleCount.onTrain}} db</b> kerékpár van</h4>
    <table style="width: 100%; border-collapse: collapse;">
      <tr>
        <th></th>
        <th>leszálló</th>
        <th>felszálló</th>
      </tr>
      <tr *ngFor="let allomas of myTrain.Menetrend; let index = index;" style="border-bottom: 1pt solid white; width: 100%; border-top: 1pt solid white;">
        <td class="rovid">{{allomas.AllomasNev}}</td>
        <td>
          <input [disabled]="index == 0" (change)="updateBicycles(index, 'leaving')" [(ngModel)]="bicycleCount.leaving[index]" style="width: 70px; margin-bottom: 5px; margin-top: 5px; color: red; text-align: center; font-weight: bold;" value="0" type="tel"> <br> 
          <button [disabled]="index == 0" (click)="modifyLeaving(index, -1)" class="btn btn-danger" style="margin-right: 5px; margin-bottom:5px;">-</button><button (click)="modifyLeaving(index, 1)" [disabled]="index == 0" class="btn btn-success" style="margin-bottom:5px;">+</button>
        </td>
        <td>
          <input [(ngModel)]="bicycleCount.boarding[index]" (change)="updateBicycles(index, 'boarding')" style="width: 70px; margin-bottom: 5px; margin-top: 5px; color: green; text-align: center; font-weight: bold;" value="0" type="tel"> <br> 
          <button class="btn btn-danger" (click)="modifyBoarding(index, -1)" style="margin-right: 5px; margin-bottom:5px;">-</button><button (click)="modifyBoarding(index, 1)" class="btn btn-success" style="margin-bottom:5px;">+</button>
        </td>
      </tr>
    </table>
    <hr>
    <button class="btn btn-success btn-block" (click)="saveData()"> 
        <i class="fa fa-paper-plane"></i> Adatok rögzítése</button>
    <button class="btn btn-danger btn-block" (click)="open(content)"> 
        <i class="fa fa-times"></i> Adatok törlése</button>  
  </article>
</div>
