import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendService } from '../backend.service';
import { Router } from '@angular/router';
import { FordaReszletek } from './fordatipus';

@Component({
  selector: 'app-choose-forda',
  templateUrl: './choose-forda.component.html',
  styleUrls: ['./choose-forda.component.css']
})
export class ChooseFordaComponent implements OnInit {
  fordaForm: FormGroup;
  submitted = false;
  inProgress = false;
  wasError = false;
  error = "";
  message = "";
  username = "";
  teljesnev = "";
  fordatipus = "";
  fordaszam = "";
  fordaAdatok = {};
  allStations = {};
  selectedHely = null;
  selectedTelep = 'Budapest';
  szolgalatStatusz = "";
  allomasKod = "";
  munkakorKod = "";
  munkakorok = [];
  telephely = '';
  fordaTipusok: {
    'Budapest': FordaReszletek[],
    'Szombathely': FordaReszletek[],
    'Nagykanizsa': FordaReszletek[],
    'Pécs': FordaReszletek[],
    'Miskolc': FordaReszletek[],
    'Debrecen': FordaReszletek[],
    'Szeged': FordaReszletek[],
    'Tapolca': FordaReszletek[],
  }

  constructor(private formBuilder: FormBuilder, private data: BackendService, private router: Router) { }

  ngOnInit() {
    this.fordaTipusok = {
      'Budapest': [
        {megnevezes: 'Hangos', rovidites: 'H'},
        {megnevezes: 'Hangos és kerékpáros', rovidites: 'HK'},
        {megnevezes: 'Utasellátó segítő', rovidites: 'UE'},
        {megnevezes: 'Kerékpáros', rovidites: 'K'},
        {megnevezes: 'Retró hétvége ajtófelügyelő', rovidites: 'RA'},
        {megnevezes: 'Retró hétvége hangosító', rovidites: 'RH'},
      ],
      'Szombathely': [
        {megnevezes: 'Utasellátó segítő', rovidites: 'UE'},
        {megnevezes: 'Hangos', rovidites: 'H'},
        {megnevezes: 'Hangos és kerékpáros', rovidites: 'HK'},
        {megnevezes: 'Kerékpáros', rovidites: 'K'}
      ],
      'Nagykanizsa': [
        {megnevezes: 'Hangos', rovidites: 'H'},
        {megnevezes: 'Hangos és kerékpáros', rovidites: 'HK'},
        {megnevezes: 'Kerékpáros', rovidites: 'K'}
      ],
      'Pécs': [
        {megnevezes: 'Hangos', rovidites: 'H'},
        {megnevezes: 'Hangos és kerékpáros', rovidites: 'HK'},
        {megnevezes: 'Kerékpáros', rovidites: 'K'}
      ],
      'Miskolc': [
        {megnevezes: 'Hangos', rovidites: 'H'},
        {megnevezes: 'Hangos és kerékpáros', rovidites: 'HK'},
        {megnevezes: 'Kerékpáros', rovidites: 'K'}
      ],
      'Debrecen': [
        {megnevezes: 'Hangos', rovidites: 'H'},
        {megnevezes: 'Hangos és kerékpáros', rovidites: 'HK'},
        {megnevezes: 'Kerékpáros', rovidites: 'K'}
      ],
      'Szeged': [
        {megnevezes: 'Hangos', rovidites: 'H'},
        {megnevezes: 'Hangos és kerékpáros', rovidites: 'HK'},
        {megnevezes: 'Kerékpáros', rovidites: 'K'}
      ],
      'Tapolca': [
        {megnevezes: 'Hangos', rovidites: 'H'},
        {megnevezes: 'Hangos és kerékpáros', rovidites: 'HK'},
        {megnevezes: 'Kerékpáros', rovidites: 'K'}
      ],
    }
    this.buildForm();
    this.setUserCategoryValidators();
    this.username = localStorage.getItem('currentUser');
    this.teljesnev = localStorage.getItem('teljesNev');
    this.fordatipus = localStorage.getItem('fordatipus');
    this.fordaszam = localStorage.getItem('fordaszam');
    this.szolgalatStatusz = localStorage.getItem('szolgalatStatusz');
    this.allomasKod = localStorage.getItem('allomasKod');
    this.munkakorKod = localStorage.getItem('munkakorKod');
    this.telephely = localStorage.getItem('telephely');
    this.munkakorok = this.data.munkakorok;

    if(this.szolgalatStatusz != "0") {
      if(this.szolgalatStatusz == "2") {
        var sign = this.fordatipus + "" + this.fordaszam;
        this.data.getFordaData(sign, this.telephely).subscribe(data => {
          this.fordaAdatok = data;
        }, error => {
          console.log(error);
        })
      }
    }

    this.data.getAllomasLista().subscribe(data => {
      this.allStations = data;
    }, error => {
      console.log(error);
    })
  }

  buildForm() {
    this.fordaForm = this.formBuilder.group({
      szolgTipus: [null, Validators.required],
      telep: ['Budapest', Validators.required],
      tipus: [null, Validators.required],
      szam: ['', Validators.required],
      allomas: [null, Validators.required],
      munkakor: [null, Validators.required],
      szolgVege: ['']
    });
  }

  setUserCategoryValidators() {
    const fordatipusCtrl = this.fordaForm.get('tipus');
    const fordaszamCtrl = this.fordaForm.get('szam');
    const allomasCtrl = this.fordaForm.get('allomas');
    const munkakorCtrl = this.fordaForm.get('munkakor');
    const szolgVegeCtrl = this.fordaForm.get('szolgVege');

    this.fordaForm.get('szolgTipus').valueChanges
      .subscribe(userCategory => {

        if (userCategory === '1') { // állomás
          allomasCtrl.setValidators([Validators.required]);
          munkakorCtrl.setValidators([Validators.required]);
          fordaszamCtrl.setValidators(null);
          fordatipusCtrl.setValidators(null);
        }

        if (userCategory === '2') { // vonat
          allomasCtrl.setValidators(null);
          munkakorCtrl.setValidators(null);
          fordaszamCtrl.setValidators([Validators.required]);
          fordatipusCtrl.setValidators([Validators.required]);
        }

        fordatipusCtrl.updateValueAndValidity();
        fordaszamCtrl.updateValueAndValidity();
        munkakorCtrl.updateValueAndValidity();
        allomasCtrl.updateValueAndValidity();
      });
  }

  onSubmit() {
    this.submitted = true;
    this.inProgress = true;
    this.wasError = false;

    if (this.fordaForm.invalid) {
      this.inProgress = false;
      console.log("Érvénytelen form!")
      return;
    }

    var ertek1, ertek2, ido = '';
    if(this.fordaForm.controls.szolgTipus.value == "1") {
      ertek1 = this.fordaForm.controls.allomas.value;
      ertek2  = this.fordaForm.controls.munkakor.value;
      ido = ("0" + this.fordaForm.controls.szolgVege.value['hour']).substr(-2) + ':' + ("0" + this.fordaForm.controls.szolgVege.value['minute']).substr(-2);
    }
    else {
      ertek1 = this.fordaForm.controls.tipus.value;
      ertek2 = this.fordaForm.controls.szam.value;
    }
    this.data.chooseForda(this.username, this.fordaForm.controls.szolgTipus.value, ertek1, ertek2, ido, this.selectedTelep).subscribe(data => {
      //console.log(data)
      try {
        if(data["szolgalatStatusz"] == '2') {
          localStorage.setItem('fordatipus', data["fordatipus"])
          localStorage.setItem('fordaszam', data["fordaszam"])
        }
        else {
          localStorage.setItem('allomasKod', data["allomasKod"])
          localStorage.setItem('munkakorKod', data["munkakorKod"])
        }
      }
      catch(err) {
        this.wasError = true;
        this.error = `A művelet sikertelen volt.`;
        this.submitted = false;
        this.inProgress = false;
      }
      if(!this.wasError) {
        this.router.navigate(['/']);
      }
  },
  error => {
    this.wasError = true;
    this.error = error;
    this.submitted = false;
    this.inProgress = false;
  })
}

onConfirm() {
    this.submitted = false;
    this.inProgress = true;

    this.data.chooseForda(this.username, -1, this.fordatipus, this.fordaszam).subscribe(data => {
      //console.log(data)
      try {
        localStorage.setItem('fordatipus', 'N/A')
        localStorage.setItem('fordaszam', '-1')
        localStorage.setItem('allomasKod', '0')
        localStorage.setItem('munkakorKod', '0')
        localStorage.setItem('szolgalatStatusz', '0')
      }
      catch(err) {
        this.wasError = true;
        this.error = `A művelet sikertelen volt.`;
        this.submitted = false;
        this.inProgress = false;
      }
      if(!this.wasError) {
        this.router.navigate(['/']);
      }
  },
  error => {
    this.wasError = true;
    this.error = error;
    this.submitted = false;
    this.inProgress = false;
  })
}

getVonatID(vonatszam) {
  this.data.checkVonatszam(vonatszam.toString()).subscribe(data => {
    if(data.length > 1) {
      this.router.navigate(['/vonatinfo', data[1]['VonatID']]);
    }
    else {
      this.router.navigate(['/vonatinfo', data[0]['VonatID']]);
    }
  })
}

ugrasVonatra(vonatszam) {
  //localStorage.setItem('vonatszam', vonatszam);
  // már nem működik, VonatID kell a vonatinfóhoz
  this.router.navigate(['/vonatinfo', vonatszam]);
}

}
