<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
  <article *ngIf="newsState == 0" class="card-body">
    <h4 class="text-center">MÁVINFORM</h4>
    <div *ngIf="wasError" class="alert alert-danger" role="alert">
      {{error}}
    </div>
    <p *ngIf="isLoading && !wasError" style="color: white;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Hírek betöltése folyamatban</p>
    <table style="width: 100%">
      <tr *ngFor="let hir of RssData?.rss.channel[0].item; let index = index" [ngStyle]="{'background-color': index % 2 ? 'none' : '#4287f5'}">
        <td style="padding: 5px"><b><a style="text-decoration: none; color: white;" [href]="hir.link[0]">{{hir.title[0]}}</a></b><br>
        <span style="float: right">{{hir.pubDate[0] | date: 'yyyy.MM.dd. HH:mm'}}</span>
        </td>
      </tr>
      <!--<tr *ngFor="let hir of allNews.Hirek; let index = index" [ngStyle]="{'background-color': index % 2 ? 'none' : '#4287f5'}">
        <td (click)="displayHir(hir.ID)" style="padding: 5px"><b>{{hir.Cim}}</b><br>
        <span (click)="displayHir(hir.ID)" style="float: right;">{{hir.Datum * 1000 | date:'yyyy.MM.dd. HH:mm'}}</span></td>
        <!--<td>{{hir.Datum * 1000 | date:'yyyy.MM.dd. HH:mm'}}</td>-->
      <!--</tr>-->
    </table>
  </article>
  <article *ngIf="newsState == 1" class="card-body">
    <p [innerHTML]="myNews.Hirek[0].Tartalom"></p>
    <a style="text-decoration: underline" (click)="resetNews()"><i class="fa fa-arrow-left"></i> Vissza az összes hírhez</a>
  </article>
</div>