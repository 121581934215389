<div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
  <article class="card-body">
  <h4 class="card-title mb-4 mt-1">Bejelentkezés</h4>
  <div class="alert alert-danger">
    <i class="fa fa-exclamation-triangle" style="margin-right: 5px;"></i><b>Figyelem!</b><br> A DTC webes verziója már nem támogatott. Kérlek, <a href="/download">töltsd le az alkalmazást!</a>
 </div>
    <div *ngIf="wasError" class="alert alert-danger" role="alert">
      {{error}}
    </div>
    <div *ngIf="data.gotLoggedOut" class="alert alert-danger" role="alert">
      {{data.loggedOutMsg}}
    </div>
     <form [formGroup]="messageForm" (ngSubmit)="onSubmit()">
      <div class="form-group">
        <label>Felhasználónév</label>
          <div *ngIf="submitted && messageForm.controls.username.errors">
            <div *ngIf="messageForm.controls.username.errors.required">
              <div class="alert alert-danger" role="alert">
                A felhasználónév megadása kötelező!
              </div>
            </div>
          </div>
          <input name="" class="form-control" placeholder="Felhasználónév" type="text" formControlName="username">
      </div> <!-- form-group// -->
      <div class="form-group">
        <label>Jelszó</label>
          <div *ngIf="submitted && messageForm.controls.password.errors">
            <div *ngIf="messageForm.controls.password.errors.required">
              <div class="alert alert-danger" role="alert">
                A jelszó megadása kötelező!
              </div>
            </div>
          </div>
          <div class="input-group">
            <input class="form-control" placeholder="******" [type]="fieldTextType ? 'text' : 'password'" formControlName="password">
            <div class="input-group-append">
              <span class="input-group-text">
                <i
                  class="fa"
                  [ngClass]="{
                    'fa-eye-slash': !fieldTextType,
                    'fa-eye': fieldTextType
                  }"
                  (click)="toggleFieldTextType()"
                ></i>
              </span>
           </div>
          </div>
      </div> <!-- form-group// --> 
      <div class="form-group">
          <button [disabled]="inProgress" type="submit" class="btn btn-primary btn-block"> 
            <span *ngIf="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Bejelentkezés  </button>
      </div> <!-- form-group// -->                                                           
  </form>
  <p>Nincs még felhasználód? <a routerLink="/register">Regisztrálj!</a></p>
  <p>A DTC egy vasúti diákmunkásoknak készült alkalmazás, amely elősegíti a nyári szezonban történő munkavégzést, és a diákok közötti kommunikációt. A regisztráció véglegesítésére egy ellenőrzés után kerül sor.</p>
  <p>Az alkalmazásban megjelenő adatokért a MÁV-START Zrt. semmilyen formában nem vállal felelősséget!</p>
  </article>
  </div> <!-- card.// -->
