<article *ngIf="wasError" class="card-body">
    <div class="alert alert-danger" role="alert">
      {{error}}
    </div>
  </article>
  
  <article *ngIf="!myStation?.station && !wasError" class="card-body">
    <p style="color: white;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Állomásinformációk betöltése...</p>
  </article>
  
  <article *ngIf="myStation?.station" class="card-body">
    <div [ngStyle]="{'background-color': 'white', 'color': 'black', 'padding': '5px', 'margin-bottom': '8px', 'overflow': 'hidden', 'width': '100%'}">
      <h4 class="text-center" style="font-weight: bold">{{myStation.station}}<span [routerLink]="['/szemelyzet/allomas', myStation.station]" style="float: right"><button class="btn btn-success"><i class="fa fa-users"></i></button></span></h4>
      <!--<p *ngIf="myStation.allomasAdatok?.Platform">Nyári szezonhoz kapcsolódó adatok elérhetőek!</p>-->
    </div>
    <div *ngIf="myStation.trains.length == 0">
      <div class="alert alert-primary" role="alert">
        Ezen a szolgálati helyen nincsenek megjeleníthető induló vagy érkező vonatok. {{reqType == 'fromNow' ? 'Próbáld meg átállítani a keresést a mai nap összes vonatára!' : ''}}
      </div>
    </div>
    <table style="width: 100%">
      <tr *ngFor="let vonat of myStation.trains; let index = index" [ngStyle]="{'background-color': index % 2 ? '#ffffff' : '#c2c2c2', color: 'black'}">
        <td>
          {{vonat.schedule.arrival}} <br>
          <span *ngIf="vonat.real?.arrival" [ngStyle]="{'color': checkColor(vonat.schedule.arrival, vonat.real.arrival), 'font-style': checkItalic(vonat.real.arrival)}">{{vonat.real.arrival}}</span>
        </td>
        <td>
          {{vonat.schedule.departure}} <br>  
          <span *ngIf="vonat.real?.departure" [ngStyle]="{'color': checkColor(vonat.schedule.departure, vonat.real.departure), 'font-style': checkItalic(vonat.real.arrival)}">{{vonat.real.departure}}</span>
        </td>
        <td>
            <span>{{vonat.info.code}} </span><span [innerHTML]="vonat.info.info"></span> <span *ngIf="vonat.Vonat?.ViszonylatJeloles?.Jel"> ({{vonat.Vonat.ViszonylatJeloles.Jel}})</span><br>
            <!--{{vonat.line.substring(5, (vonat.line.length - 5))}}-->
            {{vonat.line}}
        </td>
      </tr>
    </table>
  </article>