import { Component, OnInit, AfterViewInit } from '@angular/core';
//import { tileLayer, latLng } from 'leaflet';
//import 'leaflet';
//import 'leaflet-kml';
import * as omnivore from '@mapbox/leaflet-omnivore';
import { BackendService } from '../backend.service';
import { isNull, isNullOrUndefined } from 'util';

declare const L;

@Component({
  selector: 'app-terkep',
  templateUrl: './terkep.component.html',
  styleUrls: ['./terkep.component.css']
})
export class TerkepComponent implements OnInit, AfterViewInit {

  private map;
  options = {};
  greenIcon;
  selected;
  selectedVonatinfo;
  vonatVonal;
  vonatKiemeles;
  kiemelIcon;
  mozdony;
  mozdonyLoading;
  vonatLoading;
  gotMozdonyData;
  terkepLoading = true;
  wasError = false;
  error;
  lat = 47.414619;
  lng = 19.188248;
  menetrendszeruseg = {
    osszesVonat: 0,
    kesoVonatok: 0,
    osszesKeses: 0
  }

  constructor(private data: BackendService) { }

  ngOnInit(): void {
    /*this.options = {
      layers: [
        tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', { maxZoom: 18, attribution: '...' })
      ],
      zoom: 10,
      center: latLng(47.414619, 19.188248)
    };*/
  }

  ngAfterViewInit() {
    this.greenIcon = L.icon({
      iconUrl: '../assets/terkepikonok/vonat.png',
      iconSize: [32, 32]
    })
    this.kiemelIcon = L.icon({
      iconUrl: '../assets/terkepikonok/kiemel.png',
      iconSize: [48, 48]
    })
    this.initMap();
  }

  initMap() {
    /*if (navigator.geolocation) {  
      navigator.geolocation.getCurrentPosition((position: Position) => {  
          if (position) {  
              this.lat = position.coords.latitude;  
              this.lng = position.coords.longitude;   
              console.log(position)
          }
      })
    }*/

    this.map = L.map('map', {
      center: [ this.lat, this.lng ],
      zoom: 10,
      tileSize: 512
    })

    const tiles = new L.TileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {// new L.TileLayer('https://{s}.osm.rrze.fau.de/osmhd/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>, <a href="http://www.mav-szk.hu">MÁV-SZK</a>'
    });

    tiles.addTo(this.map);

    var vonalak = omnivore.kml('../assets/vonalak.kml').addTo(this.map);
    //console.log(vonalak._layers);

    var yellowIcon = L.icon({
      iconUrl: "../assets/terkepikonok/vonat_sarga.png",
      iconSize: [24, 24]
    })
    var orangeIcon = L.icon({
      iconUrl: "../assets/terkepikonok/vonat_narancs.png",
      iconSize: [24, 24]
    })
    var redIcon = L.icon({
      iconUrl: "../assets/terkepikonok/vonat_piros.png",
      iconSize: [24, 24]
    })
    var hevIcon = L.icon({
      iconUrl: "../assets/terkepikonok/hev.png",
      iconSize: [24, 24]
    })
    var gysevIcon = L.icon({
      iconUrl: "../assets/terkepikonok/gysev.png",
      iconSize: [24, 24]
    })

    this.data.getTerkepAdatok().subscribe(data => {
      this.terkepLoading = true;
      data.forEach(vonat => {
        this.menetrendszeruseg.osszesVonat++;
        if(vonat['Keses'] >= 5) {
          this.menetrendszeruseg.kesoVonatok++;
        }
        if(vonat['Keses'] > 0) {
          this.menetrendszeruseg.osszesKeses += vonat['Keses'];
        }
        if(vonat['EGpsLat']) {
          // https://stackoverflow.com/questions/2676719/calculating-the-angle-between-the-line-defined-by-two-points
          var szog = Math.atan2(vonat['GpsLon'] - vonat['EGpsLon'], vonat['GpsLat'] - vonat['EGpsLat']) * 180 / Math.PI;
          if(szog > 0) {
            // https://www.geeksforgeeks.org/round-the-given-number-to-nearest-multiple-of-10/
            szog = Math.round(szog / 10) * 10;
            
            if(szog >= 360) {
              szog = szog % 360;
            }
          }
          else {
            szog = 0;
          }
          var icon_url = "../assets/terkepikonok/vonat_" + szog + ".png";
          this.greenIcon = L.icon({
            iconUrl: icon_url,
            iconSize: [24, 24]
          });
          var marker = L.marker([vonat['EGpsLat'], vonat['EGpsLon']], {icon: this.greenIcon}).addTo(this.map);
          var kesik = false;
          if(vonat['Keses'] >= 5 && vonat['Keses'] < 15) {
            kesik = true;
            var kesesmarker = L.marker([vonat['EGpsLat'], vonat['EGpsLon']], {icon: yellowIcon}).addTo(this.map);
          }
          else if(vonat['Keses'] >= 15 && vonat['Keses'] < 60) {
            kesik = true;
            var kesesmarker = L.marker([vonat['EGpsLat'], vonat['EGpsLon']], {icon: orangeIcon}).addTo(this.map);
          }
          else if(vonat['Keses'] >= 60) {
            kesik = true;
            var kesesmarker = L.marker([vonat['EGpsLat'], vonat['EGpsLon']], {icon: redIcon}).addTo(this.map);
          }
          if(vonat['Menetvonal'] == 'HEV') {
            var hevmarker = L.marker([vonat['EGpsLat'], vonat['EGpsLon']], {icon: hevIcon}).addTo(this.map);
            hevmarker.adatok = vonat;
            hevmarker.on("click", this.onClick.bind(this))
          }
          else if(vonat['Menetvonal'] == 'GYSEV') {
            var gysevmarker = L.marker([vonat['EGpsLat'], vonat['EGpsLon']], {icon: gysevIcon}).addTo(this.map);
            gysevmarker.adatok = vonat;
            gysevmarker.on("click", this.onClick.bind(this))
          }
          else if(kesik) {
            kesesmarker.adatok = vonat;
            kesesmarker.on("click", this.onClick.bind(this));
          }
          else {
            marker.adatok = vonat;
            marker.on("click", this.onClick.bind(this));
          }
          //console.log(this);
        }
        else if(vonat['GpsLat']) {
          // nincs irány
          this.greenIcon = L.icon({
            iconUrl: "../assets/terkepikonok/vonat.png",
            iconSize: [24, 24]
          });
          var marker = L.marker([vonat['GpsLat'], vonat['GpsLon']], {icon: this.greenIcon}).addTo(this.map);
          var kesik = false;
          if(vonat['Keses'] >= 5 && vonat['Keses'] < 15) {
            kesik = true;
            var kesesmarker = L.marker([vonat['GpsLat'], vonat['GpsLon']], {icon: yellowIcon}).addTo(this.map);
          }
          else if(vonat['Keses'] >= 15 && vonat['Keses'] < 60) {
            kesik = true;
            var kesesmarker = L.marker([vonat['GpsLat'], vonat['GpsLon']], {icon: orangeIcon}).addTo(this.map);
          }
          else if(vonat['Keses'] >= 60) {
            kesik = true;
            var kesesmarker = L.marker([vonat['GpsLat'], vonat['GpsLon']], {icon: redIcon}).addTo(this.map);
          }
          if(vonat['Menetvonal'] == 'HEV') {
            var hevmarker = L.marker([vonat['GpsLat'], vonat['GpsLon']], {icon: hevIcon}).addTo(this.map);
            hevmarker.adatok = vonat;
            hevmarker.on("click", this.onClick.bind(this))
          }
          else if(vonat['Menetvonal'] == 'GYSEV') {
            var gysevmarker = L.marker([vonat['GpsLat'], vonat['GpsLon']], {icon: gysevIcon}).addTo(this.map);
            gysevmarker.adatok = vonat;
            gysevmarker.on("click", this.onClick.bind(this))
          }
          else if(kesik) {
            kesesmarker.adatok = vonat;
            kesesmarker.on("click", this.onClick.bind(this));
          }
          else {
            marker.adatok = vonat;
            marker.on("click", this.onClick.bind(this));
          }
        }

        if(localStorage.getItem('terkepVonat') && vonat['Vonatszam'] == localStorage.getItem('terkepVonat')) {
          var vonatObject = {
            target: {
              adatok: vonat
            }
          }
          this.previousTrain(vonat);
        }
      });

      let menetrendszeruseg = this.menetrendszeruseg;

      L.Control.textbox = L.Control.extend({
        onAdd: function(map) {
          var text = L.DomUtil.create('div');
          text.id = "mnr_text";
          text.style = "padding: 5px; background-color: rgba(0, 0, 0, 0.6);";
          text.innerHTML = "Menetrendszerűség: <b>" + Math.round((1 - (menetrendszeruseg.kesoVonatok / menetrendszeruseg.osszesVonat)) * 100) + "%</b><br>Összes késés: <b>" + menetrendszeruseg.osszesKeses + " perc</b>";
          text.onclick = () => {
            if(text.innerHTML.includes('Összes késés')) {
              text.innerHTML = 'Menetrendszerűség: <b>' + Math.round((1 - (menetrendszeruseg.kesoVonatok / menetrendszeruseg.osszesVonat)) * 100) + '%</b><br>Összes vonat: <b>' + menetrendszeruseg.osszesVonat + ' db</b>';
            }
            else {
              text.innerHTML = "Menetrendszerűség: <b>" + Math.round((1 - (menetrendszeruseg.kesoVonatok / menetrendszeruseg.osszesVonat)) * 100) + "%</b><br>Összes késés: <b>" + menetrendszeruseg.osszesKeses + " perc</b>";
            }
          }
          return text;
        },
        onRemove: function(map) {

        }
      });

      L.control.textbox = function(opts) { return new L.Control.textbox(opts) }
      L.control.textbox({ position: 'bottomleft' }).addTo(this.map);

      this.terkepLoading = false;
    }, error => {
      this.wasError = true;
      this.error = error;
    })

    //L.marker([47.414619, 19.188248], {icon: this.greenIcon}).addTo(this.map);
  }

  onClick(e) {
    if(localStorage.getItem('terkepVonat') && e.target.adatok['Vonatszam'] != localStorage.getItem('terkepVonat')) {
      localStorage.setItem('terkepVonat', e.target.adatok['Vonatszam']);
    }
    else if(!localStorage.getItem('terkepVonat')) {
      localStorage.setItem('terkepVonat', e.target.adatok['Vonatszam']);
    }
    this.selected = e.target.adatok;
    this.mozdony = {};
    this.mozdonyLoading = true;
    this.gotMozdonyData = false;
    this.vonatLoading = true;
    this.map.setView(e.target.getLatLng());

    this.data.getVonatinfo(e.target.adatok['VonatID']).subscribe(data => {
      this.selectedVonatinfo = data;
      this.vonatLoading = false;
    })

    if(this.vonatVonal) {
      this.map.removeLayer(this.vonatVonal);
    }
    if(this.vonatKiemeles) {
      this.map.removeLayer(this.vonatKiemeles);
    }
    if(e.target.adatok['EGpsLat']) {
      this.vonatKiemeles = L.marker([e.target.adatok['EGpsLat'], e.target.adatok['EGpsLon']], {icon: this.kiemelIcon, zIndexOffset: -1}).addTo(this.map);
    }
    else {
      this.vonatKiemeles = L.marker([e.target.adatok['GpsLat'], e.target.adatok['GpsLon']], {icon: this.kiemelIcon, zIndexOffset: -1}).addTo(this.map);
    }

    this.data.getMozdonyNev(e.target.adatok['VonatID']).subscribe(data => {
      this.mozdony = data;
      if(isNullOrUndefined(data) || data['str6'] == '') {
        this.mozdonyLoading = false;
        this.gotMozdonyData = false;
      }
      else {
        this.mozdonyLoading = false;
        this.gotMozdonyData = true;
      }
    }, error => {
      this.mozdonyLoading = false;
      this.gotMozdonyData = false;
    })

    this.data.getVonal(e.target.adatok['VonatID']).subscribe(data => {
      this.vonatVonal = L.polyline(data, {color: 'red'}).addTo(this.map);
      //this.map.fitBounds(this.vonatVonal.getBounds());
    })
    //TerkepComponent.selected = 
  }

  previousTrain(vonat) {
    this.selected = vonat;
    this.mozdony = {};
    this.mozdonyLoading = true;
    this.gotMozdonyData = false;
    if(vonat['EGpsLat']) {
      this.map.setView(L.latLng(vonat['EGpsLat'], vonat['EGpsLon']));
    }
    else if(vonat['GpsLat']) {
      this.map.setView(L.latLng(vonat['GpsLat'], vonat['GpsLon']));
    }

    this.data.getVonatinfo(vonat['VonatID']).subscribe(data => {
      this.selectedVonatinfo = data;
    })

    if(this.vonatVonal) {
      this.map.removeLayer(this.vonatVonal);
    }
    if(this.vonatKiemeles) {
      this.map.removeLayer(this.vonatKiemeles);
    }
    if(vonat['EGpsLat']) {
      this.vonatKiemeles = L.marker([vonat['EGpsLat'], vonat['EGpsLon']], {icon: this.kiemelIcon, zIndexOffset: -1}).addTo(this.map);
    }
    else {
      this.vonatKiemeles = L.marker([vonat['GpsLat'], vonat['GpsLon']], {icon: this.kiemelIcon, zIndexOffset: -1}).addTo(this.map);
    }

    this.data.getMozdonyNev(vonat['VonatID']).subscribe(data => {
      this.mozdony = data;
      if(isNullOrUndefined(data) || data['str6'] == '') {
        this.mozdonyLoading = false;
        this.gotMozdonyData = false;
      }
      else {
        this.mozdonyLoading = false;
        this.gotMozdonyData = true;
      }
    }, error => {
      this.mozdonyLoading = false;
      this.gotMozdonyData = false;
    })

    this.data.getVonal(vonat['VonatID']).subscribe(data => {
      this.vonatVonal = L.polyline(data, {color: 'red'}).addTo(this.map);
      //this.map.fitBounds(this.vonatVonal.getBounds());
    })
  }
  
  getVonatViszonylat(viszonylatjel) {
    if(viszonylatjel.includes('S')) {
      return 'buszHatter';
    }
    if(viszonylatjel.includes('G')) {
      return 'gyorsHatter';
    }
    if(viszonylatjel.includes('Z')) {
      return 'zonaHatter';
    }
  }

  displayViszonylatszam(fontId) {
    if(fontId) {
      return String.fromCharCode(fontId)
    }
    else {
      return '';
    }
    
  }

}
