import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/backend.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-allomas-elvira',
  templateUrl: './allomas-elvira.component.html',
  styleUrls: ['./allomas-elvira.component.css']
})
export class AllomasElviraComponent implements OnInit {

  myStation;
  wasError = false;
  error = '';
  reqType = "";

  constructor(private data: BackendService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.reqType = localStorage.getItem('reqType');
    this.data.getElviraAllomasinfo(this.route.snapshot.params['id'], localStorage.getItem('reqType')).subscribe(data => {
      this.myStation = data;
    }, error => {
      this.wasError = true;
      this.error = error;
    })
  }

  checkColor(erkMSz, erkTSz) {

    let erkM = new Date();
    let erkMArr = erkMSz.split(':');
    erkM.setHours(erkMArr[0]);
    erkM.setMinutes(erkMArr[1]);

    let erkT = new Date();
    let erkTArr = erkTSz.split(':');
    erkT.setHours(erkTArr[0]);
    erkT.setMinutes(erkTArr[1]);

    if(erkM < erkT && erkMSz != erkTSz) {
      return "red";
    }
    else {
      return "green";
    }
  }

  checkItalic(erkTSz) {

    let valosIdo = new Date();

    let erkT = new Date();
    let erkTArr = erkTSz.split(':');
    erkT.setHours(erkTArr[0]);
    erkT.setMinutes(erkTArr[1]);

    if(valosIdo <= erkT) {
      return "italic";
    }
    else {
      return "normal";
    }
  }

}
