import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-szoveg',
  templateUrl: './szoveg.component.html',
  styleUrls: ['./szoveg.component.css']
})
export class SzovegComponent implements OnInit {
  vonatszam;

  constructor() { }

  ngOnInit(): void {
  }

}
