import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/backend.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { ISzovegkonyv, IKulonleges } from './iszovegkonyv';
import * as PullToRefresh from 'pulltorefreshjs';
import { from, fromEventPattern } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-szoveg-nezet',
  templateUrl: './szoveg-nezet.component.html',
  styleUrls: ['./szoveg-nezet.component.css']
})
export class SzovegNezetComponent implements OnInit, AfterViewInit, OnDestroy {

  ptrinit: any;
  trainNumber;
  myTrain;
  allStations;
  currentIndex; // ez az azonosító nem a szövegkönyvi bejegyzés id propertyjére utal, hanem az array indexére
  szovegkonyv;
  wasError;
  errorMsg;
  isLoading;
  prevVonatszam;
  kozeliAllomas = {};
  customText;
  closeResult;
  kivalasztottSzoveg = null;
  szovegTipusok = [
    'indulás előtt 5 perccel',
    'indulás után azonnal',
    'érkezés előtt 4-5 perccel'
  ]
  generaltSzovegek: ISzovegkonyv[] = [];
  csomopontok = {
    'Budapest-Kelenföld': 'Balaton', 
    'Székesfehérvár': 'nincs', 
    'Siófok': 'nincs', 
    'Fonyód': 'nincs', 
    'Balatonszentgyörgy': 'nincs', 
    'Kőbánya-Kispest': 'nincs',
    'Nagykanizsa': 'nincs',
    'Révfülöp': 'nincs',
    'Balatonfüred': 'nincs'
  }
  // Nem Sv a határ páros: 18404, 18494, 18506, 18708
  // Nem Sv a határ páratlan: 18407, 18403
  megallasiHatarok = {
    'BALATON': 'nincs',
    'TÓPART': 'nincs',
    'AGRAM': 'nincs',
    'GRADEC - BALATON': 'nincs',
    'AGRAM - TÓPART': 'nincs',
    'GRADEC - TÓPART': 'nincs',
    'ARANYPART': 'nincs',
    'EZÜSTPART': 'nincs',
    'ARANYHÍD': 'nincs',
    'DÉLI -> PARTI': 'Székesfehérvár',
    'DÉLI <- PARTI': 'Székesfehérvár',
    'KÉK HULLÁM': 'Balatonfüred'
  }
  vegallomasOverride = {
    'GRADEC - BALATON': 'Keszthely'
  }
  angolVonatnem = {
    'Expresszvonat': 'express train',
    'sebesvonat': 'semi-fast train',
    'gyorsvonat': 'fast train',
    'InterCity': 'InterCity train',
    'személyvonat': 'passenger train'
  }

  friendlyNames = {
    'Budapest-Déli': 'Budapest-Déli pályaudvar',
    'Zagreb Glavni Kol.': 'Zágráb',
    'Budapest-Nyugati': 'Budapest-Nyugati pályaudvar',
    'Budapest-Keleti': 'Budapest-Keleti pályaudvar'
  }

  etkezoSzovegek = [
    'Tisztelt Utasaink! A vonat étkezőkocsijában szívesen látjuk Önöket! Kínálatunkból ajánljuk a korsó csapolt sört 390 Ft-ért, fél literes ásványvizet 250 Ft-ért, üdítőitalokat 390 Ft-ért.',
    'Tisztelt Utasaink! A vonat étkezőkocsijában szívesen látjuk Önöket! Ajánljuk figyelmükbe START Burgerünket 100% marhahúspogácsával 1290 Ft-ért, menüben sült burgonyával és csapolt sörrel vagy szénsavas üdítővel 1890 Ft-ért.',
    'Tisztelt Utasaink! A vonat étkezőkocsijában szívesen látjuk Önöket! Ajánljuk figyelmükbe Classic Burgerünket 100% marhahúspogácsával 1390 Ft-ért, menüben sült burgonyával és csapolt sörrel vagy szénsavas üdítővel 1990 Ft-ért.',
    'Tisztelt Utasaink! A vonat étkezőkocsijában szívesen látjuk Önöket! Kínálatunkból reggelire ajánljuk a főtt virslit 390 Ft-ért, illetve rántott csirkemelles vagy sonkás-sajtos bagett szendvicset 950 Ft-ért.',
    'Tisztelt Utasaink! A vonat étkezőkocsijában szívesen látjuk Önöket! Forró italok széles választékával várjuk Önöket: presszókávé 200 Ft-ért, cappucino, caffe latte, krémcsokoládé, illetve fekete, erdei gyümölcsös vagy borsmentatea 300 Ft-ért kapható.',
    'Tisztelt Utasaink! A vonat étkezőkocsijában szívesen látjuk Önöket! Ajánljuk figyelmükbe desszertjeinket: epres sajttorta, somlói torta, illetve Gönczi kézműves baracklekváros palacsinta 750 Ft-ért.',
    'Tisztelt Utasaink! A vonat étkezőkocsijában szívesen látjuk Önöket! Figyelmükbe ajánéjuk cézár salátánkat grillezett csirkemellel és öntettel 1490 Ft-ért.'
  ]

  kulonlegesSzovegek: {
    'forgalmi': IKulonleges,
    'muszaki': IKulonleges,
    'hatosagi': IKulonleges,
    'biztber': IKulonleges,
    'baleset': IKulonleges,
    'idojaras': IKulonleges
  }

  constructor(private route: ActivatedRoute, private data: BackendService, private dbService: NgxIndexedDBService, private router: Router, private modalService: NgbModal) { }

  ngOnInit() {
    this.kulonlegesSzovegek = {
      'forgalmi': {
        rovidites: 'forgalmi',
        megnevezes: 'Forgalmi okból megállás',
        magyar: 'Tisztelt Utasaink! Járatunk <i>([állomásnév] állomáson)</i> forgalmi okból állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped <i>(at [állomásnév])</i> for traffic reasons. Please do not get off the train. Thank you for your patience and understanding!'
      },
      'muszaki': {
        rovidites: 'muszaki',
        megnevezes: 'Műszaki okból megállás',
        magyar: 'Tisztelt Utasaink! Járatunk <i>(a [mozdony/motorvonat/az egyik kocsi])</i> műszaki hibája miatt állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped due to a technical fault <i>[with the locomotive/DMU/a carriage]</i>. Please do not get off the train. Thank you for your patience and understanding!'
      },
      'hatosagi': {
        rovidites: 'hatosagi',
        megnevezes: 'Hatósági intézkedés miatti megállás',
        magyar: 'Tisztelt Utasaink! Járatunk hatósági intézkedés miatt állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped due to regulatory measures. Please do not get off the train. Thank you for your patience and understanding!'
      },
      'biztber': {
        rovidites: 'biztber',
        megnevezes: 'Bizt. berendezés hiba miatti megállás',
        magyar: 'Tisztelt Utasaink! Járatunk a vasúti biztosítóberendezés hibája miatt állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped due to a fault with the railway signalling system. Please do not get off the train. Thank you for your patience and understanding!'
      },
      'baleset': {
        rovidites: 'baleset',
        megnevezes: 'Baleset miatti megállás',
        magyar: 'Tisztelt Utasaink! Járatunk baleset miatt állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped due to an accident. Please do not get off the train. Thank you for your patience and understanding!'
      },
      'idojaras': {
        rovidites: 'idojaras',
        megnevezes: 'Időjárási ok miatti megállás',
        magyar: 'Tisztelt Utasaink! Járatunk rendkívüli időjárási viszonyok miatt állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped due to an extreme weather conditions. Please do not get off the train. Thank you for your patience and understanding!'
      }
    }
    this.isLoading = true;
    this.trainNumber = this.route.snapshot.params['szam']; // VonatID
    this.prevVonatszam = localStorage.getItem('szovegVonatszam');
    this.currentIndex = localStorage.getItem('szovegkonyvIndex');
    if(this.currentIndex && this.prevVonatszam != this.trainNumber) {
      this.currentIndex = 0;
      localStorage.setItem('szovegkonyvIndex', this.currentIndex);
    }
    localStorage.setItem('szovegVonatszam', this.trainNumber);
    if(!this.currentIndex) {
      this.currentIndex = 0;
      localStorage.setItem('szovegkonyvIndex', this.currentIndex);
    }
    if(this.trainNumber != '') {
      this.data.getVonatinfo(this.trainNumber).subscribe(train => {
        this.myTrain = train;
        this.isLoading = false;
        
        this.data.getAllomasLista().subscribe(data => {
          this.allStations = data;
          this.szovegkonyvGeneralas();
        }, error => {
          console.log(error);
        })
        this.data.getVonatPozicio(this.myTrain.Vonat.Szam).subscribe(data => {
          this.kozeliAllomas = data;
        })
        this.data.getSzovegKonyvForTrain(this.myTrain.Vonat.Szam).subscribe(data => {
          this.szovegkonyv = data;
          /*this.data.getCustomText(this.myTrain.Vonat.Szam, this.szovegkonyv[this.currentIndex].station, this.szovegkonyv[this.currentIndex].type).subscribe(data => {
            this.customText = data[0]['text'];
          })*/
        })
        //console.log(this.myTrain.Vonat.Szam, this.szovegkonyv[this.currentIndex].station, this.szovegkonyv[this.currentIndex].type);
      },
      error => {
        this.isLoading = false;
        this.wasError = true;
        this.errorMsg = error;
      })
    }
  }

  getMegallasiRend(fromAllomas, nyelv) {
    let megallasiRend = '';
    let allomasok = [];
    for(let k = 0; k < this.myTrain['Menetrend'].length; k++) {
      allomasok.push(this.myTrain['Menetrend'][k]['AllomasNev'])
    }
      if(this.megallasiHatarok[this.myTrain['Vonat']['Nev']] == 'nincs' || this.myTrain['Vonat']['Tipus'][0]['Vonatnem'] == 'gyorsvonat') { // FIGYELEM! utószezoni gyorsokra optimalizálva!
        let index = 0;
        if(nyelv == 'magyar') {
          megallasiRend = 'csak '
        }
        else {
          megallasiRend = 'will stop only at '
        }
        let shouldInclude = false;
        let megallasiHelyekCount = 0;
        for(let k = 0; k < this.myTrain['Menetrend'].length; k++) {
          let allomas = this.myTrain['Menetrend'][k];
          if(shouldInclude) {
            if(index == this.myTrain['Menetrend'].length - 1) {
              break;
            }
            if(index != this.myTrain['Menetrend'].length - 2) {
              megallasiHelyekCount++;
              megallasiRend += allomas['AllomasNev'] + ', '
            }
            else {
              megallasiHelyekCount++;
              if(megallasiHelyekCount == 1) {
                if(nyelv == 'magyar') {
                  megallasiRend += '' + allomas['AllomasNev'] + ' állomáson áll meg.'
                }
                else {
                  megallasiRend += '' + allomas['AllomasNev'] + ' station.'
                }
              }
              else {
                if(nyelv == 'magyar') {
                  megallasiRend += 'és ' + allomas['AllomasNev'] + ' állomásokon áll meg.'
                }
                else {
                  megallasiRend += 'and ' + allomas['AllomasNev'] + ' stations.'
                }
              }
            }
          }
          if(allomas['AllomasNev'] == fromAllomas) {
            shouldInclude = true;
          }
          index++;
        }
        if(megallasiHelyekCount == 0) {
          if(nyelv == 'magyar') {
            megallasiRend = `${this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']]}ig nem áll meg.`;
          }
          else {
            megallasiRend = `will not stop until ${this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']]}.`
          }
        }
      }
      // budapest felé megy, a mindenhol megállós szakasz után kell állomást sorolni
      else if(this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']].includes('Budapest') || this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']] == 'Záhony' || this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']] == 'Kőbánya-Kispest' || this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']] == 'Szeged' || this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']] == 'Sátoraljaújhely') {
        let index = 0;
        let kulonMegallasiHatar = 'Székesfehérvár';
        /* FIGYELEM! Ha később a DÉLI->PARTI-kon kívül több vonatot is fel akarunk venni külön felsorolós módszerrel, akkor ezt módosítani kell! */
        if(this.myTrain['Vonat']['Szam'] == '18404' || this.myTrain['Vonat']['Szam'] == '18494') {
          kulonMegallasiHatar = 'Lepsény';
        }
        else if(this.myTrain['Vonat']['Nev'] == 'KÉK HULLÁM') {
          kulonMegallasiHatar = 'Balatonfüred';
        }

        if(allomasok.indexOf(fromAllomas) >= allomasok.indexOf(kulonMegallasiHatar)) {
          if(nyelv == 'magyar') {
            megallasiRend = ` csak `
          }
          else {
            megallasiRend = ` will stop only at `
          }
        }
        else {
          if(nyelv == 'magyar') {
            megallasiRend = `${kulonMegallasiHatar}ig minden állomáson és megállóhelyen megáll. `
            if(kulonMegallasiHatar == 'Lepsény' || kulonMegallasiHatar == 'Balatonfüred') {
              megallasiRend += `${kulonMegallasiHatar}től csak `
            }
            else {
              megallasiRend += `${kulonMegallasiHatar}tól csak `
            }
            //megallasiRend = `${this.megallasiHatarok[this.myTrain['Vonat']['Nev']]}ig csak `
          }
          else {
            //megallasiRend = `until ${this.megallasiHatarok[this.myTrain['Vonat']['Nev']]} will stop only at `
            megallasiRend = `until ${kulonMegallasiHatar} will stop at every station. `
            megallasiRend += `From ${kulonMegallasiHatar} it will stop only at `
          }
        }
        let shouldInclude = false;
        let megallasiHelyekCount = 0;
        for(let k = 0; k < this.myTrain['Menetrend'].length; k++) {
          let allomas = this.myTrain['Menetrend'][k];
          if(shouldInclude) {
            /*if(allomas['AllomasNev'] != this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']]) {
              break;
            }*/
            if(this.myTrain['Menetrend'][k+1]['AllomasNev'] == this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']]) {
              megallasiHelyekCount++;
              if(megallasiHelyekCount == 1) {
                if(nyelv == 'magyar') {
                  megallasiRend += '' + allomas['AllomasNev'] + ' állomáson áll meg.'
                }
                else {
                  megallasiRend += '' + allomas['AllomasNev'] + ' station.'
                }
              }
              else {
                if(nyelv == 'magyar') {
                  megallasiRend += 'és ' + allomas['AllomasNev'] + ' állomásokon áll meg.'
                }
                else {
                  megallasiRend += 'and ' + allomas['AllomasNev'] + ' stations.'
                }
              }
              break;
            }
            else {
              megallasiHelyekCount++;
              megallasiRend += allomas['AllomasNev'] + ', '
            }
          }
          // ha a jelenlegi állomás már elhagyta a megállási határt, akkor a felsorolást a jelenlegi állomástól kezdjük, ellenkező esetben felsorolunk mindent a megállási határtól
          if(allomasok.indexOf(fromAllomas) >= allomasok.indexOf(kulonMegallasiHatar)) {
            if(allomas['AllomasNev'] == fromAllomas) {
              shouldInclude = true;
            }
          }
          else {
            if(allomas['AllomasNev'] == this.megallasiHatarok[this.myTrain['Vonat']['Nev']]) {
              shouldInclude = true;
            }
          }
         
          index++;
        }
        /*if(nyelv == 'magyar') {
          megallasiRend += ` ${this.megallasiHatarok[this.myTrain['Vonat']['Nev']]}tól minden állomáson és megállóhelyen megáll.`
        }
        else {
          megallasiRend += ` From ${this.megallasiHatarok[this.myTrain['Vonat']['Nev']]} it will stop at every station.`
        }*/
      }
      else {
        let index = 0;
        let kulonMegallasiHatar = 'Székesfehérvár';
        /* FIGYELEM! Ha később a DÉLI->PARTI-kon kívül több vonatot is fel akarunk venni külön felsorolós módszerrel, akkor ezt módosítani kell! */
        if(this.myTrain['Vonat']['Szam'] == '18404' || this.myTrain['Vonat']['Szam'] == '18494') {
          kulonMegallasiHatar = 'Lepsény';
        }
        else if(this.myTrain['Vonat']['Nev'] == 'KÉK HULLÁM') {
          kulonMegallasiHatar = 'Balatonfüred';
        }
        if(allomasok.indexOf(fromAllomas) >= allomasok.indexOf(kulonMegallasiHatar)) { // ha elértük a megállási határt, vagy átléptük
          if(nyelv == 'magyar') {
            megallasiRend = `minden állomáson és megállóhelyen megáll.`
          }
          else {
            megallasiRend = `will stop at every station.`
          }
          return megallasiRend;
        }
        if(nyelv == 'magyar') {
          megallasiRend = `${kulonMegallasiHatar}ig csak `
        }
        else {
          megallasiRend = `until ${kulonMegallasiHatar} will stop only at `
        }
        let shouldInclude = false;
        let megallasiHelyekCount = 0;
        for(let k = 0; k < this.myTrain['Menetrend'].length; k++) {
          let allomas = this.myTrain['Menetrend'][k];
          if(shouldInclude) {
            if(allomas['AllomasNev'] == kulonMegallasiHatar) {
              break;
            }
            if(this.myTrain['Menetrend'][k+1]['AllomasNev'] == kulonMegallasiHatar) {
              megallasiHelyekCount++;
              if(megallasiHelyekCount == 1) {
                if(nyelv == 'magyar') {
                  megallasiRend += '' + allomas['AllomasNev'] + ' állomáson áll meg.'
                }
                else {
                  megallasiRend += '' + allomas['AllomasNev'] + ' station.'
                }
              }
              else {
                if(nyelv == 'magyar') {
                  megallasiRend += 'és ' + allomas['AllomasNev'] + ' állomásokon áll meg.'
                }
                else {
                  megallasiRend += 'and ' + allomas['AllomasNev'] + ' stations.'
                }
              }
              break;
            }
            else {
              megallasiHelyekCount++;
              megallasiRend += allomas['AllomasNev'] + ', '
            }
          }
          if(allomas['AllomasNev'] == fromAllomas) {
            shouldInclude = true;
          }
          index++;
        }
        // ha nincs felsorolandó megálló, akkor nem áll meg a határig sehol
        if(megallasiHelyekCount == 0) {
          if(nyelv == 'magyar') {
            megallasiRend = `${kulonMegallasiHatar}ig nem áll meg.`
          }
          else {
            megallasiRend = `will not stop until ${kulonMegallasiHatar}.`
          }
        }
        if(nyelv == 'magyar') {
          if(kulonMegallasiHatar == 'Lepsény' || kulonMegallasiHatar == 'Balatonfüred') {
            megallasiRend += ` ${kulonMegallasiHatar}től minden állomáson és megállóhelyen megáll.`
          }
          else {
            megallasiRend += ` ${kulonMegallasiHatar}tól minden állomáson és megállóhelyen megáll.`
          }
        }
        else {
          megallasiRend += ` From ${kulonMegallasiHatar} it will stop at every station.`
        }
      }
      return megallasiRend;
  }

  szovegkonyvGeneralas() {
    // segítség a flowchart
    //let index = 0;
    for(let index = 0; index < this.myTrain['Menetrend'].length; index++) { //*this.myTrain['Menetrend'][0].forEach(allomas => {
      let allomas = this.myTrain['Menetrend'][index];

      let isKozvetlenKocsi = false;
      let kozvetlenKocsi = {
        magyar: '',
        angol: '',
        nemet: ''
      }
      let etkezoKocsi = {
        magyar: '',
        angol: '',
        nemet: ''
      }
      let megallasiRend = '';
      let megallasiHelyekCount = 0;
      if(this.megallasiHatarok[this.myTrain['Vonat']['Nev']] == 'nincs' || this.myTrain['Vonat']['Tipus'][0]['Vonatnem'] == 'gyorsvonat') { // FIGYELEM! utószezonra optimalizálva!!!
        let index = 0;
        megallasiRend = 'csak '
        this.myTrain['Menetrend'].forEach(allomas => {
          if(index != this.myTrain['Menetrend'].length -1) {
            megallasiHelyekCount++;
            megallasiRend += allomas['AllomasNev'] + ', '
          }
          else {
            megallasiHelyekCount++;
            if(megallasiHelyekCount == 1) {
              megallasiRend += '' + allomas['AllomasNev'] + ' állomáson áll meg.'
            }
            else {
              console.log(megallasiHelyekCount);
              megallasiRend += 'és ' + allomas['AllomasNev'] + ' állomásokon áll meg.'
            }
          }
          index++;
        });
      }
      for(let j = 0; j < this.myTrain.Vonat.Szolgaltatasok.length; j++) {
        if(this.myTrain.Vonat.Szolgaltatasok[j]['SzolgNev'] == 'Étkező-/bisztrókocsi' || this.myTrain.Vonat.Szolgaltatasok[j]['SzolgNev'] == 'Étkező-/büfékocsi') {
          let holakocsi = 'közepén';
          let holakocsiA = 'middle';
          if(this.myTrain['Vonat']['Szam'] % 2 == 0) { // páros
            holakocsi = 'végén';
            holakocsiA = 'end';
          }
          else {
            holakocsi = 'elején';
            holakocsiA = 'front';
          }
          etkezoKocsi.magyar = `Tájékoztatjuk Önöket, hogy a vonat ${holakocsi} étkezőkocsi közlekedik, ahol szívesen látjuk Önöket.`
          etkezoKocsi.angol = `There is a dining car at the ${holakocsiA} of the train, where we look forward to welcoming our Guests.`
          break;
        }
      }
      /*if(this.myTrain['Vonat']['Tipus'][0]['Vonatnem'] == 'gyorsvonat' || this.myTrain['Vonat']['Nev'].includes('BALATON')) {
        let holakocsi = 'közepén';
        let holakocsiA = 'middle';
        if(this.myTrain['Vonat']['Szam'] == 1878) {
          holakocsi = 'végén';
          holakocsiA = 'end';
        }
        etkezoKocsi.magyar = `Tájékoztatjuk Önöket, hogy a vonat ${holakocsi} étkezőkocsi közlekedik, ahol szívesen látjuk Önöket.`
        etkezoKocsi.angol = `There is a dining car at the ${holakocsiA} of the train, where we look forward to welcoming our Guests.`
      }*/
      if(this.myTrain['KozvetlenKocsik']) {
        if(this.myTrain['Vonat']['Nev'] == 'GRADEC' || this.myTrain['Vonat']['Szam'] == '840') {
          isKozvetlenKocsi = true;
          kozvetlenKocsi.magyar = `, valamint közvetlen kocsikkal Keszthelyig`
          kozvetlenKocsi.angol = `, and with direct carriages to Keszthely`
        }
        else {
          for(let j = 0; j < this.myTrain['KozvetlenKocsik'].length; j++) {
            if(this.myTrain['KozvetlenKocsik'][j]['Viszonylat']['CelAllomasKod'] != this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']) {
              isKozvetlenKocsi = true;
              kozvetlenKocsi.magyar = `, valamint közvetlen kocsikkal ${this.allStations[this.myTrain['KozvetlenKocsik'][j]['Viszonylat']['CelAllomasKod']]}ig`
              kozvetlenKocsi.angol = `, and with direct carriages to ${this.allStations[this.myTrain['KozvetlenKocsik'][j]['Viszonylat']['CelAllomasKod']]}`
            }
          }
        }
      }
      if(index == 0) { // kiinduló állomás
        // beszállítás
        let magyar0 = `Tisztelt Utasaink! Köszöntjük Önöket járatunk fedélzetén! A ${this.getFriendlyName(this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']])}ig${kozvetlenKocsi.magyar} közlekedő ${this.myTrain['Vonat']['Nev'] == undefined ? '' : this.myTrain['Vonat']['Nev'] + ' '}${this.myTrain['Vonat']['Tipus'][0]['Vonatnem']} néhány perc múlva indul. Kérjük, fejezzék be a fel- és leszállást.`;
        let angol0 = `Dear Passengers, Welcome on board! The ${this.angolVonatnem[this.myTrain['Vonat']['Tipus'][0]['Vonatnem']]} ${this.myTrain['Vonat']['Nev'] == undefined ? '' : this.myTrain['Vonat']['Nev'] + ' '}to ${this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']]}${kozvetlenKocsi.angol} is ready to depart. Please finish the boarding.`
        // üdvözlés - induló állomásnál mindden esetben (köszönjtük ÖNÖKET)
        let magyar1 = `A MÁV-START Zrt. köszönti Önöket a ${this.getFriendlyName(this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']])}ig${kozvetlenKocsi.magyar} közlekedő ${this.myTrain['Vonat']['Nev'] == undefined ? '' : this.myTrain['Vonat']['Nev'] + ' '}${this.myTrain['Vonat']['Tipus'][0]['Vonatnem']}${this.myTrain['Vonat']['Tipus'][0]['Vonatnem'].includes('vonat') ? 'án' : ' vonatán'}. 
                      Járatunk menetrend szerint ${this.getMegallasiRend(allomas['AllomasNev'], 'magyar')} Felhívjuk figyelmüket, hogy a vonaton tilos a dohányzás. 
                      ${etkezoKocsi.magyar} 
                      Tisztelt utasaink! A járvány helyzet miatt a vonaton történő utazás során kötelező az arc eltakarása maszkkal! Ennek elmaradása esetén az utas kizárható az utazásból, a szabálytalanul utazót a vonatszemélyzet leszállíthatja a vonatról. Kellemes utazást kívánunk!`;
        let angol1 = `MÁV-START would like to welcome You on the ${this.angolVonatnem[this.myTrain['Vonat']['Tipus'][0]['Vonatnem']]} ${this.myTrain['Vonat']['Nev'] == undefined ? '' : this.myTrain['Vonat']['Nev'] + ' '}to ${this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']]}${kozvetlenKocsi.angol}.
                      This train ${this.getMegallasiRend(allomas['AllomasNev'], 'angol')}  Please note that smoking is not allowed on the train.
                      ${etkezoKocsi.angol}
                      Dear Passengers! Due to pandemic regulations, facial covering is mandatory with a mask when travelling by train. The person failing to do so can be excluded from travel. We wish you a pleasant journey!`;
        this.generaltSzovegek.push({
          stationId: allomas['AllomasID'],
          stationName: allomas['AllomasNev'],
          type: 0,
          hungarian: magyar0,
          english: angol0
        })
        this.generaltSzovegek.push({
          stationId: allomas['AllomasID'],
          stationName: allomas['AllomasNev'],
          type: 1,
          hungarian: magyar1,
          english: angol1
        })
      }
      else if(index == this.myTrain['Menetrend'].length - 1) { // végállomás
        let magyar = `Tisztelt Utasaink! Rövidesen ${this.getFriendlyName(allomas['AllomasNev'])}, végállomásra érkezünk.
        A MÁV-START személyzete búcsúzik Önöktől. Köszönjük, hogy utazásukhoz a ${this.myTrain['Vonat']['Nev'] == undefined ? '' : this.myTrain['Vonat']['Nev'] + ' '}${this.myTrain['Vonat']['Tipus'][0]['Vonatnem']}${this.myTrain['Vonat']['Tipus'][0]['Vonatnem'].includes('vonat') ? 'ot' : ' vonatot'} választották. 
        Reméljük kellemesen utaztak, és hamarosan viszontláthatjuk Önöket más járatunk fedélzetén is. Viszontlátásra!`
        let angol = `Dear Passengers! We will shortly be arriving at ${allomas['AllomasNev']}, where this train terminates.
        The MÁV-START staff would like to wish You farewell. Thank you for choosing the ${this.angolVonatnem[this.myTrain['Vonat']['Tipus'][0]['Vonatnem']]} ${this.myTrain['Vonat']['Nev'] == undefined ? '' : this.myTrain['Vonat']['Nev'] + ' '}for your journey. We hope you have had a pleasant journey and that we can welcome You on board of our other trains again soon. Goodbye!`
        this.generaltSzovegek.push({
          stationId: allomas['AllomasID'],
          stationName: allomas['AllomasNev'],
          type: 2,
          hungarian: magyar,
          english: angol
        })
      }
      else if(this.csomopontok[allomas['AllomasNev']] == 'nincs' || (this.csomopontok[allomas['AllomasNev']] == 'Balaton' && (this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']] != 'Budapest-Déli' && this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']] != 'Kőbánya-Kispest'))) { // csomóponti állomás
        let magyar0 = `Tisztelt Utasaink! Rövidesen ${allomas['AllomasNev']} állomásra érkezünk.
                      Leszálló utasainknak köszönjük, hogy a vasutat választották. Viszontlátásra!`
        let angol0 = `Dear Passengers! We will shortly be arriving at ${allomas['AllomasNev']}.
        We would like to thank our alighting passengers for travelling with us. Goodbye!`;
        let magyar1 = `A MÁV-START Zrt. köszönti ${allomas['AllomasNev']} állomáson felszálló utasait a ${this.getFriendlyName(this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']])}ig${kozvetlenKocsi.magyar} közlekedő ${this.myTrain['Vonat']['Nev'] == undefined ? '' : this.myTrain['Vonat']['Nev'] + ' '}${this.myTrain['Vonat']['Tipus'][0]['Vonatnem']}${this.myTrain['Vonat']['Tipus'][0]['Vonatnem'].includes('vonat') ? 'án' : ' vonatán'}. 
                      Járatunk menetrend szerint ${this.getMegallasiRend(allomas['AllomasNev'], 'magyar')} Felhívjuk figyelmüket, hogy a vonaton tilos a dohányzás. 
                      ${etkezoKocsi.magyar}  
                      Tisztelt utasaink! A járvány helyzet miatt a vonaton történő utazás során kötelező az arc eltakarása maszkkal! Ennek elmaradása esetén az utas kizárható az utazásból, a szabálytalanul utazót a vonatszemélyzet leszállíthatja a vonatról. Kellemes utazást kívánunk!`;
        let angol1 = `MÁV-START would like to welcome its passengers boarding at ${allomas['AllomasNev']} on the ${this.angolVonatnem[this.myTrain['Vonat']['Tipus'][0]['Vonatnem']]} ${this.myTrain['Vonat']['Nev'] == undefined ? '' : this.myTrain['Vonat']['Nev'] + ' '}to ${this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']]}${kozvetlenKocsi.angol}.
                      This train ${this.getMegallasiRend(allomas['AllomasNev'], 'angol')}  Please note that smoking is not allowed on the train.
                      ${etkezoKocsi.angol}
                      Dear Passengers! Due to pandemic regulations, facial covering is mandatory with a mask when travelling by train. The person failing to do so can be excluded from travel. We wish you a pleasant journey!`;
        this.generaltSzovegek.push({
          stationId: allomas['AllomasID'],
          stationName: allomas['AllomasNev'],
          type: 2,
          hungarian: magyar0,
          english: angol0
        })
        this.generaltSzovegek.push({
          stationId: allomas['AllomasID'],
          stationName: allomas['AllomasNev'],
          type: 1,
          hungarian: magyar1,
          english: angol1
        })
      }
      else if(!this.csomopontok[allomas['AllomasNev']] || (this.csomopontok[allomas['AllomasNev']] == 'Balaton' && (this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']] == 'Budapest-Déli' || this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']] == 'Kőbánya-Kispest'))) { // mezei állomás, csak a következik szükséges
        let magyar = `Tisztelt Utasaink! Rövidesen ${allomas['AllomasNev']} állomásra érkezünk.
        Leszálló utasainknak köszönjük, hogy a vasutat választották. Viszontlátásra!`;
        let angol = `Dear Passengers! We will shortly be arriving at ${allomas['AllomasNev']}.
        We would like to thank our alighting passengers for travelling with us. Goodbye!`;
        this.generaltSzovegek.push({
          stationId: allomas['AllomasID'],
          stationName: allomas['AllomasNev'],
          type: 2,
          hungarian: magyar,
          english: angol
        })
      }
      //index++;
    }
  }

  getFriendlyName(station) {
    if(this.friendlyNames[station]) {
      return this.friendlyNames[station];
    }
    else {
      return station;
    }
  }

  moveBemondas(how) {
    //console.log(this.customText);
    /*if(this.customText != '' && this.customText != undefined) {
      this.data.setCustomText(this.myTrain.Vonat.Szam, this.generaltSzovegek[this.currentIndex].stationName, this.generaltSzovegek[this.currentIndex].type, this.customText).subscribe(data => {
        //console.log('mentve')
      })
    }*/ // az egyéni szöveghez ez kell
    if(how > 0) {
      this.currentIndex++;
      localStorage.setItem('szovegkonyvIndex', this.currentIndex);
    }
    else {
      this.currentIndex--;
      localStorage.setItem('szovegkonyvIndex', this.currentIndex);
    }
    /*this.data.getCustomText(this.myTrain.Vonat.Szam, this.generaltSzovegek[this.currentIndex].stationName, this.generaltSzovegek[this.currentIndex].type).subscribe(data => {
      //console.log(data);
      this.customText = data[0]['text'];
    })*/ // az egyéni szöveghez ez kell
  }

  getIdoForBemondas(allomasKod, szovegTipus) {
    for(let allomas of this.myTrain.Menetrend) {
      if(allomas.AllomasID == allomasKod) {
        switch(szovegTipus) {
          case 0: { 
            // indulás előtt 5 perccel -> az indulási időadatból kell kivonnunk 5 percet
            let date = new Date((allomas.Ido.IndMDatum - 300) * 1000);
            let hours = "0" + date.getHours();
            let minutes = "0" + date.getMinutes();

            let ujIdo = hours.substr(-2) + ':' + minutes.substr(-2);
            return ujIdo;
          }
          case 1: {
            // indulás után azonnal -> az indulási időadatot adjuk vissza egyben
            return allomas.Ido.IndMenetrendi;
          }
          case 2: {
            // érkezés előtt 4-5 perccel -> az érkezési időadatból kell kivonnunk 4 percet
            let date = new Date((allomas.Ido.ErkMDatum - 240) * 1000);
            let hours = "0" + date.getHours();
            let minutes = "0" + date.getMinutes();

            let ujIdo = hours.substr(-2) + ':' + minutes.substr(-2);
            return ujIdo;
          }
        }
        break;
      }
    }
  }

  getValosIdoForBemondas(allomasKod, szovegTipus) {
    for(let allomas of this.myTrain.Menetrend) {
      if(allomas.AllomasID == allomasKod) {
        switch(szovegTipus) {
          case 0: { 
            // indulás előtt 5 perccel -> az indulási időadatból kell kivonnunk 5 percet
            let date;
            if(allomas.Ido.IndBecsDatum) {
              date = new Date((allomas.Ido.IndBecsDatum - 300) * 1000);
            }
            else if(allomas.Ido.IndVarhatoDatum) {
              date = new Date((allomas.Ido.IndVarhatoDatum - 300) * 1000);
            }
            else {
              date = new Date((allomas.Ido.IndTenyDatum - 300) * 1000);
            }
            let hours = "0" + date.getHours();
            let minutes = "0" + date.getMinutes();

            let ujIdo = hours.substr(-2) + ':' + minutes.substr(-2);
            return ujIdo;
          }
          case 1: {
            // indulás után azonnal -> az indulási időadatot adjuk vissza egyben
            if(allomas.Ido.IndVarhato) {
              return allomas.Ido.IndVarhato;
            }
            else {
              return allomas.Ido.IndTeny;
            }
          }
          case 2: {
            // érkezés előtt 4-5 perccel -> az érkezési időadatból kell kivonnunk 4 percet
            let date;
            if(allomas.Ido.ErkBecsDatum) {
              date = new Date((allomas.Ido.ErkBecsDatum - 240) * 1000);
            }
            else if(allomas.Ido.ErkVarhatoDatum) {
              date = new Date((allomas.Ido.ErkVarhatoDatum - 240) * 1000);
            }
            else {
              date = new Date((allomas.Ido.ErkTenyDatum - 240) * 1000);
            }
            let hours = "0" + date.getHours();
            let minutes = "0" + date.getMinutes();

            let ujIdo = hours.substr(-2) + ':' + minutes.substr(-2);
            return ujIdo;
          }
        }
        break;
      }
    }
  }

  jumpToTrain() {
    this.router.navigate(['vonatinfo',  this.trainNumber]);
  }

  ngAfterViewInit() {
    this.ptrinit = PullToRefresh.init({
      mainElement: '#refresh',
      instructionsPullToRefresh: 'Húzd le a frissítéshez',
      instructionsReleaseToRefresh: 'Engedd el a frissítéshez',
      instructionsRefreshing: 'Frissítés...',
      iconArrow: '<i class="fa fa-arrow-up"></i>',
      distMax: 100,
      distReload: 70,
      distThreshold: 80,
      getStyles() {
        return ".__PREFIX__ptr { " +
               "      box-shadow: inset 0 -3px 5px rgba(0, 0, 0, 0.12);" +
               "      pointer-events: none; " +
               "      font-size: 0.85em;  " +
               "      font-weight: bold; " +
               "      top: 0; " +
               "      height: 0; " +
               "      transition: height 0.3s, min-height 0.3s; " +
               "      text-align: center; " +
               "      width: 100%; " +
               "      overflow: hidden; " +
               "      display: flex; " +
               "      " +
               "      align-items: flex-end; " +
               "      align-content: stretch; " +
               "    } " +
               "      .__PREFIX__box { " +
               "    " +
               "    padding: 10px;" +
               "    flex-basis: 100%; " +
               "  } " +
               ".__PREFIX__pull { " +
               "    transition: none; " +
               "  } " +
               ".__PREFIX__text { " +
               "    margin-top: .33em; " +
               "    color: white; " +
               "  } " +
               ".__PREFIX__icon { " +
               "    color: white;" +
               "    transition: transform .3s; " +
               "  } " +
               ".__PREFIX__top { " +
               "    touch-action: pan-x pan-down pinch-zoom; " +
               "  } " +
               ".__PREFIX__release .__PREFIX__icon { " +
               "    transform: rotate(180deg); " +
               "  }";
      },
      onRefresh() {
        window.location.reload();
      }
    })
  }

  checkLekozlekedett() {
    if(this.myTrain['Menetrend'][this.myTrain['Menetrend'].length - 1]['Ido']['ErkTeny'] && !this.myTrain['Vonat']['Kozlekedik']) {
      return true
    }
    else {
      return false
    }
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    PullToRefresh.destroyAll();
  }

}
