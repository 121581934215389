<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
  <article *ngIf="myNews" class="card-body">
    <p [innerHTML]="myNews.Hirek[0].Tartalom"></p>
    <a style="text-decoration: underline" routerLink="/mavinform"><i class="fa fa-arrow-left"></i> Vissza az összes hírhez</a>
  </article>
  <article *ngIf="!myNews && error" class="card-body">
      <div class="alert alert-danger" role="alert">
        {{error}}
      </div>
  </article>
  <article *ngIf="!myNews" class="card-body">
      <p>Betöltés...</p>
  </article>
</div>