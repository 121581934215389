import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from '../../backend.service';

@Component({
  selector: 'app-hir',
  templateUrl: './hir.component.html',
  styleUrls: ['./hir.component.css']
})
export class HirComponent implements OnInit {

  constructor(private route: ActivatedRoute, private router: Router, private data: BackendService) { }
  newsId: number;
  myNews;
  error = "";

  ngOnInit() {
    this.newsId = this.route.snapshot.params['id'];
    this.data.getMavinform(this.newsId).subscribe(data => {
      this.myNews = data;
      console.log(this.myNews);
    },
    error => {
      this.error = "Hiba történt a hír betöltése közben."
    })
  }

}
