<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"><i class="fa fa-exclamation-triangle"></i> Figyelem!</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Arra készülsz, hogy töröld az eddig rögzített számlálási adatokat.<br><br>
      FIGYELEM! Ez a művelet NEM vonható vissza. Biztos?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="resetSzamlalo(); modal.close('Delete click');"><i class="fa fa-trash"></i> Törlés</button>
      <button type="button" class="btn btn-success" (click)="modal.close('Save click')"><i class="fa fa-check"></i> Mégsem</button>
    </div>
  </ng-template>
  
  <ng-template #content2 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"><i class="fa fa-exclamation-triangle"></i> Figyelem!</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      A számlálási adatok mentésével az eddig rögzített adatok lezárásra kerülnek, és később már nem módosíthatóak. Rögzítés előtt ellenőrizd, hogy megfelelőek-e a bevitt adatok.<br><br>
      FIGYELEM! Ez a művelet NEM vonható vissza. Biztos?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-warning" (click)="resetSzamlalo(); modal.close('Delete click');"><i class="fa fa-floppy-o"></i> Mentés</button>
      <button type="button" class="btn btn-success" (click)="modal.close('Save click')"><i class="fa fa-check"></i> Mégsem</button>
    </div>
  </ng-template>
  
  <ng-template #content3 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"><i class="fa fa-copy"></i> Adatok másolása</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      A számlálásod adatait ki tudod másolni innen, és beküldeni például a Messenger csoportba.
      <button class="btn btn-block btn-success" (click)="copy(copytext)"><i class="fa fa-copy"></i> Másolás a vágólapra</button>
      <!--<div class="copy" [innerHTML]="copyText"></div>-->
      <hr>
      <textarea #copytext class="form-control" rows="10">{{copyText}}</textarea>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-success" (click)="modal.close('Save click')"><i class="fa fa-check"></i> Kész</button>
    </div>
  </ng-template>
  
  <div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article *ngIf="osszesites && !wasError && response" class="card-body">
      <div class="alert alert-success" role="alert">
        {{response}} 
      </div>
      <a routerLink=""><button class="btn btn-block btn-success"><i class="fa fa-arrow-left"></i> Vissza a főoldalra</button></a>
    </article>
  
    <article *ngIf="bicycleCounts.length != 0 && !osszesites" class="card-body">
      <h4 class="text-center">Kerékpárok - {{trainNumber}} sz. vonat</h4>
      <h4 class="text-center">Összes kerékpár: <b>{{allBicycles.all}}</b> db</h4>
      <h2 class="text-center">{{bicycleCounts[currentIndex].station}}</h2>
      <p class="title text-center"><i class="fa fa-plus-circle"></i> felszállók</p>
      <input [disabled]="currentIndex == (bicycleCounts.length - 1)" (change)="checkInput('boarding')" [(ngModel)]="bicycleCounts[currentIndex].boarding" type="number" class="nagy felszallo mb-2">
      <div class="row mb-3">
        <div class="col-6 text-center">
          <button [disabled]="currentIndex == (bicycleCounts.length - 1)" (click)="changeCount(-1, 'boarding')" class="btn btn-lg btn-danger" style="width: 100%"><i class="fa fa-minus"></i></button>
        </div>
        <div class="col-6 text-center">
          <button [disabled]="currentIndex == (bicycleCounts.length - 1)" (click)="changeCount(1, 'boarding')" class="btn btn-lg btn-success" style="width: 100%"><i class="fa fa-plus"></i></button>
        </div>
      </div>
      <p class="title text-center"><i class="fa fa-minus-circle"></i> leszállók</p>
      <input [disabled]="currentIndex == 0" (change)="checkInput('leaving')" [(ngModel)]="bicycleCounts[currentIndex].leaving" type="number" class="nagy leszallo mb-2">
      <div class="row">
        <div class="col-6 text-center">
          <button [disabled]="currentIndex == 0" class="btn btn-lg btn-danger" (click)="changeCount(-1, 'leaving')" style="width: 100%"><i class="fa fa-minus"></i></button>
        </div>
        <div class="col-6 text-center">
          <button [disabled]="currentIndex == 0" class="btn btn-lg btn-success" (click)="changeCount(1, 'leaving')" style="width: 100%"><i class="fa fa-plus"></i></button>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-6 text-center">
          <button class="btn btn-lg btn-danger" [disabled]="currentIndex == 0" (click)="changeStation(-1)" style="width: 100%"><i class="fa fa-arrow-left"></i> Előző állomás</button>
        </div>
        <div class="col-6 text-center">
          <button class="btn btn-lg btn-success" [disabled]="currentIndex == (bicycleCounts.length - 1)" (click)="changeStation(1)" style="width: 100%"><i class="fa fa-arrow-right"></i> Következő állomás</button>
        </div>
      </div>
      <hr>
      <button (click)="showOsszesites()" class="btn btn-lg btn-block btn-success"><i class="fa fa-check"></i> Számlálás összesítése</button>
      <button (click)="open(content)" class="btn btn-lg btn-block btn-danger"><i class="fa fa-trash"></i> Eddigi számlálási adatok törlése</button>
    </article>
    <article *ngIf="osszesites && !success" class="card-body">
      <h4 class="text-center">Kerékpárok - {{trainNumber}} sz. vonat</h4>
      <h4 class="text-center">Összesítés - Ellenőrizd az adatokat!</h4>
      <h5 class="text-center">Összes kerékpár: <b>{{allBicycles.all}}</b> db</h5>
      <table style="width: 100%; border-collapse: collapse;">
        <tr>
          <th></th>
          <th>leszálló</th>
          <th>felszálló</th>
        </tr>
        <tr *ngFor="let allomas of bicycleCounts; let index = index;" style="border-bottom: 1pt solid white; width: 100%; border-top: 1pt solid white;">
          <td class="rovid">{{allomas.station}}</td>
          <td>
            <input [disabled]="index == 0" (change)="checkInput('leaving', index)" [(ngModel)]="bicycleCounts[index].leaving" style="width: 70px; margin-bottom: 5px; margin-top: 5px; color: red; text-align: center; font-weight: bold;" value="0" type="number"> <br> 
            <button [disabled]="index == 0" (click)="changeCount(-1, 'leaving', index)" class="btn btn-danger" style="margin-right: 5px; margin-bottom:5px;">-</button><button (click)="changeCount(1, 'leaving', index)" [disabled]="index == 0" class="btn btn-success" style="margin-bottom:5px;">+</button>
          </td>
          <td>
            <input [disabled]="index == (bicycleCounts.length - 1)" [(ngModel)]="bicycleCounts[index].boarding" (change)="checkInput('boarding', index)" style="width: 70px; margin-bottom: 5px; margin-top: 5px; color: green; text-align: center; font-weight: bold;" value="0" type="number"> <br> 
            <button class="btn btn-danger" [disabled]="index == (bicycleCounts.length - 1)" (click)="changeCount(-1, 'boarding', index)" style="margin-right: 5px; margin-bottom:5px;">-</button><button [disabled]="index == (bicycleCounts.length - 1)" (click)="changeCount(1, 'boarding', index)" class="btn btn-success" style="margin-bottom:5px;">+</button>
          </td>
        </tr>
      </table>
      <hr>
      <div *ngIf="wasError && !isLoading" class="alert alert-danger" role="alert">
        {{response}} 
      </div>
      <button [disabled]="isLoading" class="btn btn-warning btn-block" (click)="showOsszesites()"> 
        <i class="fa fa-arrow-left"></i> Vissza az adatbevitelhez</button>
      <button [disabled]="isLoading" class="btn btn-success btn-block" (click)="getTextFromCount(); open(content3)"><i class="fa fa-copy"></i> Adatok másolása</button>
      <button [disabled]="isLoading" class="btn btn-success btn-block" (click)="saveData()"> 
          <i class="fa fa-paper-plane"></i> <span *ngIf="isLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Adatok rögzítése</button>
      <button [disabled]="isLoading" class="btn btn-danger btn-block" (click)="open(content)"> 
          <i class="fa fa-times"></i> Adatok elvetése</button>  
    </article>
  </div>