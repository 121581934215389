import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/backend.service';

@Component({
  selector: 'app-ticketlist',
  templateUrl: './ticketlist.component.html',
  styleUrls: ['./ticketlist.component.css']
})
export class TicketlistComponent implements OnInit {

  azonosito = '';
  ticketLista;
  jarmu;
  isLoading = true;
  wasError = false;
  jarmuLoading = true;
  error;

  constructor(private route: ActivatedRoute, public data: BackendService, private router: Router) { }

  ngOnInit(): void {
    if(this.route.snapshot.params['azon']) {
      this.azonosito = this.route.snapshot.params['azon'];
      this.data.getHibajegyek(this.azonosito).subscribe(data => {
        this.ticketLista = data;
        this.isLoading = false;
      },
      error => {
        this.isLoading = false;
        this.wasError = true;
        this.error = error;
      })
      this.data.getJarmuFromUIC(this.azonosito).subscribe(data => {
        this.jarmu = data[0];
        this.jarmuLoading = false;
      },
      error => {
        this.jarmuLoading = false;
      })
    }
  }

  jumpToTicket(id) {
    this.router.navigate(['/ticket', id])
  }

}
