<h4 class="text-center">{{trainNumber}} sz. vonat diákszemélyzete</h4>
<h5>A vonaton szolgálatot teljesítő fordá(k):</h5>
<div *ngIf="!szemelyzet?.fordak && !isLoading" class="alert alert-danger" role="alert">
  A vonaton egy forda sem teljesít szolgálatot.
</div>
<div *ngIf="isLoading">
  <i class="fa fa-circle-o-notch fa-spin"></i> Az adatok betöltése folyamatban...
</div>
<div *ngFor="let forda of szemelyzet?.fordak">
  {{forda.Sign}} ({{forda.Telephely}}): {{allStations['00' + forda.DepStation]}} - {{allStations['00' + forda.ArriveStation]}}<span *ngIf="forda.Note == 'kiutazás' || forda.Note == 'visszautazás' || forda.Note == 'önköltség'"> ({{forda.Note}})</span><span style="font-style: italic;" *ngIf="forda.Korlatozas"> ({{forda.Korlatozas}})</span>
</div>
<br>
<p>A vonaton szolgálatot teljesítő diák(ok):</p>
<div *ngIf="isLoading">
  <i class="fa fa-circle-o-notch fa-spin"></i> Az adatok betöltése folyamatban...
</div>
<div *ngIf="wasError && !isLoading" class="alert alert-danger" role="alert">
  {{error}}
</div>
<div *ngIf="szemelyzet && szemelyzet.diakok?.length == 0 && !isLoading" class="alert alert-danger" role="alert">
  A vonaton a forda adatok alapján utazik diákszemélyzet, de nincs bejelentkezve senki.
</div>
<div *ngIf="szemelyzet">
  <div *ngFor="let diak of szemelyzet.diakok" class="card text-white bg-primary mb-3" style="width: 100%">
    <div class="card-body" *ngIf="szemelyzet.diakok">
      <h5 class="card-title"><i class="fa fa-user"></i> {{diak.teljesnev}}</h5>
      <p class="card-text m-0"><i class="fa fa-briefcase"></i> {{diak.fordatipus + '' + diak.fordaszam}} ({{diak.telephely}})
      </p>
      <p class="card-text"><i class="fa fa-phone"></i> <a *ngIf="diak.telefon != ''" style="text-decoration: none; color: white;" href="tel:{{diak.telefon}}"> {{diak.telefon != '' ? diak.telefon : 'Nincs' }}</a><span *ngIf="diak.telefon == ''"> Nincs</span></p>
    </div>
  </div>
</div>
<!--<table *ngIf="szemelyzet && szemelyzet.diakok?.length != 0" style="width: 100%">
  <tr *ngFor="let diak of szemelyzet?.diakok">
    <td style="width: 50%">{{diak.teljesnev}}</td>
    <td style="width: 50%" *ngIf="diak.telefon != ''"><a href="tel:{{diak.telefon}}">{{diak.telefon}}</a></td>
    <td style="width: 50%" *ngIf="diak.telefon == ''"><i>Nincs megadva telefonszám</i></td>
  </tr>
</table>-->