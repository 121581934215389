<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article class="card-body">
      <h4 class="text-center">{{trainNumber}} sz. vonat tervezett összeállítása</h4>
      <div *ngIf="wasError" class="alert alert-danger" role="alert">
        {{error}}
      </div>
      <p *ngIf="isLoading && !wasError" style="color: white;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Az összeállítás betöltése folyamatban...</p>
      <div *ngIf="(!osszeallitasData || osszeallitasData.length == 0) && (!isLoading && !wasError)">Az összeállításról nincs adat.</div>
        <div *ngIf="osszeallitasData && osszeallitasData.length > 0">
            <table width="100%">
                <tr style="border-bottom: 1px solid white;" *ngFor="let osszeallitas of osszeallitasData">
                    <td width="50%">{{osszeallitas.osszeallitas}}</td>
                    <td width="50%">{{osszeallitas.terulet}}</td>
                </tr>
            </table>
            <hr>
            <h5>Az egyes szerelvények mai napi fordulója</h5>
            <table width="100%">
                <tr style="border-bottom: 1px solid white;" *ngFor="let osszeallitas of osszeallitasData">
                    <td width="50%">{{osszeallitas.osszeallitas}}</td>
                    <td style="padding: 5px;" width="50%">{{osszeallitas[getAdatbazisNap()]}}</td>
                </tr>
            </table>
        </div>
    </article>
  </div>