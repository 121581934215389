<div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article class="card-body">
    <a routerLink="/login" class="float-right btn btn-outline-primary">Bejelentkezés</a>
    <h4 class="card-title mb-4 mt-1">Regisztráció</h4>
      <div *ngIf="wasError" class="alert alert-danger" role="alert">
        {{error}}
      </div>
      <div *ngIf="success" class="alert alert-success" role="alert">
          A regisztráció sikeres volt! Kis türelmet kérünk, hamarosan ellenőrzés után aktiváljuk a felhasználódat.
        </div>
       <form [formGroup]="messageForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label>Felhasználónév*</label>
            <div *ngIf="submitted && messageForm.controls.username.errors">
              <div *ngIf="messageForm.controls.username.errors.required">
                <div class="alert alert-danger" role="alert">
                  A felhasználónév megadása kötelező!
                </div>
              </div>
            </div>
            <input name="" class="form-control" placeholder="Felhasználónév" type="text" formControlName="username">
        </div> <!-- form-group// -->
        <div class="form-group">
          <label>Jelszó*</label>
            <div *ngIf="submitted && messageForm.controls.password.errors">
              <div *ngIf="messageForm.controls.password.errors.required">
                <div class="alert alert-danger" role="alert">
                  A jelszó megadása kötelező!
                </div>
              </div>
            </div>
            <input class="form-control" placeholder="******" type="password" formControlName="password">
        </div> <!-- form-group// --> 
        <div class="form-group">
          <label>E-mail cím*</label>
            <div *ngIf="submitted && messageForm.controls.email.errors">
              <div *ngIf="messageForm.controls.email.errors.required">
                <div class="alert alert-danger" role="alert">
                  E-mail cím megadása kötelező!
                </div>
              </div>
            </div>
            <input name="" class="form-control" placeholder="E-mail cím" type="email" formControlName="email">
        </div> <!-- form-group// -->
        <div class="form-group">
            <label>Teljes név*</label>
              <div *ngIf="submitted && messageForm.controls.fullname.errors">
                <div *ngIf="messageForm.controls.fullname.errors.required">
                  <div class="alert alert-danger" role="alert">
                    A teljes név megadása kötelező!
                  </div>
                </div>
              </div>
              <input name="" class="form-control" placeholder="Teljes név" type="text" formControlName="fullname">
          </div> <!-- form-group// -->
          <div class="form-group">
              <label>Telefonszám</label>
                <div *ngIf="submitted && messageForm.controls.telefon.errors">
                  <div *ngIf="messageForm.controls.telefon.errors.required">
                    <div class="alert alert-danger" role="alert">
                      A telefonszám megadása kötelező!
                    </div>
                  </div>
                  <div *ngIf="messageForm.controls.telefon.errors.pattern">
                      <div class="alert alert-danger" role="alert">
                        A megadott telefonszám érvénytelen! A helyes formátum: +XXXXXXXXXXX
                      </div>
                    </div>
                </div>
                <input name="" class="form-control" placeholder="Telefonszám" type="tel" formControlName="telefon" pattern="^\+[1-9]{1}[0-9]{7,12}$">
            </div>
            A *-gal jelölt mezők kitöltése kötelező. A telefonszámot ha megadod, akkor az adott vonat diákszemélyzeténél jelenítjük meg.
            <p>A regisztrációval elfogadod az <a href="../../assets/adatvedelmi-tajekoztato.pdf">adatvédelmi tájékoztatót.</a></p> <!-- form-group// -->
        <div class="form-group">
            <button [disabled]="inProgress || success" type="submit" class="btn btn-primary btn-block"> 
              <span *ngIf="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Regisztráció  </button>
        </div> <!-- form-group// -->                                                           
    </form>
    </article>
    </div> <!-- card.// -->
  