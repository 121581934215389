import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from '../backend.service';

@Component({
  selector: 'app-osszeallitas',
  templateUrl: './osszeallitas.component.html',
  styleUrls: ['./osszeallitas.component.css']
})
export class OsszeallitasComponent implements OnInit {

  trainNumber;
  osszeallitasData;
  wasError = false;
  isLoading = true;
  error = "";

  constructor(private data: BackendService, private route: ActivatedRoute) { }

  ngOnInit(): void {
    if(this.route.snapshot.params['szam']) {
      this.trainNumber = this.route.snapshot.params['szam'];
    }

    this.data.checkSZVOR(this.trainNumber).subscribe(data => {
      this.isLoading = false;
      this.osszeallitasData = data;
    },
    error => {
      this.isLoading = false;
      this.wasError = true;
      this.error = error;
    })
  }

  getAdatbazisNap() {
    var maiNap = new Date().getDay();
    var adatbazisNap = "hetfo";
    var kissNap = "hetfo";
    if(maiNap == 0) {
      adatbazisNap = "vasarnap";
      kissNap = "vasarnap";
    }
    else if(maiNap >= 2 && maiNap <= 4) {
      adatbazisNap = "kedd-csutortok";
      switch(maiNap) {
        case 2: 
          kissNap = "kedd";
          break;
        case 3:
          kissNap = "szerda";
          break;
        case 4:
          kissNap = "csutortok";
          break;
      }
    }
    else if(maiNap == 5) {
      adatbazisNap = "pentek";
      kissNap = "pentek";
    }
    else if(maiNap == 6) {
      adatbazisNap = "szombat";
      kissNap = "szombat"
    }

    if(this.osszeallitasData[0]['osszeallitas'].includes('KISS')) {
      return kissNap;
    }
    else {
      return adatbazisNap;
    }
  }

}
