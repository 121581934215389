<header>
  <div class="container">
    <nav class="navbar fixed-top navbar-dark" style="background-color: lightgrey">
      <a routerLink="/" class="logo navbar-brand" style="margin-left: 15px"><img src="./assets/szarnyas.png" width="50px"></a> <!-- 150px volt a starttal-->
      <button routerLink="/" class="float-right btn btn-outline-secondary"><i class="fa fa-home"></i></button>
        <!--<ul class="navbar-nav">
          <li class="homeText nav-item"><a class="nav-link" routerLink="/">Főoldal</a></li>
          <li class="homeIcon nav-item"><a class="nav-link" routerLink="/">I</a></li>
        </ul>-->
    </nav>
  </div>
</header>