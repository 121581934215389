import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { BackendService } from 'src/app/backend.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit, OnDestroy {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  users;

  constructor(private data: BackendService) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      responsive: true,
      scrollX: true
    }
    this.data.getUsers().subscribe(users => {
      this.users = users;
      this.dtTrigger.next();
    })

  }

  ngOnDestroy() {
    this.dtTrigger.unsubscribe();
  }

}
