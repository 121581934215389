<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"><i class="fa fa-exclamation-triangle"></i> Figyelem!</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Arra készülsz, hogy töröld az eddig rögzített számlálási adatokat.<br><br>
      FIGYELEM! Ez a művelet NEM vonható vissza. Biztos?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="resetSzamlalo(); modal.close('Delete click');"><i class="fa fa-trash"></i> Törlés</button>
      <button type="button" class="btn btn-success" (click)="modal.close('Save click')"><i class="fa fa-check"></i> Mégsem</button>
    </div>
  </ng-template>

  <ng-template #content2 let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"><i class="fa fa-check"></i> Előkészítés küldésre</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      A számlálásod adatait ki tudod másolni innen, és beküldeni például a Messenger csoportba.
      <button class="btn btn-block btn-success" (click)="copy(copytext)"><i class="fa fa-copy"></i> Másolás a vágólapra</button>
      <!--<div class="copy" [innerHTML]="copyText"></div>-->
      <hr>
      <textarea #copytext class="form-control" rows="10">{{copyText}}</textarea>
      <hr>
      Beküldés után a lenti gombra nyomva rögzítheted a számlálási adatokat.
    </div>
    <div class="modal-footer">
        <div *ngIf="!isLoading && !success && wasError" class="alert alert-danger">
            {{response}}
        </div>
      <button [disabled]="isLoading" type="button" class="btn btn-success" (click)="saveData(content2);"><i class="fa fa-paper-plane"></i> <b>Adatok rögzítése</b></button>
      <button type="button" class="btn btn-danger" (click)="modal.close('Close click')"><i class="fa fa-times"></i> Vissza</button>
    </div>
  </ng-template>
  
  <div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">

    <article *ngIf="chosenTrain?.megallasiRendId == undefined && !saved" class="card-body">
        <div class="alert alert-danger" role="alert">
            A megadott vonatszámhoz nem sikerült betölteni az utasszámláláshoz szükséges adatokat.
        </div>
    </article>

    <article *ngIf="chosenTrain?.megallasiRendId != undefined && !allPassengers.szakaszeleje && !allPassengers.szakaszvege && !saved" class="card-body">
        <h4 class="text-center">Utasszámlálás - {{trainNumber}} sz. vonat</h4>
        <h4>Add meg a szakaszt, ahol az utasokat számolod!</h4>
        <form [formGroup]="utasokForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <div *ngIf="submitted && utasokForm.controls.szakaszeleje.errors">
                    <div *ngIf="utasokForm.controls.szakaszeleje.errors.required">
                      <div class="alert alert-danger" role="alert">
                        A szakasz elejét kötelező megadni!
                      </div>
                    </div>
                  </div>
                <label for="szakaszeleje">Szakasz kezdőpontja</label>
                <select formControlName="szakaszeleje" id="szakaszeleje" class="form-control">
                    <option *ngFor="let allomas of megallasiRend" [value]="allomas">{{allomas}}</option>
                </select>
            </div>
            <div class="form-group">
                <div *ngIf="submitted && utasokForm.controls.szakaszvege.errors">
                    <div *ngIf="utasokForm.controls.szakaszvege.errors.required">
                      <div class="alert alert-danger" role="alert">
                        A szakasz végét kötelező megadni!
                      </div>
                    </div>
                  </div>
                <label for="szakaszvege">Szakasz végpontja</label>
                <select id="szakaszvege" formControlName="szakaszvege" class="form-control">
                    <option *ngFor="let allomas of megallasiRend" [value]="allomas">{{allomas}}</option>
                </select>
            </div>
            <div class="form-group">
                <button type="submit" class="btn btn-success btn-block"> 
                  Mehet!  </button>
            </div>
        </form>
    </article>
    <article *ngIf="chosenTrain?.megallasiRendId != undefined && allPassengers.szakaszeleje && allPassengers.szakaszvege && !saved" class="card-body">
        <h4 class="text-center">Utasszámlálás - {{trainNumber}} sz. vonat</h4>
        <h4 class="text-center">Összes utas: <b>{{allPassengers.all}}</b> fő</h4>
        <p class="text-center">{{allPassengers.szakaszeleje}} - {{allPassengers.szakaszvege}}</p>

        <div>
            <p class="title text-center"><span style="font-family: 'mnr'">&#222;</span> másodosztályú nem IC kocsik</p>
            <input (change)="checkInput('masodosztaly')" [(ngModel)]="allPassengers.masodosztaly" type="number" class="nagy felszallo mb-2">
            <div class="row mb-3">
                <div class="col-6 text-center">
                <button (click)="changeCount(-1, 'masodosztaly')" class="btn btn-lg btn-danger" style="width: 100%"><i class="fa fa-minus"></i></button>
                </div>
                <div class="col-6 text-center">
                <button (click)="changeCount(1, 'masodosztaly')" class="btn btn-lg btn-success" style="width: 100%"><i class="fa fa-plus"></i></button>
                </div>
            </div>
        </div>

        <div>
            <p class="title text-center"><span style="font-family: 'mnr'">&#213;</span> kerékpárok</p>
            <input (change)="checkInput('kerekpar')" [(ngModel)]="allPassengers.kerekpar" type="number" class="nagy felszallo mb-2">
            <div class="row mb-3">
                <div class="col-6 text-center">
                <button (click)="changeCount(-1, 'kerekpar')" class="btn btn-lg btn-danger" style="width: 100%"><i class="fa fa-minus"></i></button>
                </div>
                <div class="col-6 text-center">
                <button (click)="changeCount(1, 'kerekpar')" class="btn btn-lg btn-success" style="width: 100%"><i class="fa fa-plus"></i></button>
                </div>
            </div>
        </div>

        <div *ngIf="chosenTrain.ic">
            <p class="title text-center"><span style="font-family: 'mnr'">&#222;&#231;</span> másodosztályú IC kocsik</p>
            <input (change)="checkInput('ic')" [(ngModel)]="allPassengers.ic" type="number" class="nagy felszallo mb-2">
            <div class="row mb-3">
                <div class="col-6 text-center">
                <button (click)="changeCount(-1, 'ic')" class="btn btn-lg btn-danger" style="width: 100%"><i class="fa fa-minus"></i></button>
                </div>
                <div class="col-6 text-center">
                <button (click)="changeCount(1, 'ic')" class="btn btn-lg btn-success" style="width: 100%"><i class="fa fa-plus"></i></button>
                </div>
            </div>
        </div>

        <div *ngIf="chosenTrain.elsoosztaly">
            <p class="title text-center"><span style="font-family: 'mnr'">&#221;&#231;</span> első osztályú IC kocsik</p>
            <input (change)="checkInput('elsoosztaly')" [(ngModel)]="allPassengers.elsoosztaly" type="number" class="nagy felszallo mb-2">
            <div class="row mb-3">
                <div class="col-6 text-center">
                <button (click)="changeCount(-1, 'elsoosztaly')" class="btn btn-lg btn-danger" style="width: 100%"><i class="fa fa-minus"></i></button>
                </div>
                <div class="col-6 text-center">
                <button (click)="changeCount(1, 'elsoosztaly')" class="btn btn-lg btn-success" style="width: 100%"><i class="fa fa-plus"></i></button>
                </div>
            </div>
        </div>

        <div *ngIf="chosenTrain.etkezo">
            <p class="title text-center"><span style="font-family: 'mnr'">&#194;</span> étkezőkocsi</p>
            <input (change)="checkInput('etkezo')" [(ngModel)]="allPassengers.etkezo" type="number" class="nagy felszallo mb-2">
            <div class="row mb-3">
                <div class="col-6 text-center">
                <button (click)="changeCount(-1, 'etkezo')" class="btn btn-lg btn-danger" style="width: 100%"><i class="fa fa-minus"></i></button>
                </div>
                <div class="col-6 text-center">
                <button (click)="changeCount(1, 'etkezo')" class="btn btn-lg btn-success" style="width: 100%"><i class="fa fa-plus"></i></button>
                </div>
            </div>
        </div>

        <hr>
        <button (click)="getTextFromCount(); open(content2)" class="btn btn-lg btn-block btn-success"><i class="fa fa-check"></i> Előkészítés küldésre</button>
         <button (click)="open(content)" class="btn btn-lg btn-block btn-danger"><i class="fa fa-trash"></i> Számlálási adatok törlése</button>

    </article>

    <article *ngIf="saved" class="card-body">
        <div class="alert alert-success">
            {{response}}
        </div>
        <a routerLink=""><button class="btn btn-block btn-success"><i class="fa fa-arrow-left"></i> Vissza a főoldalra</button></a>
    </article>
  </div>