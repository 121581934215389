import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BackendService } from '../backend.service';
import { Router } from "@angular/router"
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  messageForm: FormGroup;
  submitted = false;
  inProgress = false;
  success = false;
  wasError = false;
  error = "";

  constructor(private formBuilder: FormBuilder, private data: BackendService, private router: Router, private auth: AuthService) { }

  ngOnInit() {
    if(this.auth.loggedIn) {
      this.router.navigate(['/']);
    }
    this.messageForm = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
      email: ['', Validators.required],
      fullname: ['', Validators.required],
      telefon: ['']
    });
  }

  onSubmit() {
    this.submitted = true;
    this.wasError = false;
    this.inProgress = true;

    if (this.messageForm.invalid) {
      this.inProgress = false;
      return;
    }

    this.data.regUser(this.messageForm.controls.username.value, this.messageForm.controls.password.value, this.messageForm.controls.email.value, this.messageForm.controls.fullname.value, this.messageForm.controls.telefon.value).subscribe(data => {
      /*try {
        localStorage.setItem('currentUser', data["username"])
      }
      catch(err) {
        this.wasError = true;
        this.error = `Sikertelen volt a regisztrációs folyamat! Lehet, hogy inkognitómódban van a böngésződ?`;
        this.submitted = false;
        this.inProgress = false;
      }*/
      if(!this.wasError) {
        this.success = true;
        this.inProgress = false;
        //this.router.navigate(['/']);
      }
    },
    error => {
      this.wasError = true;
      this.error = error;
      this.submitted = false;
      this.inProgress = false;
    })
  }

}

