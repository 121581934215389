import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
//import { BusesComponent } from './buses/buses.component';
//import { PrevdataComponent } from './prevdata/prevdata.component';
import { RegisterComponent } from './register/register.component';
import { MainComponent } from './main/main.component';
//import { ArrdepComponent } from './arrdep/arrdep.component';
//import { BusDataComponent } from './bus-data/bus-data.component';
//import { AddBusComponent } from './add-bus/add-bus.component';
import { VonatinfoComponent } from './vonatinfo/vonatinfo.component';
import { ChooseFordaComponent } from './choose-forda/choose-forda.component';
import { AllomasinfoComponent } from './allomasinfo/allomasinfo.component';
import { MavinformComponent } from './mavinform/mavinform.component';
import { SzemelyzetComponent } from './szemelyzet/szemelyzet.component';
import { KerekparokComponent } from './kerekparok/kerekparok.component';
import { VonatComponent } from './vonatinfo/vonat/vonat.component';
import { HirComponent } from './mavinform/hir/hir.component';
import { AllomasComponent } from './allomasinfo/allomas/allomas.component';
import { AllomasSzemelyzetComponent } from './szemelyzet/allomas-szemelyzet/allomas-szemelyzet.component';
import { VonatSzemelyzetComponent } from './szemelyzet/vonat-szemelyzet/vonat-szemelyzet.component';
import { SzovegkonyvComponent } from './szovegkonyv/szovegkonyv.component';
import { SzovegNezetComponent } from './szovegkonyv/szoveg-nezet/szoveg-nezet.component';
import { KerekparComponent } from './kerekparok/kerekpar/kerekpar.component';

import { AuthGuard } from './auth.guard';
import { UtasokComponent } from './utasok/utasok.component';
import { UtasComponent } from './utasok/utas/utas.component';
import { TerkepComponent } from './terkep/terkep.component';
import { AdminComponent } from './admin/admin.component';
import { UsersComponent } from './admin/users/users.component';
import { UserComponent } from './admin/users/user/user.component';
import { AdminGuard } from './services/admin.guard';
import { SzovegComponent } from './admin/szoveg/szoveg.component';
import { AuthService } from './auth.service';
import { SzerkesztComponent } from './admin/szoveg/szerkeszt/szerkeszt.component';
import { TicketsComponent } from './tickets/tickets.component';
import { TicketlistComponent } from './tickets/ticketlist/ticketlist.component';
import { TicketComponent } from './tickets/ticketlist/ticket/ticket.component';
import { NewticketComponent } from './tickets/ticketlist/newticket/newticket.component';
import { SzovegkonyvOfflineComponent } from './szovegkonyv-offline/szovegkonyv-offline.component';
import { OfflineSzovegNezetComponent } from './szovegkonyv-offline/offline-szoveg-nezet/offline-szoveg-nezet.component';
import { KerekparokOfflineComponent } from './kerekparok-offline/kerekparok-offline.component';
import { KerekparOfflineComponent } from './kerekparok-offline/kerekpar-offline/kerekpar-offline.component';
import { AllomasElviraComponent } from './allomasinfo/allomas-elvira/allomas-elvira.component';
import { OsszeallitasComponent } from './osszeallitas/osszeallitas.component';
import { DownloadComponent } from './download/download.component';
import { AdatkezelesComponent } from './adatkezeles/adatkezeles.component';
import { KapcsolatComponent } from './kapcsolat/kapcsolat.component';

const routes: Routes = [
  { path: '', component: MainComponent, canActivate: [AuthGuard], data: { animation: 'main' }},
  { path: 'login', component: LoginComponent },
  //{ path: 'buses', component: BusesComponent, canActivate: [AuthGuard] },
  //{ path: 'prevdata', component: PrevdataComponent, canActivate: [AuthGuard] },
  { path: 'register', component: RegisterComponent },
  //{ path: 'arrdep', component: ArrdepComponent, canActivate: [AuthGuard] },
  //{ path: 'busData', component: BusDataComponent, canActivate: [AuthGuard] },
  //{ path: 'addBus', component: AddBusComponent, canActivate: [AuthGuard] },
  { path: 'vonatinfo', component: VonatinfoComponent, canActivate: [AuthGuard] },
  { path: 'chooseForda', component: ChooseFordaComponent, canActivate: [AuthGuard] },
  { path: 'allomasinfo', component: AllomasinfoComponent, canActivate: [AuthGuard] },
  { path: 'mavinform', component: MavinformComponent, canActivate: [AuthGuard] },
  { 
    path: 'szemelyzet', 
    component: SzemelyzetComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', component: AllomasSzemelyzetComponent },
      { path: 'allomas', component: AllomasSzemelyzetComponent },
      { path: 'allomas/:allomasnev', component: AllomasSzemelyzetComponent },
      { path: 'vonat', component: VonatSzemelyzetComponent },
      { path: 'vonat/:szam', component: VonatSzemelyzetComponent }
    ]
  },
  { path: 'kerekparok', component: KerekparokComponent, canActivate: [AuthGuard] },
  { path: 'kerekparok/:szam', component: KerekparComponent, canActivate: [AuthGuard] },
  { path: 'vonatinfo/:szam', component: VonatComponent, canActivate: [AuthGuard] },
  { path: 'mavinform/:id', component: HirComponent, canActivate: [AuthGuard] },
  { path: 'allomasinfo/:id', component: AllomasComponent, canActivate: [AuthGuard] },
  { path: 'allomasinfov2/:id', component: AllomasElviraComponent, canActivate: [AuthGuard] },
  { path: 'szovegkonyv', component: SzovegkonyvComponent, canActivate: [AuthGuard] },
  { path: 'szovegkonyv/:szam', component: SzovegNezetComponent, canActivate: [AuthGuard] },
  { path: 'terkep', component: TerkepComponent, canActivate: [AuthGuard] },
  { 
    path: 'admin', 
    component: AdminComponent, 
    canActivate: [AuthGuard, AdminGuard],
    children: [
      { path: 'users', component: UsersComponent },
      { path: 'users/:id', component: UserComponent }
    ]
  },
  { path: 'admin/szoveg', component: SzovegComponent, canActivate: [AuthGuard, AdminGuard]},
  { path: 'admin/szoveg/:szam', component: SzerkesztComponent, canActivate: [AuthGuard, AdminGuard]},
  { path: 'tickets', component: TicketsComponent, canActivate: [AuthGuard]},
  { path: 'tickets/:azon', component: TicketlistComponent, canActivate: [AuthGuard]},
  { path: 'tickets/:azon/new', component: NewticketComponent, canActivate: [AuthGuard]},
  { path: 'ticket/:id', component: TicketComponent, canActivate: [AuthGuard]},
  { path: 'szkonyvoffline', component: SzovegkonyvOfflineComponent, canActivate: [AuthGuard]},
  { path: 'szkonyvoffline/:id', component: OfflineSzovegNezetComponent, canActivate: [AuthGuard]},
  { path: 'kerekparokoff', component: KerekparokOfflineComponent, canActivate: [AuthGuard]},
  { path: 'kerekparokoff/:szam', component: KerekparOfflineComponent, canActivate: [AuthGuard]},
  { path: 'utasok', component: UtasokComponent, canActivate: [AuthGuard] },
  { path: 'utasok/:szam', component: UtasComponent, canActivate: [AuthGuard]},
  { path: 'osszeallitas/:szam', component: OsszeallitasComponent, canActivate: [AuthGuard]},
  { path: 'download', component: DownloadComponent},
  { path: 'adatkezeles', component: AdatkezelesComponent},
  { path: 'kapcsolat', component: KapcsolatComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
