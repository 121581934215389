<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
  <article *ngIf="!myStation.allomasNev" class="card-body">
    <h4 class="text-center">Állomásinformáció</h4>
    <h4 class="text-center">Adj meg egy állomást!</h4>
    <form [formGroup]="allomasinfoForm" (ngSubmit)="onSubmit()">
      <div *ngIf="wasError" class="alert alert-danger" role="alert">
          {{error}}
      </div>
      <div class="form-group">
          <div *ngIf="submitted && allomasinfoForm.controls.allomas.errors">
            <div *ngIf="allomasinfoForm.controls.allomas.errors.required">
              <div class="alert alert-danger" role="alert">
                Az állomást kötelező megadni!
              </div>
            </div>
          </div>
          <input id="typeahead-basic" placeholder="Kezdj el írni egy állomást..." formControlName="allomas" type="text" class="form-control" [(ngModel)]="model" [ngbTypeahead]="search"/>  
          <!--<input class="form-control" placeholder="Állomás neve" type="text" formControlName="allomas">-->
      </div> <!-- form-group// --> 
      <div class="form-group">
        <div class="row">
          <div class="col-6">
            <button (click)="switchMode()" type="button" [className]="reqType == 'fromNow' ? 'btn btn-success btn-block' : 'btn btn-primary btn-block'" class="btn btn-block"><i *ngIf="reqType == 'fromNow'" class="fa fa-check"></i> Aktuális vonatok</button>
          </div>
          <div class="col-6">
            <button (click)="switchMode()" type="button" [className]="reqType == 'allTrains' ? 'btn btn-success btn-block' : 'btn btn-primary btn-block'" class="btn btn-block"><i *ngIf="reqType == 'allTrains'" class="fa fa-check"></i> Összes vonat</button>
          </div>
        </div>
      </div>
      <div class="form-group">
          <button [disabled]="inProgress" type="submit" class="btn btn-success btn-block"> 
            <span *ngIf="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            Mehet!  </button>
      </div> <!-- form-group// -->    
    </form>
    <h4>Legutóbbi állomások</h4>
      <table style="width: 100%">
        <tr class="hatter" *ngFor="let allomas of lastStations">
          <td>
            <a [routerLink]="['/allomasinfov2', allomas]">{{allomas}}</a>
          </td>
        </tr>
      </table>
  </article>
</div>
