import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SzovegkonyvNyarService {

  vonatok = [
    {
      vonatszamok: [1842, 1852, 1844, 1854, 1856, 1848], // Balatonszentgyörgyre TÓPART
      megallasiRendId: 0,
      reverse: false,
      etkezo: false,
      nev: 'TÓPART',
      vonatnem: 'InterCity',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Nagykanizsa',
      friendlyVegallomas: 'Nagykanizsa'
    },
    {
      vonatszamok: [204],
      megallasiRendId: 12,
      reverse: false,
      etkezo: false,
      nev: 'AGRAM-TÓPART',
      vonatnem: 'InterCity',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Zagreb Glavni Kol.',
      friendlyVegallomas: 'Zágráb'
    },
    {
      vonatszamok: [201],
      megallasiRendId: 12,
      reverse: true,
      etkezo: false,
      nev: 'AGRAM-TÓPART',
      vonatnem: 'InterCity',
      irany: 'Budapest',
      induloallomas: 'Zagreb Glavni Kol.',
      vegallomas: 'Budapest-Déli',
      friendlyVegallomas: 'Budapest-Déli pályaudvar'
    },
    {
      vonatszamok: [1862, 1864, 1874, 1866, 1876, 1868, 1878], // Balatonszentgyörgyre BALATON
      megallasiRendId: 7,
      reverse: false,
      etkezo: true,
      nev: 'BALATON',
      vonatnem: 'InterCity',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Keszthely',
      friendlyVegallomas: 'Keszthely'
    },
    {
      vonatszamok: [1860],
      megallasiRendId: 11,
      reverse: false,
      etkezo: true,
      nev: 'BALATON',
      vonatnem: 'InterCity',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Keszthely',
      friendlyVegallomas: 'Keszthely'
    },
    {
      vonatszamok: [1849, 1847, 1857, 1843, 1845, 205, 1853], // Balatonszentgyörgyről TÓPART
      megallasiRendId: 0,
      reverse: true,
      etkezo: false,
      nev: 'TÓPART',
      vonatnem: 'InterCity',
      irany: 'Budapest',
      induloallomas: 'Nagykanizsa',
      vegallomas: 'Budapest-Déli',
      friendlyVegallomas: 'Budapest-Déli pályaudvar'
    },
    {
      vonatszamok: [1867, 1877, 1865, 1875, 1863, 1873, 1861, 1871], // Balatonszentgyörgyről BALATON
      megallasiRendId: 7,
      reverse: true,
      etkezo: true,
      nev: 'BALATON',
      vonatnem: 'InterCity',
      irany: 'Budapest',
      induloallomas: 'Keszthely',
      vegallomas: 'Budapest-Déli',
      friendlyVegallomas: 'Budapest-Déli pályaudvar'
    },
    {
      vonatszamok: [18502, 18504], // Balatonszentgyörgyre
      megallasiRendId: 1,
      reverse: false,
      etkezo: false,
      nev: 'DÉLI->PARTI',
      vonatnem: 'sebesvonat',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Balatonszentgyörgy',
      friendlyVegallomas: 'Balatonszentgyörgy',
      szakaszhatar: 'Székesfehérvár'
    },
    {
      vonatszamok: [18404], // Balatonszentgyörgyre, Lepsény határ
      megallasiRendId: 2,
      reverse: false,
      etkezo: false,
      nev: 'DÉLI->PARTI',
      vonatnem: 'sebesvonat',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Balatonszentgyörgy',
      friendlyVegallomas: 'Balatonszentgyörgy',
      szakaszhatar: 'Lepsény'
    },
    {
      vonatszamok: [18503, 18505, 18403], // Balatonszentgyörgyről
      megallasiRendId: 1,
      reverse: true,
      etkezo: false,
      nev: 'DÉLI->PARTI',
      vonatnem: 'sebesvonat',
      irany: 'Budapest',
      induloallomas: 'Balatonszentgyörgy',
      vegallomas: 'Budapest-Déli',
      friendlyVegallomas: 'Budapest-Déli pályaudvar',
      szakaszhatar: 'Székesfehérvár'
    },
    {
      vonatszamok: [15502],
      megallasiRendId: 3,
      reverse: false,
      etkezo: false,
      nev: 'TISZA-TÓ',
      vonatnem: 'Expresszvonat',
      irany: 'Balaton',
      induloallomas: 'Budapest-Keleti',
      vegallomas: 'Tiszafüred',
      friendlyVegallomas: 'Tiszafüred'
    },
    {
      vonatszamok: [15503],
      megallasiRendId: 3,
      reverse: true,
      etkezo: false,
      nev: 'TISZA-TÓ',
      vonatnem: 'Expresszvonat',
      irany: 'Budapest',
      induloallomas: 'Tiszafüred',
      vegallomas: 'Budapest-Keleti',
      friendlyVegallomas: 'Budapest-Keleti pályaudvar'
    },
    {
      vonatszamok: [19700, 19702, 19704, 19794, 19706],
      megallasiRendId: 9,
      reverse: false,
      etkezo: false,
      nev: 'KÉK HULLÁM',
      vonatnem: 'InterCity',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Tapolca',
      friendlyVegallomas: 'Tapolca',
      szakaszhatar: 'Balatonfüred'
    },
    {
      vonatszamok: [19705, 19795, 19703, 19701],
      megallasiRendId: 9,
      reverse: true,
      etkezo: false,
      nev: 'KÉK HULLÁM',
      vonatnem: 'InterCity',
      irany: 'Budapest',
      induloallomas: 'Tapolca',
      vegallomas: 'Budapest-Déli',
      friendlyVegallomas: 'Budapest-Déli pályaudvar',
      szakaszhatar: 'Balatonfüred'
    },
    {
      vonatszamok: [19712, 19722, 19714, 19718, 19724, 19716, 19726],
      megallasiRendId: 10,
      reverse: false,
      etkezo: false,
      nev: 'VÍZIPÓK',
      vonatnem: 'InterRégió',
      irany: 'Balaton',
      induloallomas: 'Budapest-Déli',
      vegallomas: 'Balatonfüred',
      friendlyVegallomas: 'Balatonfüred',
      szakaszhatar: 'Balatonakarattya'
    },
    {
      vonatszamok: [19715, 19725, 19713, 19723, 19711, 19721, 19771],
      megallasiRendId: 10,
      reverse: true,
      etkezo: false,
      nev: 'VÍZIPÓK',
      vonatnem: 'InterRégió',
      irany: 'Budapest',
      induloallomas: 'Balatonfüred',
      vegallomas: 'Budapest-Déli',
      friendlyVegallomas: 'Budapest-Déli pályaudvar',
      szakaszhatar: 'Balatonakarattya'
    },
    {
      vonatszamok: [15607],
      megallasiRendId: 4,
      reverse: false,
      etkezo: false,
      nev: 'EZÜSTPART',
      vonatnem: 'Expresszvonat',
      irany: 'Balaton',
      induloallomas: 'Miskolc-Tiszai',
      vegallomas: 'Fonyód',
      friendlyVegallomas: 'Fonyód'
    },
    {
      vonatszamok: [15606],
      megallasiRendId: 4,
      reverse: true,
      etkezo: false,
      nev: 'EZÜSTPART',
      vonatnem: 'Expresszvonat',
      irany: 'Budapest',
      induloallomas: 'Fonyód',
      vegallomas: 'Miskolc-Tiszai',
      friendlyVegallomas: 'Miskolc-Tiszai pályaudvar'
    },
    {
      vonatszamok: [16707],
      megallasiRendId: 6,
      reverse: false,
      etkezo: false,
      nev: 'ARANYPART',
      vonatnem: 'Expresszvonat',
      irany: 'Balaton',
      induloallomas: 'Nyíregyháza',
      vegallomas: 'Fonyód',
      friendlyVegallomas: 'Fonyód'
    },
    {
      vonatszamok: [16706],
      megallasiRendId: 6,
      reverse: true,
      etkezo: false,
      nev: 'ARANYPART',
      vonatnem: 'Expresszvonat',
      irany: 'Budapest',
      induloallomas: 'Fonyód',
      vegallomas: 'Nyíregyháza',
      friendlyVegallomas: 'Nyíregyháza'
    },
    {
      vonatszamok: [1972],
      megallasiRendId: 13,
      reverse: false,
      etkezo: false,
      nev: 'TEKERGŐ',
      vonatnem: 'Expresszvonat',
      irany: 'Balaton',
      induloallomas: 'Szolnok',
      vegallomas: 'Tapolca',
      friendlyVegallomas: 'Tapolca'
    },
    {
      vonatszamok: [1973],
      megallasiRendId: 13,
      reverse: true,
      etkezo: false,
      nev: 'TEKERGŐ',
      vonatnem: 'Expresszvonat',
      irany: 'Budapest',
      induloallomas: 'Tapolca',
      vegallomas: 'Szolnok',
      friendlyVegallomas: 'Szolnok'
    },
    {
      vonatszamok: [16907],
      megallasiRendId: 14,
      reverse: false,
      etkezo: false,
      nev: 'TEKERGŐ',
      vonatnem: 'Expresszvonat',
      irany: 'Balaton',
      induloallomas: 'Nyíregyháza',
      vegallomas: 'Zánka-Erzsébettábor',
      friendlyVegallomas: 'Zánka-Erzsébettábor'
    },
    {
      vonatszamok: [16906],
      megallasiRendId: 14,
      reverse: true,
      etkezo: false,
      nev: 'TEKERGŐ',
      vonatnem: 'Expresszvonat',
      irany: 'Budapest',
      induloallomas: 'Zánka-Erzsébettábor',
      vegallomas: 'Nyíregyháza',
      friendlyVegallomas: 'Nyíregyháza'
    }
  ]

  megallasiRendek = [
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Siófok',
      'Zamárdi',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyód',
      'Balatonfenyves',
      'Balatonmáriafürdő',
      'Balatonszentgyörgy',
      'Vörs',
      'Zalakomár',
      'Zalaszentjakab',
      'Nagykanizsa'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Érd alsó',
      'Székesfehérvár',
      'Székesfehévár-Repülőtér',
      'Szabadbattyán',
      'Kiscséripuszta',
      'Polgárdi-Tekerespuszta',
      'Lepsény',
      'Balatonaliga',
      'Balatonvilágos',
      'Szabadisóstó',
      'Szabadifürdő',
      'Siófok',
      'Balatonszéplak felső',
      'Balatonszéplak alsó',
      'Zamárdi felső',
      'Zamárdi',
      'Szántód',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle felső',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyódliget',
      'Fonyód',
      'Bélatelep',
      'Alsóbélatelep',
      'Balatonfenyves',
      'Balatonfenyves alsó',
      'Máriahullámtelep',
      'Máriaszőlőtelep',
      'Balatonmáriafürdő',
      'Balatonberény',
      'Balatonszentgyörgy'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Érd alsó',
      'Székesfehérvár',
      'Lepsény',
      'Balatonaliga',
      'Balatonvilágos',
      'Szabadisóstó',
      'Szabadifürdő',
      'Siófok',
      'Balatonszéplak felső',
      'Balatonszéplak alsó',
      'Zamárdi felső',
      'Zamárdi',
      'Szántód',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle felső',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyódliget',
      'Fonyód',
      'Bélatelep',
      'Alsóbélatelep',
      'Balatonfenyves',
      'Balatonfenyves alsó',
      'Máriahullámtelep',
      'Máriaszőlőtelep',
      'Balatonmáriafürdő',
      'Balatonberény',
      'Balatonszentgyörgy'
    ],
    [
      'Budapest-Keleti',
      'Gödöllő',
      'Füzesabony',
      'Poroszló',
      'Tiszafüred'
    ],
    [
      'Miskolc-Tiszai',
      'Nyékládháza',
      'Mezőkövesd',
      'Füzesabony',
      'Kál-Kápolna',
      'Vámosgyörk',
      'Hatvan',
      'Aszód',
      'Gödöllő',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Balatonaliga',
      'Balatonvilágos',
      'Szabadisóstó',
      'Siófok',
      'Balatonszéplak felső',
      'Zamárdi',
      'Szántód',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle felső',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyódliget',
      'Fonyód'
    ],
    [
      'Fonyód',
      'Fonyódliget',
      'Balatonboglár',
      'Balatonlelle',
      'Balatonlelle felső',
      'Balatonszemes',
      'Balatonszárszó',
      'Balatonföldvár',
      'Szántód',
      'Zamárdi',
      'Zamárdi felső',
      'Balatonszéplak alsó',
      'Balatonszéplak felső',
      'Siófok',
      'Szabadifürdő',
      'Szabadisóstó',
      'Balatonvilágos',
      'Balatonaliga',
      'Lepsény',
      'Polgárdi-Tekerespuszta',
      'Kiscséripuszta',
      'Szabadbattyán',
      'Székesfehévár-Repülőtér',
      'Székesfehérvár',
      'Érd alsó',
      'Budapest-Kelenföld',
      'Budapest-Déli'
    ],
    [
      'Nyíregyháza',
      'Újfehértó',
      'Debrecen-Csapókert',
      'Debrecen',
      'Hajdúszoboszló',
      'Püspökladány',
      'Karcag',
      'Kisújszállás',
      'Törökszentmiklós',
      'Szolnok',
      'Abony',
      'Cegléd',
      'Ferihegy',
      'Kőbánya-Kispest',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Balatonaliga',
      'Szabadisóstó',
      'Szabadifürdő',
      'Siófok',
      'Balatonszéplak felső',
      'Balatonszéplak alsó',
      'Zamárdi',
      'Szántód',
      'Balatonföldvár',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyódliget',
      'Fonyód'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Siófok',
      'Zamárdi',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyód',
      'Balatonfenyves',
      'Balatonmáriafürdő',
      'Balatonberény',
      'Balatonszentgyörgy',
      'Keszthely'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Velence',
      'Velencefürdő',
      'Gárdony',
      'Agárd',
      'Székesfehérvár'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Balatonalmádi',
      'Balatonfüred',
      'Aszófő',
      'Örvényes',
      'Balatonudvari',
      'Fövenyes',
      'Balatonakali-Dörgicse',
      'Zánka-Erzsébettábor',
      'Zánka-Köveskál',
      'Balatonszepezd',
      'Révfülöp',
      'Balatonrendes',
      'Ábrahámhegy',
      'Badacsonytomaj',
      'Badacsony',
      'Badacsonylábdihegy',
      'Badacsonytördemic-Szigliget',
      'Nemesgulács-Kisapáti',
      'Tapolca'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Balatonakarattya',
      'Csittényhegy',
      'Balatonkenese',
      'Balatonfűzfő',
      'Balatonalmádi',
      'Káptalanfüred',
      'Alsóörs',
      'Csopak',
      'Balatonarács',
      'Balatonfüred'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Lepsény',
      'Siófok',
      'Zamárdi',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyód',
      'Balatonfenyves',
      'Balatonmáriafürdő',
      'Balatonberény',
      'Balatonszentgyörgy',
      'Keszthely'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Siófok',
      'Zamárdi',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyód',
      'Balatonfenyves',
      'Balatonmáriafürdő',
      'Balatonszentgyörgy',
      'Vörs',
      'Zalakomár',
      'Zalaszentjakab',
      'Nagykanizsa',
      'Gyékényes',
      'Zagreb Glavni Kol.',
      /*'Krizevci',
      'Vrbovec',
      'Dugo Selo',
      'Zagreb Glavni Kol.'*/
    ],
    [
      'Szolnok',
      'Cegléd',
      'Ferihegy',
      'Kőbánya-Kispest',
      'Budapest-Kelenföld',
      'Velence',
      'Székesfehérvár',
      'Balatonakarattya',
      'Balatonkenese',
      'Balatonfűzfő',
      'Balatonalmádi',
      'Alsóörs',
      'Csopak',
      'Balatonfüred',
      'Balatonakali-Dörgicse',
      'Zánka-Erzsébettábor',
      'Zánka-Köveskál',
      'Révfülöp',
      'Badacsonyörs',
      'Badacsonytomaj',
      'Badacsony',
      'Tapolca'
    ],
    [
      'Nyíregyháza',
      'Újfehértó',
      'Debrecen-Csapókert',
      'Debrecen',
      'Hajdúszoboszló',
      'Püspökladány',
      'Karcag',
      'Kisújszállás',
      'Törökszentmiklós',
      'Szolnok',
      'Abony',
      'Cegléd',
      'Ferihegy',
      'Kőbánya-Kispest',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Balatonaliga',
      'Szabadisóstó',
      'Szabadifürdő',
      'Siófok',
      'Balatonszéplak felső',
      'Balatonszéplak alsó',
      'Zamárdi',
      'Szántód',
      'Balatonföldvár',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyódliget',
      'Fonyód'
    ]
  ]

  elagazoAllomasok = [
    'Budapest-Kelenföld',
    'Székesfehérvár',
    'Siófok',
    'Fonyód',
    'Balatonszentgyörgy',
    'Balatonfüred',
    'Révfülöp',
    'Balatonakarattya'
  ]

  angolVonatnem = {
    'Expresszvonat': 'express train',
    'sebesvonat': 'semi-fast train',
    'gyorsvonat': 'fast train',
    'InterCity': 'InterCity train',
    'személyvonat': 'passenger train',
    'InterRégió': 'InterRegio train'
  }


  constructor() { }

  getMegallasiHelyek(id, reverse) {
    let copy = this.megallasiRendek[id].slice();

    if(reverse) {
      return copy.reverse();
    }
    return copy;
  }
}
