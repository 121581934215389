<p>Felhasználók kezelése</p>
<table datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger" class="row-border hover w-100">
    <thead>
        <tr>
            <th>ID</th>
            <th>Usernév</th>
            <th>Teljes név</th>
            <th>Aktív?</th>
            <th>Művelet</th>
        </tr>
    </thead>
    <tbody style="color: black">
        <tr *ngFor="let user of users">
            <td>{{ user.id }}</td>
            <td>{{ user.username }}</td>
            <td>{{ user.teljesnev }}</td>
            <td>{{ user.aktiv ? 'Igen' : 'Nem' }}</td>
            <td><button [routerLink]="[user.id]" class="btn btn-success"><i class="fa fa-pencil"></i></button></td>
        </tr>
    </tbody>
</table>