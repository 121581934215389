<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{myTrain.Vonat.Szam}} sz. vonat részletei</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <a [routerLink]="['/szemelyzet/vonat', myTrain.Vonat.Szam]" (click)="ugrasSzemelyzetre()" class="btn btn-block btn-success"><i class="fa fa-users"></i> Vonat diákszemélyzete</a>
    <a *ngIf="osszeallitas" [href]="osszeallitas" class="btn btn-block btn-primary"><i class="fa fa-train"></i> Tervezett összeállítás</a>
    <a routerLink='/terkep' (click)="ugrasTerkepre()" *ngIf="myTrain.Vonat.Kozlekedik" class="btn btn-block btn-primary"><i class="fa fa-map"></i> Mutasd a térképen</a>
    <hr>
    <h4>A vonat szolgáltatásai</h4>
    <table>
      <tr *ngFor="let szolg of myTrain.Vonat.Szolgaltatasok">
        <td style="font-family: 'mnr'; font-size: 24px">{{this.displayViszonylatszam(szolg.Jel.FontKod)}}</td>
        <td>{{szolg.SzolgNev}} <span style="color: darkgrey; font-style: italic;" *ngIf="szolg.Viszonylat">({{allStations[szolg.Viszonylat.InduloAllomasKod]}} - {{allStations[szolg.Viszonylat.CelAllomasKod]}})</span></td>
      </tr>
    </table>
    <br>
    <h4>A viszonylat részletei</h4>
    <table>
        <tr *ngFor="let tipus of myTrain.Vonat.Tipus">
            <td>{{allStations[tipus.Viszonylat.InduloAllomasKod]}} - {{allStations[tipus.Viszonylat.CelAllomasKod]}}</td>
            <td><span [ngStyle]="{'color': tipus.Szin}">{{tipus.Vonatnem}}</span></td>
        </tr>
    </table>
    <br>
    <h4>Tervezett összeállítás</h4>
    <div *ngIf="!szvorSzerelveny || szvorSzerelveny.length == 0">Nincs adat.</div>
    <table *ngIf="szvorSzerelveny && szvorSzerelveny.length > 0" width="100%">
      <tr *ngFor="let osszeallitas of szvorSzerelveny">
        <td width="50%">{{osszeallitas.osszeallitas}}</td>
        <td width="50%">{{osszeallitas.terulet}}</td>
      </tr>
    </table>
    <br>
    <h4>
      Közvetlen kocsik
    </h4>
    <table>
      <tr *ngFor="let kocsi of myTrain.KozvetlenKocsik">
        <td>{{kocsi.Vonatszam}}</td>
        <td>{{allStations[kocsi.Viszonylat.InduloAllomasKod ]}} - {{allStations[kocsi.Viszonylat.CelAllomasKod]}}</td>
      </tr>
      <span *ngIf="myTrain.KozvetlenKocsik.length == 0">A vonatban nem közlekedik közvetlen kocsi.</span>
    </table>
    <br>
    <h4>Közlekedési rend</h4>
    <p>{{myTrain.KozlekedesiRend}}</p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">OK</button>
  </div>
</ng-template>

<article *ngIf="!myTrain.Vonat && !error" class="card-body">
  <p style="color: white;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Vonatinformációk betöltése...</p>
</article>

<article *ngIf="!myTrain.Vonat && error" class="card-body">
  <div class="alert alert-danger" role="alert">
    {{error}}
  </div>
</article>

<div id="refresh">
  <article id="vonatinformacio" *ngIf="myTrain.Vonat" class="card-body">
    <div *ngIf="myTrain.Vonat.Tipus.length < 1" style="color: red">Hiba történt a vonat adatainak betöltése közben.</div>
    <div style="position: sticky; top: 3.8rem">
      <div style="text-align: right; color: white; font-size: 28px; background-color: rgb(35, 59, 116);"><b>{{idoString}}</b></div>
      <div (click)="open(content)" [ngStyle]="{'background-color': 'white', 'color': 'black', 'padding': '5px', 'margin-bottom': '8px', 'overflow': 'hidden', 'width': '100%'}">
        <p><b>{{myTrain.Vonat.Szam}}</b><span [ngStyle]="{'color': myTrain.Vonat.Tipus[0].Szin}"> {{myTrain.Vonat.Nev}} {{myTrain.Vonat.Tipus[0].Vonatnem}}</span> <span *ngIf="myTrain.Vonat.ViszonylatJeloles.Jel"> ({{myTrain.Vonat.ViszonylatJeloles.Jel}})</span>
        <span *ngIf="myTrain.Vonat.Info && myTrain.Vonat.Info.Keses" [style.color]="myTrain.Vonat.Info.Keses > 0 ? 'red' : 'green'" style="float: right; clear: both; font-size: 48px; font-style: italic">{{myTrain.Vonat.Info.Keses}}'</span><span *ngIf="!myTrain.Vonat.Info?.Keses && myTrain.Vonat.Kozlekedik" style="float: right; font-size: 48px; font-style: italic; color: green;">0'</span></p>
        ({{myTrain.Menetrend[0].AllomasNev}} - {{myTrain.Menetrend[myTrain.Menetrend.length - 1].AllomasNev}})
        <span *ngIf="myTrain.Vonat.Kozlekedik && kozeliAllomas.longName" style="float: right; clear: both;"><i class="fa fa-map-marker"></i> {{kozeliAllomas.longName}}</span>
        <span *ngIf="myTrain.Vonat.Kozlekedik && !kozeliAllomas.longName" style="float: right; clear: both;"><i class="fa fa-map-marker"></i> Nincs adat</span>
        <span *ngIf="myTrain.Vonat.Kozlekedik" style="float: right; clear: both;"><i class="fa fa-arrow-right"></i> {{myTrain.Vonat.kovetkezoAllomas}}</span>
        <span *ngIf="myTrain.Vonat.Kozlekedik && mozdony?.str6" style="float: right; clear: both;"><i class="fa fa-train"></i> {{mozdony.str6}} {{mozdony.sb2}}</span>
        <span *ngIf="myTrain.Vonat.Kozlekedik && !mozdony?.str6" style="float: right; clear: both;"><i class="fa fa-train"></i> Nincs adat</span>
        <div *ngIf="myTrain.Vonat.Info && myTrain.Vonat.Info.KesesiOk" style="background-color: red; color: white; font-weight: bold; padding-left: 2px; clear: both;">{{myTrain.Vonat.Info.KesesiOk}}</div>
        <div *ngIf="myTrain.Vonat.Info && myTrain.Vonat.Info.HavariaInfo">
            <div *ngFor="let havaria of myTrain.Vonat.Info.HavariaInfo"  style="background-color: red; color: white; font-weight: bold; padding-left: 2px; clear: both;">{{havaria}}</div>
        </div>
        <div *ngIf="!myTrain.Vonat.Kozlekedik && !checkLekozlekedett()"><i>A vonat jelenleg nem közlekedik.</i></div>
        <div *ngIf="checkLekozlekedett()"><i>A vonat már leközlekedett.</i></div>
      </div>
    </div>
    <table style="width: 100%; border-collapse: collapse; color: white;">
      <tr *ngFor="let allomas of myTrain.Menetrend; let i = index" style="border-bottom: 1pt solid white; width: 100%; border-top: 1pt solid white;">
        <td [ngStyle]="{'font-weight': checkBold(allomas.AllomasNev, i)}" (click)="jumpToAllomas(allomas.AllomasNev)"><span *ngIf="checkBold(allomas.AllomasNev, i) == 'bold'">►</span> {{allomas.AllomasNev}}</td> 
        <td *ngIf="allomas.Ido.ErkVarhato">{{allomas.Ido.ErkMenetrendi}}<br><span [ngStyle]="{'color': checkColor(allomas.Ido.ErkMDatum, allomas.Ido.ErkVarhatoDatum, allomas.Ido.ErkMenetrendi, allomas.Ido.ErkVarhato)}"><i>{{allomas.Ido.ErkVarhato}}</i></span></td>
        <td *ngIf="allomas.Ido.ErkTeny">{{allomas.Ido.ErkMenetrendi}}<br><span [ngStyle]="{'color': checkColor(allomas.Ido.ErkMDatum, allomas.Ido.ErkTenyDatum, allomas.Ido.ErkMenetrendi, allomas.Ido.ErkTeny)}">{{allomas.Ido.ErkTeny}}</span></td>
        <td *ngIf="allomas.Ido.ErkMenetrendi && !allomas.Ido.ErkVarhato && !allomas.Ido.ErkTeny">{{allomas.Ido.ErkMenetrendi}}</td>
        <td *ngIf="!allomas.Ido.ErkMenetrendi"></td>
        <td class="menetrendido" *ngIf="allomas.Ido.IndVarhato">{{allomas.Ido.IndMenetrendi}}<br><span [ngStyle]="{'color': checkColor(allomas.Ido.IndMDatum, allomas.Ido.IndVarhatoDatum, allomas.Ido.IndMenetrendi, allomas.Ido.IndVarhato)}"><i>{{allomas.Ido.IndVarhato}}</i></span></td>
        <td class="menetrendido" *ngIf="allomas.Ido.IndTeny">{{allomas.Ido.IndMenetrendi}}<br><span [ngStyle]="{'color': checkColor(allomas.Ido.IndMDatum, allomas.Ido.IndTenyDatum, allomas.Ido.IndMenetrendi, allomas.Ido.IndTeny)}">{{allomas.Ido.IndTeny}}</span></td>
        <td class="menetrendido" *ngIf="allomas.Ido.IndMenetrendi && !allomas.Ido.IndVarhato && !allomas.Ido.IndTeny">{{allomas.Ido.IndMenetrendi}}</td>
        <td class="menetrendido" *ngIf="!allomas.Ido.IndMenetrendi"></td>
      </tr>
    </table>
    <hr>
    <button class="btn btn-success btn-block" (click)="resetVonatszam()"> 
      <i class="fa fa-arrow-left"></i> Másik vonatszám megadása</button>
  </article>
</div>