<div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article class="card-body">
        <h4 class="card-title mb-4 mt-1">{{vonatszam}} sz. vonat - Szövegkönyv felvitel</h4>
        <!-- Formhoz kellenek: 
            * Állomás, amihez a bemondás köthető -> automatikus kiegészítős text input
            * Bemondás típusa (indulás előtt 5 perccel, indulás után azonnal, érkezés előtt 4-5 perccel) -> select
            * Bemondás szövege magyar, angol és német nyelven -> textarea
        -->
        <form [formGroup]="szovegForm" (ngSubmit)="onSubmit()">
            <div class="form-group">
                <label>Bemondáshoz tartozó állomás</label>
                <div *ngIf="submitted && szovegForm.controls.allomas.errors">
                    <div *ngIf="szovegForm.controls.allomas.errors.required">
                        <div class="alert alert-danger" role="alert">
                            Az állomás nevét meg kell adni!
                        </div>
                    </div>
                </div>
                <input id="typeahead-basic" type="text" class="form-control" placeholder="Kezdj el írni egy állomást.." formControlName="allomas" [(ngModel)]="model" [ngbTypeahead]="search">
            </div>
            <div class="form-group">
                <label>Bemondás típusa</label>
                <div *ngIf="submitted && szovegForm.controls.tipus.errors">
                    <div *ngIf="szovegForm.controls.tipus.errors.required">
                        <div class="alert alert-danger" role="alert">
                            A bemondás típusát meg kell adni!
                        </div>
                    </div>
                </div>
                <select class="form-control" id="tipus" formControlName="tipus">
                    <option value="0">Indulás előtt 5 perccel</option>
                    <option value="1">Indulás után azonnal</option>
                    <option value="2">Érkezés előtt 4-5 perccel</option>
                </select>
            </div>
            <div class="form-group">
                <label>Magyar szöveg</label>
                <div *ngIf="submitted && szovegForm.controls.magyar.errors">
                    <div *ngIf="szovegForm.controls.magyar.errors.required">
                        <div class="alert alert-danger" role="alert">
                            A magyar szöveget meg kell adni!
                        </div>
                    </div>
                </div>
                <textarea placeholder="Magyar szöveg" class="form-control" formControlName="magyar"></textarea>
            </div>
            <div class="form-group">
                <label>Angol szöveg</label>
                <textarea placeholder="Angol szöveg (nem kötelező)" class="form-control" formControlName="angol"></textarea>
            </div>
            <div class="form-group">
                <label>Német szöveg</label>
                <textarea placeholder="Német szöveg (nem kötelező)" class="form-control" formControlName="nemet"></textarea>
            </div>
            <div class="form-group">
                <div *ngIf="finished" [ngClass]="wasError ? 'alert alert-danger' : 'alert alert-success'" role="alert">
                    {{message}}
                </div>
                <button type="submit" class="btn btn-block btn-success"><i class="fa fa-check"></i> Mehet!</button>
            </div>
        </form>
    </article>
</div>