<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article class="card-body">
      <h4 class="text-center">Hibajegy központ</h4>
      <form [formGroup]="hibajegyForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
            <label>Hibajegy típusa</label>
            <div *ngIf="submitted && hibajegyForm.controls.hibajegyTipus.errors">
                <div *ngIf="hibajegyForm.controls.hibajegyTipus.errors.required">
                    <div class="alert alert-danger" role="alert">
                    A hibajegy típusát meg kell adni!
                    </div>
                </div>
            </div>
            <select class="form-control" id="hibajegyTipus" formControlName="hibajegyTipus" [(ngModel)]="selectedTipus">
                <option [ngValue]="null" [disabled]="true">Válassz típust!</option>
                <option value="1" [disabled]="true">Állomási hibajegy</option>
                <option value="2">Jármű hibajegy</option>
            </select>
        </div>
        <div class="form-group" id="allomas" *ngIf="selectedTipus == '1'">
            <label>Állomás neve</label>
            <div *ngIf="submitted && hibajegyForm.controls.allomas.errors">
                <div *ngIf="hibajegyForm.controls.allomas.errors.required">
                    <div class="alert alert-danger" role="alert">
                    Az állomás nevét meg kell adni!
                    </div>
                </div>
            </div>
            <input class="form-control" placeholder="Kezdj el írni egy állomást..." type="text" formControlName="allomas">
        </div>
        <div class="form-group" id="jarmu" *ngIf="selectedTipus == '2'">
            <label>Jármű UIC azonosítója</label>
            <div *ngIf="submitted && hibajegyForm.controls.uic.errors">
                <div *ngIf="hibajegyForm.controls.uic.errors.required">
                    <div class="alert alert-danger" role="alert">
                    A jármű UIC azonosítóját meg kell adni!
                    </div>
                </div>
                <div *ngIf="hibajegyForm.controls.uic.errors.pattern">
                    <div class="alert alert-danger" role="alert">
                    Hibás UIC formátum! A helyes formátum: XX XX XXXX XXX-X
                    </div>
                </div>
            </div>
            <input id="typeahead-basic" placeholder="Kezdj el írni egy UIC azonosítót..." formControlName="uic" type="text" class="form-control" [(ngModel)]="model" [ngbTypeahead]="search" pattern="\d{2} \d{2} \d{4} \d{3}-\d" appUicMask/>  
            <!--<input class="form-control" placeholder="UIC azonosító" type="text" formControlName="uic">-->
        </div>
        <div class="form-group">
            <button [disabled]="inProgress" type="submit" class="btn btn-success btn-block"> 
              <span *ngIf="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Mehet!  </button>
        </div>
      </form>
    </article>
</div>