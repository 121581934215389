<ng-template #content let-modal>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title"><i class="fa fa-exclamation-triangle"></i> Figyelem!</h4>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      Arra készülsz, hogy töröld az eddig rögzített számlálási adatokat.<br><br>
      FIGYELEM! Ez a művelet NEM vonható vissza. Biztos?
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="resetSzamlalo(); modal.close('Delete click');"><i class="fa fa-trash"></i> Törlés</button>
      <button type="button" class="btn btn-success" (click)="modal.close('Save click')"><i class="fa fa-check"></i> Mégsem</button>
    </div>
  </ng-template>
  
  <div class="card" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article *ngIf="!myTrain.Vonat" class="card-body">
      <h4 class="text-center">Utasszámlálás</h4>
      <div *ngIf="earlierTrainnum">
        <h4 class="text-center">Korábbi számlálás folytatása</h4>
        <button [routerLink]="['/utasok', earlierTrainnum]" class="btn btn-success btn-block">{{earlierTrainnum}} sz. vonat számlálása</button>
      </div>
      <hr *ngIf="earlierTrainnum">
      <h4 class="text-center">Add meg a vonatszámot, ahol utast számolsz!</h4>
      <h6 class="text-center" style="color: red" *ngIf="earlierTrainnum"><i class="fa fa-exclamation-triangle"></i> <b>Figyelem!</b> Ha új vonatszámot adsz meg, akkor a korábbi vonat ({{earlierTrainnum}}) számlálási adatai elvesznek! Ez nem vonható vissza.</h6>
      <form [formGroup]="vonatinfoForm" (ngSubmit)="onSubmit()">
        <div *ngIf="wasError" class="alert alert-danger" role="alert">
            {{error}}
        </div>
        <div class="form-group">
            <div *ngIf="submitted && vonatinfoForm.controls.szam.errors">
              <div *ngIf="vonatinfoForm.controls.szam.errors.required">
                <div class="alert alert-danger" role="alert">
                  A vonatszám megadása kötelező!
                </div>
              </div>
            </div>
            <input class="form-control" type="tel" formControlName="szam" style="text-align: center; font-size: 24px; font-weight: bold;">
        </div> <!-- form-group// --> 
        <div class="form-group">
            <button [disabled]="inProgress" type="submit" class="btn btn-success btn-block"> 
              <span *ngIf="inProgress" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Mehet!  </button>
        </div> <!-- form-group// -->    
      </form>
    </article>
  </div>
  