import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class VonatokService {

  vonatok = [
    {
      vonatszamok: [1842, 204, 1852, 1848, 1856, 1844, 1854], // Balatonszentgyörgyre TÓPART
      megallasiRendId: 0,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [1860, 1862, 1864, 1874, 1866, 1876, 1868, 1878], // Balatonszentgyörgyre BALATON
      megallasiRendId: 7,
      reverse: false,
      etkezo: true,
      elsoosztaly: true,
      ic: true
    },
    {
      vonatszamok: [1849, 1847, 1857, 1843, 1845, 205, 1853, 201], // Balatonszentgyörgyről TÓPART
      megallasiRendId: 0,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [1867, 1877, 1865, 1875, 1863, 1873, 1861, 1871], // Balatonszentgyörgyről BALATON
      megallasiRendId: 7,
      reverse: true,
      etkezo: true,
      elsoosztaly: true,
      ic: true
    },
    {
      vonatszamok: [18500, 18502, 18504, 18406], // Balatonszentgyörgyre
      megallasiRendId: 1,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [18404], // Bs-ig Lepsény határ
      megallasiRendId: 2,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [18608], // Fo-ig
      megallasiRendId: 11,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [18505, 18403, 18503, 18401], // Balatonszentgyörgyről
      megallasiRendId: 1,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [18511], // Bs-től személy
      megallasiRendId: 5,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [15502],
      megallasiRendId: 3,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [15503],
      megallasiRendId: 3,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [6614, 6624],
      megallasiRendId: 4,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [6625, 6623],
      megallasiRendId: 4,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [4502, 4592],
      megallasiRendId: 6,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [4593],
      megallasiRendId: 6,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [4501],
      megallasiRendId: 8,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [19700, 19702, 19704, 19794, 19796, 19708],
      megallasiRendId: 9,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [19706], // KÉK HULLÁM Sm
      megallasiRendId: 16,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [19797], // KÉK HULLÁM Sm
      megallasiRendId: 16,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [19705, 19707, 19795, 19703, 19701, 19793],
      megallasiRendId: 9,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [19712, 19722, 19714, 19718, 19724, 19716, 19726],
      megallasiRendId: 10,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [19715, 19725, 19713, 19723, 19711, 19721, 19771],
      megallasiRendId: 10,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [15607],
      megallasiRendId: 12,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [15606],
      megallasiRendId: 12,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [16707],
      megallasiRendId: 13,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [16706],
      megallasiRendId: 13,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [1787],
      megallasiRendId: 14,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [1786],
      megallasiRendId: 14,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: true
    },
    {
      vonatszamok: [16907],
      megallasiRendId: 15,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [16906],
      megallasiRendId: 15,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [19607, 19699],
      megallasiRendId: 17,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [19606, 19608],
      megallasiRendId: 17,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [19622],
      megallasiRendId: 18,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [19742, 19734, 19744, 19736, 19746, 19738, 19798],
      megallasiRendId: 19,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [19747, 19735, 19745, 19733, 19743, 19731, 19781, 19741],
      megallasiRendId: 19,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [1972],
      megallasiRendId: 20,
      reverse: false,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    },
    {
      vonatszamok: [1973],
      megallasiRendId: 20,
      reverse: true,
      etkezo: false,
      elsoosztaly: false,
      ic: false
    }
  ]

  megallasiRendek = [
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Siófok',
      'Zamárdi',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyód',
      'Balatonfenyves',
      'Balatonmáriafürdő',
      'Balatonszentgyörgy',
      'Vörs',
      'Zalakomár',
      'Zalaszentjakab',
      'Nagykanizsa'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Érd alsó',
      'Székesfehérvár',
      'Székesfehévár-Repülőtér',
      'Szabadbattyán',
      'Kiscséripuszta',
      'Polgárdi-Tekerespuszta',
      'Lepsény',
      'Balatonaliga',
      'Balatonvilágos',
      'Szabadisóstó',
      'Szabadifürdő',
      'Siófok',
      'Balatonszéplak felső',
      'Balatonszéplak alsó',
      'Zamárdi felső',
      'Zamárdi',
      'Szántód',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle felső',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyódliget',
      'Fonyód',
      'Bélatelep',
      'Alsóbélatelep',
      'Balatonfenyves',
      'Balatonfenyves alsó',
      'Máriahullámtelep',
      'Máriaszőlőtelep',
      'Balatonmáriafürdő',
      'Balatonberény',
      'Balatonszentgyörgy'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Érd alsó',
      'Székesfehérvár',
      'Lepsény',
      'Balatonaliga',
      'Balatonvilágos',
      'Szabadisóstó',
      'Szabadifürdő',
      'Siófok',
      'Balatonszéplak felső',
      'Balatonszéplak alsó',
      'Zamárdi felső',
      'Zamárdi',
      'Szántód',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle felső',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyódliget',
      'Fonyód',
      'Bélatelep',
      'Alsóbélatelep',
      'Balatonfenyves',
      'Balatonfenyves alsó',
      'Máriahullámtelep',
      'Máriaszőlőtelep',
      'Balatonmáriafürdő',
      'Balatonberény',
      'Balatonszentgyörgy'
    ],
    [
      'Budapest-Keleti',
      'Gödöllő',
      'Füzesabony',
      'Poroszló',
      'Tiszafüred'
    ],
    [
      'Debrecen',
      'Tócóvölgy',
      'Nagyhát',
      'Balmazújváros',
      'Kónya',
      'Hortobágy',
      'Hortobágyi Halastó',
      'Ohat-Pusztakócs',
      'Egyek',
      'Tiszafüred',
      'Poroszló',
      'Kétútköz',
      'Egerfarmos',
      'Mezőtárkány',
      'Füzesabony'
    ],
    [
      'Balatonszentgyörgy',
      'Balatonberény',
      'Balatonmáriafürdő',
      'Máriaszőlőtelep',
      'Máriahullámtelep',
      'Balatonfenyves alsó',
      'Balatonfenyves',
      'Alsóbélatelep',
      'Bélatelep',
      'Fonyód',
      'Fonyódliget',
      'Balatonboglár',
      'Balatonlelle',
      'Balatonlelle felső',
      'Balatonszemes',
      'Balatonszárszó',
      'Balatonföldvár',
      'Szántód',
      'Zamárdi',
      'Zamárdi felső',
      'Balatonszéplak alsó',
      'Balatonszéplak felső',
      'Siófok',
      'Szabadifürdő',
      'Szabadisóstó',
      'Balatonvilágos',
      'Balatonaliga',
      'Lepsény',
      'Polgárdi-Tekerespuszta',
      'Kiscséripuszta',
      'Szabadbattyán',
      'Székesfehévár-Repülőtér',
      'Székesfehérvár',
      'Dinnyés',
      'Agárd',
      'Gárdony',
      'Velencefürdő',
      'Velence',
      'Kápolnásnyék',
      'Pettend',
      'Baracska',
      'Martonvásár',
      'Tárnok',
      'Érd alsó',
      'Tétényliget',
      'Kastélypark',
      'Budafok',
      'Albertfalva',
      'Budapest-Kelenföld',
      'Budapest-Déli'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Érd alsó',
      'Velence',
      'Velencefürdő',
      'Gárdony',
      'Agárd',
      'Székesfehérvár'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Siófok',
      'Zamárdi',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyód',
      'Balatonfenyves',
      'Balatonmáriafürdő',
      'Balatonberény',
      'Balatonszentgyörgy',
      'Keszthely'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Velence',
      'Velencefürdő',
      'Gárdony',
      'Agárd',
      'Székesfehérvár'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Balatonalmádi',
      'Balatonfüred',
      'Aszófő',
      'Örvényes',
      'Balatonudvari',
      'Fövenyes',
      'Balatonakali-Dörgicse',
      'Zánka-Erzsébettábor',
      'Zánka-Köveskál',
      'Balatonszepezd',
      'Révfülöp',
      'Balatonrendes',
      'Ábrahámhegy',
      'Badacsonytomaj',
      'Badacsony',
      'Badacsonylábdihegy',
      'Badacsonytördemic-Szigliget',
      'Nemesgulács-Kisapáti',
      'Tapolca'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Balatonakarattya',
      'Csittényhegy',
      'Balatonkenese',
      'Balatonfűzfő',
      'Balatonalmádi',
      'Káptalanfüred',
      'Alsóörs',
      'Csopak',
      'Balatonarács',
      'Balatonfüred'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Érd alsó',
      'Székesfehérvár',
      'Székesfehévár-Repülőtér',
      'Szabadbattyán',
      'Kiscséripuszta',
      'Polgárdi-Tekerespuszta',
      'Lepsény',
      'Balatonaliga',
      'Balatonvilágos',
      'Szabadisóstó',
      'Szabadifürdő',
      'Siófok',
      'Balatonszéplak felső',
      'Balatonszéplak alsó',
      'Zamárdi felső',
      'Zamárdi',
      'Szántód',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle felső',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyódliget',
      'Fonyód'
    ],
    [
      'Miskolc-Tiszai',
      'Nyékládháza',
      'Mezőkövesd',
      'Füzesabony',
      'Kál-Kápolna',
      'Vámosgyörk',
      'Hatvan',
      'Aszód',
      'Gödöllő',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Balatonaliga',
      'Balatonvilágos',
      'Szabadisóstó',
      'Siófok',
      'Balatonszéplak felső',
      'Zamárdi',
      'Szántód',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle felső',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyódliget',
      'Fonyód'
    ],
    [
      'Nyíregyháza',
      'Újfehértó',
      'Debrecen-Csapókert',
      'Debrecen',
      'Hajdúszoboszló',
      'Püspökladány',
      'Karcag',
      'Kisújszállás',
      'Törökszentmiklós',
      'Szolnok',
      'Abony',
      'Cegléd',
      'Ferihegy',
      'Kőbánya-Kispest',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Balatonaliga',
      'Szabadisóstó',
      'Szabadifürdő',
      'Siófok',
      'Balatonszéplak felső',
      'Balatonszéplak alsó',
      'Zamárdi',
      'Szántód',
      'Balatonföldvár',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyódliget',
      'Fonyód'
    ],
    [
      'Szeged',
      'Kiskunfélegyháza',
      'Kecskemét',
      'Nagykőrös',
      'Cegléd',
      'Ferihegy',
      'Kőbánya-Kispest',
      'Ferencváros',
      'Székesfehérvár',
      'Balatonaliga',
      'Szabadisóstó',
      'Siófok',
      'Zamárdi',
      'Balatonföldvár',
      'Balatonszárszó',
      'Balatonszemes',
      'Balatonlelle',
      'Balatonboglár',
      'Fonyód'
    ],
    [
      'Nyíregyháza',
      'Újfehértó',
      'Téglás',
      'Hajdúhadház',
      'Bocskaikert',
      'Debrecen-Csapókert',
      'Debrecen',
      'Ebes',
      'Hajdúszoboszló',
      'Kaba',
      'Püspökladány',
      'Karcag',
      'Kisújszállás',
      'Fegyvernek-Örményes',
      'Törökszentmiklós',
      'Szajol',
      'Szolnok',
      'Abony',
      'Cegléd',
      'Ferihegy',
      'Kőbánya-Kispest',
      'Budapest-Kelenföld',
      'Velence',
      'Székesfehérvár',
      'Balatonakarattya',
      'Balatonkenese',
      'Balatonfűzfő',
      'Balatonalmádi',
      'Alsóörs',
      'Csopak',
      'Balatonfüred',
      'Balatonakali-Dörgicse',
      'Zánka-Erzsébettábor'
    ],
    [
      'Budapest-Déli',
      'Budapest-Kelenföld',
      'Székesfehérvár',
      'Balatonalmádi',
      'Balatonfüred',
      'Aszófő',
      'Örvényes',
      'Balatonudvari',
      'Fövenyes',
      'Balatonakali-Dörgicse',
      'Zánka-Erzsébettábor',
      'Zánka-Köveskál',
      'Balatonszepezd',
      'Révfülöp',
      'Balatonrendes',
      'Ábrahámhegy',
      'Badacsonytomaj',
      'Badacsony',
      'Badacsonylábdihegy',
      'Badacsonytördemic-Szigliget',
      'Nemesgulács-Kisapáti',
      'Tapolca',
      'Boba',
      'Celldömölk',
      'Sárvár',
      'Szombathely'
    ],
    [
      'Szombathely',
      'Sárvár',
      'Celldömölk',
      'Ukk',
      'Sümeg',
      'Tapolca',
      'Raposka',
      'Balatonederics',
      'Balatongyörök',
      'Vonyarcvashegy',
      'Gyenesdiás',
      'Alsógyenes',
      'Keszthely'
    ],
    [
      'Keszthely',
      'Alsógyenes',
      'Gyenesdiás',
      'Vonyarcvashegy',
      'Balatongyörök',
      'Tapolca'
    ],
    [
      'Balatonfüred',
      'Aszófő',
      'Örvényes',
      'Balatonudvari',
      'Fövenyes',
      'Balatonakali-Dörgicse',
      'Zánka-Köveskál',
      'Balatonszepezd',
      'Szepezdfürdő',
      'Révfülöp',
      'Balatonrendes',
      'Ábrahámhegy',
      'Badacsonyörs',
      'Badacsonytomaj',
      'Badacsony',
      'Badacsonylábdihegy',
      'Badacsonytördemic-Szigliget',
      'Nemesgulács-Kisapáti',
      'Tapolca'
    ],
    [
      'Szolnok',
      'Cegléd',
      'Ferihegy',
      'Kőbánya-Kispest',
      'Budapest-Kelenföld',
      'Velence',
      'Székesfehérvár',
      'Balatonakarattya',
      'Balatonkenese',
      'Balatonfűzfő',
      'Balatonalmádi',
      'Alsóörs',
      'Csopak',
      'Balatonfüred',
      'Balatonakali-Dörgicse',
      'Zánka-Erzsébettábor',
      'Zánka-Köveskál',
      'Révfülöp',
      'Badacsonyörs',
      'Badacsonytomaj',
      'Badacsony',
      'Tapolca'
    ]
  ]


  constructor() { }

  getMegallasiRend(id, reverse) {
    let copy = this.megallasiRendek[id].slice();

    if(reverse) {
      return copy.reverse();
    }
    return copy;
  }
}
