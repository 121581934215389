<ng-template #content let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Hiba megoldása</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Ezzel a hibát megoldottnak jelölöd. Kérlek, csak akkor jelöld megoldottnak, ha meggyőződtél róla, hogy a leírt hiba már nem jelentkezik!</p>
        <p>Válaszd ki a megoldottnak jelölés okát!</p>
        <select class="form-control" id="solveReason" [(ngModel)]="solveReason">
            <option value="0">A hiba nem jelentkezett</option>
            <option value="1">A hiba ismert módon elhárítva</option>
            <option value="2">A hiba ismeretlen módon elhárítva</option>
        </select>
        <hr>
        <button (click)="solve()" class="btn btn-block btn-success"><i class="fa fa-check"></i> Megjelölés megoldottként</button>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.close('Save click')"><i class="fa fa-times"></i> Mégsem</button>
    </div>
</ng-template>

<ng-template #content2 let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Hiba megerősítése</h4>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p>Ezzel megerősíted a hibát. Kérlek, csak akkor erősítsd meg, ha meggyőződtél róla, hogy a leírt hiba még mindig jelentkezik! Ezzel pontosabbá tudjuk tenni a hibák követését.</p>
        <hr>
        <button (click)="still()" class="btn btn-block btn-warning"><i class="fa fa-times"></i> Hiba megerősítése</button>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-danger" (click)="modal.close('Save click')"><i class="fa fa-times"></i> Mégsem</button>
    </div>
</ng-template>

<div class="card vonatinfo" [ngStyle]="{'background-color': 'rgb(35, 59, 116)', 'border': 'none', 'color': 'white'}">
    <article *ngIf="ticket" class="card-body">
        <h4 class="text-center">Hibajegy részletei (#{{id}})</h4>
        <div *ngIf="!inProgress && success" class="alert alert-success" role="alert">
            {{successMsg}}
        </div>
        <div *ngIf="!inProgress && wasError" class="alert alert-success" role="alert">
            {{error}}
        </div>
        <ul class="list-group" style="color: black;">
            <li class="list-group-item">Érintett {{ticket.place == '1' ? 'állomás' : 'jármű'}}: {{ticket.azonosito}}</li>
            <li class="list-group-item">Hiba típusa: {{data.jarmuHibak[ticket.type]}}</li>
            <li class="list-group-item">Rögzítő: {{ticket.rogzitoNeve}}</li>
            <li class="list-group-item">Rögzítés ideje: {{ticket.rogzitesIdeje}}</li>
            <li class="list-group-item">Rögzítés helye: {{ticket.rogzitesHelye}}</li>
            <li class="list-group-item">Állapot: {{data.hibajegyStatuszok[ticket.state]}}</li>
            <li class="list-group-item">Hiba leírása: {{ticket.reszletek}}</li>
            <li *ngIf="ticket.state == '1'" class="list-group-item bg-success">Megoldó: {{ticket.megoldoNeve}}</li>
            <li *ngIf="ticket.state == '1'" class="list-group-item bg-success">Megoldás ideje: {{ticket.megoldasIdeje}}</li>
            <li *ngIf="ticket.state == '1'" class="list-group-item bg-success">Megoldás oka: {{data.megoldasOkok[ticket.megoldasOka]}}</li>
        </ul>
        <hr>
        <p>Hibajegy története</p>
        <table *ngIf="ticketHistory">
            <thead>
                <th>Felhasználó</th>
                <th>Művelet</th>
                <th>Dátum</th>
            </thead>
            <tbody>
                <tr *ngFor="let history of ticketHistory">
                    <td>{{history.teljesnev}}</td>
                    <td>{{data.muveletek[history.action]}}</td>
                    <td>{{history.datum}}</td>
                </tr>
            </tbody>
        </table>
        <hr>
        <div *ngIf="ticket.state == '0'">
            <p>Ha ezen a kocsin utazol, kérlek nyilatkozz, fennáll-e még a fent leírt hiba!</p>
            <div class="row">
                <div class="col-6">
                    <button (click)="open(content)" class="btn btn-block btn-success btn-lg"><i class="fa fa-check"></i> A hiba elhárult</button>
                </div>
                <div class="col-6">
                    <button (click)="open(content2)" class="btn btn-block btn-danger btn-lg"><i class="fa fa-times"></i> A hiba nem hárult el</button>
                </div>
            </div>
        </div>
        <div *ngIf="ticket.state == '1'">
            <div class="alert alert-success" role="alert">
                Ez a hiba már megoldásra került.
            </div>
        </div>
    </article>
</div>