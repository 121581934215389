import { Component, OnInit } from '@angular/core';
import { BackendService } from '../backend.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-szovegkonyv',
  templateUrl: './szovegkonyv.component.html',
  styleUrls: ['./szovegkonyv.component.css']
})
export class SzovegkonyvComponent implements OnInit {

  fordatipus;
  fordaszam;
  szolgalatStatusz;
  fordaAdatok;
  hangositoFordak;
  allStations;

  constructor(private data: BackendService, private router: Router) { }

  ngOnInit() {
    this.fordatipus = localStorage.getItem('fordatipus');
    this.fordaszam = localStorage.getItem('fordaszam');
    this.szolgalatStatusz = localStorage.getItem('szolgalatStatusz');

    if(this.szolgalatStatusz != "0") {
      if(this.szolgalatStatusz == "2") {
        var sign = this.fordatipus + "" + this.fordaszam;
        this.data.getFordaData(sign, localStorage.getItem('telephely')).subscribe(data => {
          this.fordaAdatok = data;
          this.hangositoFordak = this.fordaAdatok.filter(forda => {
            if((forda['Note'].includes('hangosít') || forda['Note'].includes('Hangosít')) && forda['Note'] != "nem hangosít") {
              return forda;
            }
          })
        }, error => {
          console.log(error);
        })
      }
    }

    this.data.getAllomasLista().subscribe(data => {
      this.allStations = data;
    }, error => {
      console.log(error);
    })
  }
  
  toSzovegNezet(vonatszam) {
    this.data.checkVonatszam(vonatszam).subscribe(data => {
      if(data.length > 1) {
        this.router.navigate(['/szovegkonyv', data[1]['VonatID']]);
      }
      else {
        this.router.navigate(['/szovegkonyv', data[0]['VonatID']]);
      }
    })
  }

}
