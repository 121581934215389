import { Component, OnInit } from '@angular/core';
import { slideInAnimation } from './route-animations';
import { ConnectionService } from 'ng-connection-service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  //animations: [ slideInAnimation ] animációk később
})
export class AppComponent implements OnInit {
  isConnected = true;
  title = 'mavapp';

  constructor(private connectionService: ConnectionService, public updates: SwUpdate) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
    })

    updates.available.subscribe(event => {
      updates.activateUpdate().then(() => this.updateApp());
    });
    this.updates.checkForUpdate();
  }

  ngOnInit() {
    setInterval(() =>  {
      this.updates.checkForUpdate();
    }, 60000);
  }

  updateApp() {
    document.location.reload();
  }
}
