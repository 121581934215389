import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BackendService } from 'src/app/backend.service';
import { IBicycleCount } from './bicycleCount';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { VonatokService } from '../vonatok.service';

@Component({
  selector: 'app-kerekpar-offline',
  templateUrl: './kerekpar-offline.component.html',
  styleUrls: ['./kerekpar-offline.component.css']
})
export class KerekparOfflineComponent implements OnInit {

  trainNumber: number;
  vonatID: string;
  allBicycles = {
    boarding: 0,
    leaving: 0,
    all: 0
  }
  copyText = '';
  bicycleCounts: IBicycleCount[] = [];
  currentIndex;
  closeResult;
  osszesites = 0;
  /* mentés visszajelzése */
  isLoading = false;
  response;
  wasError = false;
  success = false;

  constructor(private route: ActivatedRoute, private data: BackendService, private router: Router, private modalService: NgbModal, private vonatService: VonatokService) { }

  ngOnInit() {
    if(localStorage.getItem('kerekparVonatszam') && localStorage.getItem('kerekparAdatok') && localStorage.getItem('kerekparSzamok')) {
      this.trainNumber = Number(localStorage.getItem('kerekparVonatszam'));
      this.bicycleCounts = JSON.parse(localStorage.getItem('kerekparAdatok'));
      this.allBicycles = JSON.parse(localStorage.getItem('kerekparSzamok'));
    }
    else {
      this.trainNumber = this.route.snapshot.params['szam'];
      if(this.trainNumber) {
        let chosenTrain = this.vonatService.vonatok.find((vonat) => {
          return vonat.vonatszamok.includes(Number(this.trainNumber));
        })
    
        if(chosenTrain != undefined) {
          let megallasok = this.vonatService.getMegallasiRend(chosenTrain.megallasiRendId, chosenTrain.reverse);
          megallasok.forEach(allomas => {
            this.bicycleCounts.push({
              station: allomas,
              stationCode: 0,
              boarding: 0,
              leaving: 0
            })
          })
        }
      }
    }
    if(localStorage.getItem('bicycleIndex')) {
      this.currentIndex = localStorage.getItem('bicycleIndex');
    }
    else {
      this.currentIndex = 0;
    }
    
  }

  changeStation(how) {
    if(how > 0) {
      this.currentIndex++;
    }
    else {
      this.currentIndex--;
    }
    localStorage.setItem('bicycleIndex', this.currentIndex);
  }

  changeCount(how, which, index = this.currentIndex) {
    if(how > 0) {
      this.bicycleCounts[index][which]++;
    }
    else {
      this.bicycleCounts[index][which]--;
    }

    this.checkInput(which, index, how);
    //this.calculateAll();
  }

  checkInput(which, index = this.currentIndex, how) {
    // ha felszálló, akkor a beírt adatokat hozzáadjuk az összes biciklihez, de ha kisebb, mint 0, akkor visszaállítjuk 0-ra
    if(which == 'boarding') {
      if(this.bicycleCounts[index].boarding < 0) {
        this.bicycleCounts[index].boarding = 0;
      }
    }
    else {
      if(this.bicycleCounts[index].leaving < 0) {
        this.bicycleCounts[index].leaving = 0;
      }
      else if(this.bicycleCounts[index].leaving > this.allBicycles.boarding) {
        this.bicycleCounts[index].leaving = this.allBicycles.boarding;
      }
      else if(this.allBicycles.all == 0 && how > 0) {
        this.bicycleCounts[index].leaving = this.bicycleCounts[index].leaving - 1;
      }
    }

    this.calculateAll();
  }

  calculateAll() {
    this.allBicycles = {boarding: 0, leaving: 0, all: 0}
    this.bicycleCounts.forEach(allomas => {
      this.allBicycles.boarding += allomas.boarding;
      this.allBicycles.leaving += allomas.leaving;
    })
    this.allBicycles.all = this.allBicycles.boarding - this.allBicycles.leaving;
    localStorage.setItem('kerekparVonatszam', this.trainNumber.toString());
    localStorage.setItem('kerekparAdatok', JSON.stringify(this.bicycleCounts));
    localStorage.setItem('kerekparSzamok', JSON.stringify(this.allBicycles));
  }

  getTextFromCount() {
    this.copyText = '#kerekparok\n' + this.trainNumber + ' sz. vonat\n';
    this.bicycleCounts.forEach(count => {
      //console.log(this.bicycleCounts[i].station);
      if(count.leaving > 0 || count.boarding > 0) {
        this.copyText += `${count.station}: le: ${count.leaving}, fel: ${count.boarding}\n`;
      }
    })
    this.copyText += "A nem felsorolt állomásokon nem volt le- vagy felszálló kerékpáros.";
  }

  copy(inputElement) {
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
    inputElement.blur();
  }

  saveData() {
    this.isLoading = true;
    this.wasError = false;
    this.response = "";
    this.success = false;
    this.data.saveBicycleCount(this.bicycleCounts, this.trainNumber, localStorage.getItem('currentUser')).subscribe(data => {
      this.isLoading = false;
      this.response = data;
      this.success = true;
      localStorage.removeItem('kerekparVonatszam');
      localStorage.removeItem('kerekparAdatok');
      localStorage.removeItem('kerekparSzamok');
      localStorage.removeItem('bicycleIndex');
      //this.router.navigate(['/kerekparok']);
    },
    error => {
      this.isLoading = false;
      this.wasError = true;
      this.response = error;
    })
  }

  resetSzamlalo() {
    localStorage.removeItem('kerekparVonatszam');
    localStorage.removeItem('kerekparAdatok');
    localStorage.removeItem('kerekparSzamok');
    localStorage.removeItem('bicycleIndex');
    this.router.navigate(['/kerekparokoff']);
  }

  showOsszesites() {
    if(this.osszesites) {
      this.osszesites = 0;
    }
    else {
      this.osszesites = 1;
    }
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
