<ng-template #content let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{myStation.allomasNev}} adatai</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <a [routerLink]="['/szemelyzet/allomas', myStation.allomasNev]" (click)="ugrasSzemelyzetre()" class="btn btn-block btn-success"><i class="fa fa-users"></i> Állomás diákszemélyzete</a>
    <hr>
    <h4>Az állomás szolgáltatásai</h4>
    <table>
      <tr *ngFor="let szolg of myStation.Szolgaltatasok; let index = index">
        <div *ngIf="(index + 1) % 2 != 0">
            <td style="font-family: 'mnr'; font-size: 24px">{{this.displayViszonylatszam(szolg.Jel.FontKod)}}</td>
            <td>{{szolg.SzolgNev}} <span style="color: darkgrey; font-style: italic;" *ngIf="szolg.Viszonylat.length > 0">({{allStations[szolg.Viszonylat.InduloAllomasKod]}} - {{allStations[szolg.Viszonylat.CelAllomasKod]}})</span></td>    
        </div>
      </tr>
    </table>
    <div>
      <i class="fa fa-arrows-v"></i> Peron típusa: {{myStation.allomasAdatok?.Platform ? myStation.allomasAdatok.Platform : 'nincs adat'}}
    </div>
    <br>
    <div *ngIf="myStation.allomasAdatok?.Note || myStation.allomasAdatok?.Lunch || myStation.allomasAdatok?.More">
        <h4>Nyári szezon</h4>
        <div *ngIf="myStation.allomasAdatok.Note">
          <i class="fa fa-sticky-note-o"></i> Megjegyzés
          <p [innerHTML]="myStation.allomasAdatok.Note"></p>
        </div>
        <div *ngIf="myStation.allomasAdatok.Lunch">
          <i class="fa fa-cutlery"></i> Ebéd
          <p [innerHTML]="myStation.allomasAdatok.Lunch"></p>
        </div>
        <div *ngIf="myStation.allomasAdatok.More">
          <i class="fa fa-ellipsis-h"></i> Továbbiak
          <p [innerHTML]="myStation.allomasAdatok.More"></p>
        </div>
        <!--<span style="text-decoration: underline">Peron típusa<br></span>
        <span class="nagy">{{myStation.allomasAdatok.Platform}}<br></span>
        <span style="text-decoration: underline">Megjegyzés<br></span>
        <span *ngIf="myStation.allomasAdatok.Note" class="nagy">{{myStation.allomasAdatok.Note}}<br></span>
        <span *ngIf="!myStation.allomasAdatok.Note" class="nagy">Nincs adat<br></span>
        <hr>
        <span style="text-decoration: underline">Ebéd<br></span>
        <span *ngIf="myStation.allomasAdatok.Lunch" [innerHTML]="myStation.allomasAdatok.Lunch" style="font-size: 20px"><br></span>
        <span *ngIf="!myStation.allomasAdatok.Lunch" class="nagy">Nincs adat<br></span>
        <hr>
        <span style="text-decoration: underline">Továbbiak<br></span>
        <span *ngIf="myStation.allomasAdatok.More" [innerHTML]="myStation.allomasAdatok.More" style="font-size: 20px"><br></span>
        <span *ngIf="!myStation.allomasAdatok.More" class="nagy">Nincs adat<br></span>-->
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">OK</button>
  </div>
</ng-template>

<article *ngIf="wasError" class="card-body">
  <div class="alert alert-danger" role="alert">
    {{error}}
  </div>
</article>

<article *ngIf="!myStation?.allomasNev && !wasError" class="card-body">
  <p style="color: white;"><span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Állomásinformációk betöltése...</p>
</article>

<article *ngIf="myStation?.allomasNev" class="card-body">
  <div (click)="open(content)" [ngStyle]="{'background-color': 'white', 'color': 'black', 'padding': '5px', 'margin-bottom': '8px', 'overflow': 'hidden', 'width': '100%'}">
    <h4 class="text-center" style="font-weight: bold">{{myStation.allomasNev}}</h4>
    <p *ngIf="myStation.allomasAdatok?.Platform">Nyári szezonhoz kapcsolódó adatok elérhetőek!</p>
  </div>
  <div *ngIf="myStation.Menetrend.length == 0">
    <div class="alert alert-primary" role="alert">
      Ezen a szolgálati helyen nincsenek megjeleníthető induló vagy érkező vonatok. {{reqType == 'fromNow' ? 'Próbáld meg átállítani a keresést a mai nap összes vonatára!' : ''}}
    </div>
  </div>
  <table style="width: 100%">
    <tr (click)="jumpToTrain(vonat.Vonat.ID)" *ngFor="let vonat of myStation.Menetrend; let index = index" [ngStyle]="{'background-color': index % 2 ? '#ffffff' : '#c2c2c2', color: 'black'}">
      <td>
        {{vonat.Ido.ErkMenetrendi}} <br>
        <span *ngIf="vonat.Ido.ErkTeny" [ngStyle]="{'color': checkColor(vonat.Ido.ErkMDatum, vonat.Ido.ErkTenyDatum, vonat.Ido.ErkMenetrendi, vonat.Ido.ErkTeny)}">{{vonat.Ido.ErkTeny}}</span>
        <span *ngIf="vonat.Ido.ErkVarhato" [ngStyle]="{'color': checkColor(vonat.Ido.ErkMDatum, vonat.Ido.ErkVarhatoDatum, vonat.Ido.ErkMenetrendi, vonat.Ido.ErkVarhato)}"><i>{{vonat.Ido.ErkVarhato}}</i></span>
      </td>
      <td>
        {{vonat.Ido.IndMenetrendi}} <br>  
        <span *ngIf="vonat.Ido.IndTeny" [ngStyle]="{'color': checkColor(vonat.Ido.IndMDatum, vonat.Ido.IndTenyDatum, vonat.Ido.IndMenetrendi, vonat.Ido.IndTeny)}">{{vonat.Ido.IndTeny}}</span>
        <span *ngIf="vonat.Ido.IndVarhato" [ngStyle]="{'color': checkColor(vonat.Ido.IndMDatum, vonat.Ido.IndVarhatoDatum, vonat.Ido.IndMenetrendi, vonat.Ido.IndVarhato)}"><i>{{vonat.Ido.IndVarhato}}</i></span>
      </td>
      <td>
          <span [ngStyle]="{'color': vonat.Vonat.Tipus[0].Szin}">{{vonat.Vonat.Szam}} {{vonat.Vonat.Nev}} {{vonat.Vonat.Tipus[0].Vonatnem}}</span> <span *ngIf="vonat.Vonat?.ViszonylatJeloles?.Jel"> ({{vonat.Vonat.ViszonylatJeloles.Jel}})</span><br>
          {{allStations[vonat.Vonat.Tipus[0].Viszonylat.InduloAllomasKod]}} - {{allStations[vonat.Vonat.Tipus[0].Viszonylat.CelAllomasKod]}}
      </td>
    </tr>
  </table>
</article>