import { Component, OnInit } from '@angular/core';
import { BackendService } from 'src/app/backend.service';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ticket',
  templateUrl: './ticket.component.html',
  styleUrls: ['./ticket.component.css']
})
export class TicketComponent implements OnInit {
  ticket;
  id;
  ticketHistory;
  closeResult: string;
  solveReason = '0';
  isLoading = false;
  wasError = false;
  error = '';
  success = false;
  successMsg = '';

  constructor(public data: BackendService, private route: ActivatedRoute, private modalService: NgbModal) { }

  ngOnInit(): void {
    this.id = this.route.snapshot.params['id'];
    if(this.id) {
      this.data.getHibajegyFromID(this.id).subscribe(data => {
        this.ticket = data[0];
        this.data.getHibajegyHistory(this.id).subscribe(data => {
          this.ticketHistory = data;
        })
      })
    }
  }

  solve() {
    this.isLoading = true;
    this.wasError = false;
    this.error = '';
    this.success = false;
    this.successMsg = '';

    this.data.editHibajegy(this.id, 'solve', this.solveReason).subscribe(data => {
      this.modalService.dismissAll();
      this.isLoading = false;
      this.success = true;
      this.data.getHibajegyFromID(this.id).subscribe(data => {
        this.ticket = data[0];
        this.data.getHibajegyHistory(this.id).subscribe(data => {
          this.ticketHistory = data;
        })
        this.successMsg = 'A hibajegy sikeresen megoldottnak jelölve. Köszönjük!';
      })
    },
    error => {
      this.isLoading = false;
      this.modalService.dismissAll();
      this.wasError = true;
      this.error = error;
    })
  }

  still() {
    this.isLoading = true;
    this.wasError = false;
    this.error = '';
    this.success = false;
    this.successMsg = '';

    this.data.editHibajegy(this.id, 'still', this.solveReason).subscribe(data => {
      this.modalService.dismissAll();
      this.isLoading = false;
      this.success = true;
      this.data.getHibajegyHistory(this.id).subscribe(data => {
        this.ticketHistory = data;
      })
      this.successMsg = 'A hibajegyhez sikeresen rögzítésre került, hogy a hiba továbbra sem hárult el. Köszönjük!';
    },
    error => {
      this.isLoading = false;
      this.modalService.dismissAll();
      this.wasError = true;
      this.error = error;
    })
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
