import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BackendService } from 'src/app/backend.service';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.css']
})
export class UserComponent implements OnInit {
  user;
  wasError = false;
  error = '';
  errorMod = false;
  message = '';
  finished = false;

  constructor(private route: ActivatedRoute, private data: BackendService) { }

  ngOnInit(): void {
    if(this.route.snapshot.params['id'] != '') {
      this.data.getUser(this.route.snapshot.params['id']).subscribe(user => {
        this.user = user[0];
      },
      error => {
        this.wasError = true;
        this.error = error;
      })
    }
  }

  modify() {
    this.errorMod = false;
    this.message = '';
    this.finished = false;
    this.data.modifyUser(this.user.id, !this.user.aktiv).subscribe(data => {
      this.finished = true;
      this.message = data;
      this.user.aktiv = !this.user.aktiv;
    },
    error => {
      this.errorMod = true;
      this.message = error;
    })
  }

}
