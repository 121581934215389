<div *ngIf="!user">
    Betöltés...
</div>
<div *ngIf="user">
    <h2>{{user.username}} (#{{user.id}}) felhasználó adatai</h2>
    <ul class="list-group" style="color: black">
        <li class="list-group-item">Felhasználónév: {{user.username}}</li>
        <li class="list-group-item">Teljes név: {{user.teljesnev}}</li>
        <li class="list-group-item">E-mail: {{user.email}}</li>
        <li class="list-group-item">Telefonszám: {{user.telefon}}</li>
        <li class="list-group-item">Szint: {{user.level}}</li>
        <li class="list-group-item">Aktív? {{user.aktiv ? 'Igen' : 'Nem'}}</li>
    </ul>
    <hr>
    <div *ngIf="finished" [ngClass]="errorMod ? 'alert alert-danger' : 'alert alert-success'">
        {{message}}
    </div>
    <button *ngIf="user.aktiv" (click)="modify()" class="btn btn-block btn-danger"><i class="fa fa-times"></i> Felhasználó letiltása</button>
    <button *ngIf="!user.aktiv" (click)="modify()" class="btn btn-block btn-success"><i class="fa fa-check"></i> Felhasználó aktiválása</button>
</div>
