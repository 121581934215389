import { Component, OnInit } from '@angular/core';
import { BackendService } from '../backend.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BicycleData } from '../BicycleData';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { VonatokService } from './vonatok.service';

@Component({
  selector: 'app-kerekparok-offline',
  templateUrl: './kerekparok-offline.component.html',
  styleUrls: ['./kerekparok-offline.component.css']
})
export class KerekparokOfflineComponent implements OnInit {
  vonatinfoForm: FormGroup;
  earlierTrainnum;
  earlierTrainID;
  inProgress = false;
  success = false;
  wasError = false;
  submitted = false;
  isLoading = false;
  error = "";
  myTrain = <any>{};
  bicycleCount = new BicycleData(0, 0, [], []);
  closeResult: string;

  constructor(private formBuilder: FormBuilder, private data: BackendService, private modalService: NgbModal, private router: Router, private vonatService: VonatokService) { }

  ngOnInit() {
    this.vonatinfoForm = this.formBuilder.group({
      szam: ['', Validators.required]
    });

    if(localStorage.getItem('kerekparVonatszam')) {
      this.earlierTrainnum = localStorage.getItem('kerekparVonatszam');
    }

    /*var kerekparObject = JSON.parse(localStorage.getItem('kerekparAllapot'));
    if(kerekparObject && kerekparObject.vonatszam != "") {
      this.bicycleCount = kerekparObject;
      this.data.getVonatinfo(kerekparObject.vonatszam).subscribe(data => {
        this.myTrain = data;
      }, error => {
        this.wasError = true;
        this.error = error;
        this.submitted = false;
        this.inProgress = false;
      })
    }*/
    
    /*this.data.getAllomasLista().subscribe(data => {
      //this.allStations = data;
    }, error => {
      console.log(error);
    })*/
  }

  onSubmit() {
    this.submitted = true;
    this.inProgress = true;

    if (this.vonatinfoForm.invalid) {
      this.inProgress = false;
      console.log("Érvénytelen form!")
      return;
    }

    let chosenTrain = this.vonatService.vonatok.find((vonat) => {
      return vonat.vonatszamok.includes(Number(this.vonatinfoForm.controls.szam.value));
    })

    if(chosenTrain != undefined) {
      localStorage.removeItem('kerekparVonatszam');
      localStorage.removeItem('kerekparAdatok');
      localStorage.removeItem('kerekparSzamok');
      this.router.navigate(['/kerekparokoff', Number(this.vonatinfoForm.controls.szam.value)]);
    }
    else {
      this.wasError = true;
      this.error = "A megadott vonatszámhoz a kerékpárszámlálás jelenleg nem elérhető.";
      this.submitted = false;
      this.inProgress = false;
    }

    //console.log(this.vonatService.getMegallasiRend(chosenTrain.megallasiRendId, chosenTrain.reverse));

    /*this.data.checkVonatszam(this.vonatinfoForm.controls.szam.value).subscribe(data => {
      localStorage.removeItem('kerekparVonatszam');
      localStorage.removeItem('kerekparAdatok');
      localStorage.removeItem('kerekparSzamok');
      this.router.navigate(['/kerekparok', data[0]['VonatID']]);
    },
    error => {
      this.wasError = true;
      this.error = error;
      this.submitted = false;
      this.inProgress = false;
    })*/

    /*this.data.getVonatinfo(this.vonatinfoForm.controls.szam.value).subscribe(data => {
      this.myTrain = data;
      localStorage.removeItem('kerekparVonatszam');
      localStorage.removeItem('kerekparAdatok');
      localStorage.removeItem('kerekparSzamok');
      this.router.navigate(['/kerekparok', this.myTrain.Vonat.Szam]);
      /*this.bicycleCount.vonatszam = this.myTrain.Vonat.Szam;
      for(let i = 0; i < this.myTrain.Menetrend.length; i++) {
        this.bicycleCount.boarding[i] = 0;
        this.bicycleCount.leaving[i] = 0;
      }*/
      
    /*}, error => {
      this.wasError = true;
      this.error = error;
      this.submitted = false;
      this.inProgress = false;
    })*/
  }

  truncate(allomas) {
    return  allomas.replace(/(.{15})..+/, " $1..");
  }

  /*
    elvárt működés: 
    - felszálló utasok esetében növeli a kerékpárok számát a vonaton
    - leszálló utasok esetében a kerékpárok számáig engedi növelni a leszállók számát, ezzel egyidőben csökkentve a kerékpárok számát
  */

  modifyLeaving(index, irany) {
    if(irany == 1) {
      if(this.bicycleCount.onTrain == 0) {
        return; 
      }
      else {
        this.bicycleCount.onTrain--;
        this.bicycleCount.leaving[index]++;
      }
    }
    else {
      if(this.bicycleCount.leaving[index] == 0) {
        return;
      }
      else if(this.bicycleCount.onTrain == 0 && this.bicycleCount.leaving[index] == 0) {
        return;
      }
      else {
        this.bicycleCount.leaving[index]--;
        this.bicycleCount.onTrain++;
      }
    }
    localStorage.setItem('kerekparAllapot', JSON.stringify(this.bicycleCount))
  }

  modifyBoarding(index, irany) {
    if(irany == 1) {
      this.bicycleCount.boarding[index]++;
      this.bicycleCount.onTrain++;
    }
    else {
      if(this.bicycleCount.boarding[index] == 0) {
        return;
      }
      else {
        this.bicycleCount.boarding[index]--;
        this.bicycleCount.onTrain--;
      }
    }
    localStorage.setItem('kerekparAllapot', JSON.stringify(this.bicycleCount))
  }

  updateBicycles(index, which) {
    if(which == 'boarding') {
      if(this.bicycleCount.boarding[index] < 0) {
        this.bicycleCount.boarding[index] = 0;
      }
      this.bicycleCount.onTrain += Number(this.bicycleCount.boarding[index]);
    }
    else {
      if(this.bicycleCount.leaving[index] > this.bicycleCount.onTrain || this.bicycleCount.leaving[index] < 0) {
        this.bicycleCount.leaving[index] = 0;
      }
      this.bicycleCount.onTrain -= Number(this.bicycleCount.leaving[index]);
    }
    localStorage.setItem('kerekparAllapot', JSON.stringify(this.bicycleCount))
    
  }

  resetSzamlalo() {
    localStorage.removeItem('kerekparAllapot');
    this.myTrain = <any>{};
    this.inProgress = false;
    this.success = false;
    this.wasError = false;
    this.submitted = false;
    this.bicycleCount = new BicycleData(0, 0, [], []);
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
