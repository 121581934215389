import { Component, OnInit } from '@angular/core';
import { SzovegkonyvService } from '../szovegkonyv.service';
import { ActivatedRoute } from '@angular/router';
import { IVonat } from '../IVonat';
import { ISzovegkonyv } from 'src/app/szovegkonyv/szoveg-nezet/iszovegkonyv';
import { IKulonleges } from '../../szovegkonyv/szoveg-nezet/iszovegkonyv';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { SzovegkonyvNyarService } from '../szovegkonyv_nyar.service';

@Component({
  selector: 'app-offline-szoveg-nezet',
  templateUrl: './offline-szoveg-nezet.component.html',
  styleUrls: ['./offline-szoveg-nezet.component.css']
})
export class OfflineSzovegNezetComponent implements OnInit {

  chosenTrain;
  wasError = false;
  generaltSzovegek: ISzovegkonyv[] = [];
  currentIndex;
  closeResult;
  kivalasztottSzoveg = null;
  trainNumber;

  szovegTipusok = [
    'indulás előtt 5 perccel',
    'indulás után azonnal',
    'érkezés előtt 4-5 perccel'
  ]

  kulonlegesSzovegek: {
    'forgalmi': IKulonleges,
    'maszk': IKulonleges,
    'kareset': IKulonleges,
    'muszaki': IKulonleges,
    'hatosagi': IKulonleges,
    'biztber': IKulonleges,
    'baleset': IKulonleges,
    'idojaras': IKulonleges
  }

  constructor(public szkonyv: SzovegkonyvNyarService, private route: ActivatedRoute, private modalService: NgbModal, /*public szkonyvNyar: SzovegkonyvNyarService*/) { }

  ngOnInit(): void {
    this.trainNumber = this.route.snapshot.params['id'];
    if(this.trainNumber) {
      let chosenTrain = this.szkonyv.vonatok.find((vonat) => {
        return vonat.vonatszamok.includes(Number(this.trainNumber));
      })

      this.chosenTrain = chosenTrain;
    }

    if(localStorage.getItem('szovegkonyvIndex')) {
      this.currentIndex = localStorage.getItem('szovegkonyvIndex');
    }
    else {
      this.currentIndex = 0;
    }

    
    if(!this.chosenTrain) {
      this.wasError = true;
      return;
    }

    this.szovegkonyvGeneralas();

    if(this.currentIndex >= this.generaltSzovegek.length) {
      this.currentIndex = 0;
    }

    this.kulonlegesSzovegek = {
      'forgalmi': {
        rovidites: 'forgalmi',
        megnevezes: 'Forgalmi okból megállás',
        magyar: 'Tisztelt Utasaink! Járatunk <i>([állomásnév] állomáson)</i> forgalmi okból állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped <i>(at [állomásnév])</i> for traffic reasons. Please do not get off the train. Thank you for your patience and understanding!'
      },
      'maszk': {
        rovidites:' maszk',
        megnevezes: 'Maszkhasználat',
        magyar: 'Tisztelt utasaink! A járvány helyzet miatt a vonaton történő utazás során, valamint a zárt utasforgalmi területeken kötelező az orr és a száj eltakarása maszkkal! Ennek elmaradása esetén a szabálytalanul utazót büntetés kiszabása mellett a vonatszemélyzet leszállítja a vonatról!',
        angol: 'Dear Passengers! Due to pandemic regulations, facial covering is mandatory with a mask when travelling by train, and on stations. The person failing to do so will be excluded from travel and must pay a fine.'
      },
      'kareset': {
        rovidites: 'kareset',
        megnevezes: 'Káresetek megelőzése',
        magyar: 'A káresetek megelőzése érdekében kérjük, hogy csomagjaikra, értéktárgyaikra fokozottan vigyázzanak, azokat ne hagyják őrizetlenül.',
        angol: 'To prevent incidents, please take special care of your luggage and valuables, do not leave them unattended.'
      },
      'muszaki': {
        rovidites: 'muszaki',
        megnevezes: 'Műszaki okból megállás',
        magyar: 'Tisztelt Utasaink! Járatunk <i>(a [mozdony/motorvonat/az egyik kocsi])</i> műszaki hibája miatt állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped due to a technical fault <i>[with the locomotive/DMU/a carriage]</i>. Please do not get off the train. Thank you for your patience and understanding!'
      },
      'hatosagi': {
        rovidites: 'hatosagi',
        megnevezes: 'Hatósági intézkedés miatti megállás',
        magyar: 'Tisztelt Utasaink! Járatunk hatósági intézkedés miatt állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped due to regulatory measures. Please do not get off the train. Thank you for your patience and understanding!'
      },
      'biztber': {
        rovidites: 'biztber',
        megnevezes: 'Bizt. berendezés hiba miatti megállás',
        magyar: 'Tisztelt Utasaink! Járatunk a vasúti biztosítóberendezés hibája miatt állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped due to a fault with the railway signalling system. Please do not get off the train. Thank you for your patience and understanding!'
      },
      'baleset': {
        rovidites: 'baleset',
        megnevezes: 'Baleset miatti megállás',
        magyar: 'Tisztelt Utasaink! Járatunk baleset miatt állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped due to an accident. Please do not get off the train. Thank you for your patience and understanding!'
      },
      'idojaras': {
        rovidites: 'idojaras',
        megnevezes: 'Időjárási ok miatti megállás',
        magyar: 'Tisztelt Utasaink! Járatunk rendkívüli időjárási viszonyok miatt állt meg. Kérjük, ne szálljanak le a vonatról. Türelmüket és megértésüket köszönjük!',
        angol: 'Dear Passengers, This train has stopped due to an extreme weather conditions. Please do not get off the train. Thank you for your patience and understanding!'
      }
    }
  }

  szovegkonyvGeneralas() {
    let megallasiHelyek = this.szkonyv.getMegallasiHelyek(this.chosenTrain.megallasiRendId, this.chosenTrain.reverse);

    /*if(this.chosenTrain.irany == 'Budapest') {
      megallasiHelyek.reverse();
    }*/

    let index = 0;

    let etkezokocsiHely = {
      magyar: '',
      angol: ''
    }

    if(this.chosenTrain.irany == 'Balaton') {
      etkezokocsiHely.magyar = 'végén'
      etkezokocsiHely.angol = 'at the end'
    }
    else {
      etkezokocsiHely.magyar = 'elején'
      etkezokocsiHely.angol = 'at the front'
    }

    let isKozvetlenKocsi = false;
      let kozvetlenKocsi = {
        magyar: '',
        angol: '',
        nemet: ''
      }

    let etkezoKocsi = {
      magyar: '',
      angol: ''
    }

    if(this.chosenTrain.etkezo) {
      etkezoKocsi.magyar = `Tájékoztatjuk utasainkat, hogy a vonat ${etkezokocsiHely.magyar} étkezőkocsi közlekedik, ahol szívesen látjuk Önöket. (Kínálatunkból szeretnénk figyelmükbe ajánlani...)`;
      etkezoKocsi.angol = `There is a dining car ${etkezokocsiHely.angol} of the train, where we look forward to welcoming our Guests. (From our selection, we would like to recommend...)`;
    }

    if(this.trainNumber == 204) {
      isKozvetlenKocsi = true;
      kozvetlenKocsi.magyar = ", valamint közvetlen kocsikkal Gyékényesig";
      kozvetlenKocsi.angol = ", and with direct carriages to Gyékényes";
    }

    megallasiHelyek.forEach(megallas => {
      if(index == 0) {
        // beszállítás
        this.generaltSzovegek.push({
          stationName: megallas,
          type: 0,
          hungarian: `Tisztelt Utasaink! Köszöntjük Önöket járatunk fedélzetén! A ${this.chosenTrain.friendlyVegallomas}ig${kozvetlenKocsi.magyar} közlekedő ${this.chosenTrain.nev} ${this.chosenTrain.vonatnem}${this.chosenTrain.vonatnem.includes('vonat') ? ' ' : ' vonat '}néhány perc múlva indul. Kérjük fejezzék be a fel- és leszállást!`,
          english: `Dear Passengers! Welcome on board! The ${this.szkonyv.angolVonatnem[this.chosenTrain.vonatnem]} ${this.chosenTrain.nev} to ${this.chosenTrain.vegallomas}${kozvetlenKocsi.angol} is ready to depart. Please finish the boarding!`
        })

        this.generaltSzovegek.push({
          stationName: megallas,
          type: 1,
          hungarian: `A MÁV-START Zrt. köszönti Önöket a ${this.chosenTrain.friendlyVegallomas}ig${kozvetlenKocsi.magyar} közlekedő ${this.chosenTrain.nev} ${this.chosenTrain.vonatnem}${this.chosenTrain.vonatnem.includes('vonat') ? 'án' : ' vonatán'}. 
                      Járatunk menetrend szerint ${this.getMegallasiRend(megallas, 'magyar')} Felhívjuk figyelmüket, hogy a vasút területén tilos a dohányzás. 
                      ${etkezoKocsi.magyar} Kellemes utazást kívánunk!`,
                      //Tisztelt utasaink! A járvány helyzet miatt a vonaton történő utazás során, valamint a zárt utasforgalmi területeken kötelező az orr és a száj eltakarása maszkkal! Ennek elmaradása esetén a szabálytalanul utazót büntetés kiszabása mellett a vonatszemélyzet leszállítja a vonatról! Kellemes utazást kívánunk!`,
          english: `MÁV-START welcomes You on board of the ${this.szkonyv.angolVonatnem[this.chosenTrain.vonatnem]} ${this.chosenTrain.nev} to ${this.chosenTrain.vegallomas}${kozvetlenKocsi.angol}.
                    This train ${this.getMegallasiRend(megallas, 'angol')} Please note that smoking is not allowed on the train.
                    ${etkezoKocsi.angol} We wish you a pleasant journey!`
                    //Dear Passengers! Due to pandemic regulations, facial covering is mandatory with a mask when travelling by train, and in closed passenger areas. The person failing to do so will be excluded from travel and must pay a fine. We wish you a pleasant journey!`
        }) 
      }
      else if(index == megallasiHelyek.length - 1) {
        this.generaltSzovegek.push({
          stationName: megallas,
          type: 2,
          hungarian: `Tisztelt Utasaink! Rövidesen ${this.chosenTrain.friendlyVegallomas}, végállomásra érkezünk. A MÁV-START személyzete búcsúzik Önöktől. Köszönjük, hogy utazásukhoz a ${this.chosenTrain.nev} ${this.chosenTrain.vonatnem}${this.chosenTrain.vonatnem.includes('vonat') ? 'ot' : ' vonatot'} választották. Reméljük, hogy kellemesen utaztak, és hamarosan viszontláthatjuk önöket más járatunk fedélzetén is! Viszontlátásra!`,
          english: `Dear Passengers! We will shortly be arriving at ${this.chosenTrain.vegallomas}, where this train terminates. The MÁV-START staff would like to wish you farewell. Thank you for choosing the ${this.szkonyv.angolVonatnem[this.chosenTrain.vonatnem]} ${this.chosenTrain.nev} for your journey. We hope you have had a pleasant journey, and that we can welcome you on board of our other trains again soon. Good bye!`
        })
      }
      else {
        if(this.trainNumber == 204 && megallas == "Balatonszentgyörgy") {
          this.generaltSzovegek.push({
            stationName: megallas,
            type: 2,
            hungarian: `Tisztelt Utasaink! Rövidesen ${megallas} állomásra érkezünk. Felhívjuk figyelmüket, hogy Balatonszentgyörgy állomáson a vonat hátsó három kocsija állva marad, tovább nem közlekedik. Kérjük, győződjenek meg arról, hogy úticéljuknak megfelelő vonatrészben tartózkodnak-e. Leszálló utasainknak köszönjük, hogy a vasutat választották! Viszontlátásra!`,
            english: `Dear Passengers! We will shortly be arriving at ${megallas}. Please note, that the last three carriages of this train will stay at Balatonszentgyörgy station. Please make sure you are in the correct part of the train for your destination. We would like to thank our alighting passengers for travelling with us! Good bye!`
          })
        }
        else {
          this.generaltSzovegek.push({
            stationName: megallas,
            type: 2,
            hungarian: `Tisztelt Utasaink! Rövidesen ${megallas} állomásra érkezünk. Leszálló utasainknak köszönjük, hogy a vasutat választották! Viszontlátásra!`,
            english: `Dear Passengers! We will shortly be arriving at ${megallas}. We would like to thank our alighting passengers for travelling with us! Good bye!`
          })
        }
        if(this.szkonyv.elagazoAllomasok.includes(megallas)) {
          if(this.chosenTrain.irany == 'Budapest') {
            if(megallas != 'Budapest-Kelenföld') {
              this.generaltSzovegek.push({
                stationName: megallas,
                type: 1,
                hungarian: `A MÁV-START Zrt. köszönti ${megallas} állomáson felszálló utasait a ${this.chosenTrain.friendlyVegallomas}ig${kozvetlenKocsi.magyar} közlekedő ${this.chosenTrain.nev} ${this.chosenTrain.vonatnem}${this.chosenTrain.vonatnem.includes('vonat') ? 'án' : ' vonatán'}. 
                            Járatunk menetrend szerint ${this.getMegallasiRend(megallas, 'magyar')} Felhívjuk figyelmüket, hogy a vasút területén tilos a dohányzás. 
                            ${etkezoKocsi.magyar} Kellemes utazást kívánunk!`, 
                            //Tisztelt utasaink! A járvány helyzet miatt a vonaton történő utazás során, valamint a zárt utasforgalmi területeken kötelező az orr és a száj eltakarása maszkkal! Ennek elmaradása esetén a szabálytalanul utazót büntetés kiszabása mellett a vonatszemélyzet leszállítja a vonatról! Kellemes utazást kívánunk!`,
                english: `MÁV-START would like to welcome its passengers boarding at ${megallas} on the ${this.szkonyv.angolVonatnem[this.chosenTrain.vonatnem]} ${this.chosenTrain.nev} to ${this.chosenTrain.vegallomas}${kozvetlenKocsi.angol}.
                          This train ${this.getMegallasiRend(megallas, 'angol')} Please note that smoking is not allowed on the train.
                          ${etkezoKocsi.angol} We wish you a pleasant journey!`
                          //Dear Passengers! Due to pandemic regulations, facial covering is mandatory with a mask when travelling by train, and in closed passenger areas. The person failing to do so will be excluded from travel and must pay a fine. We wish you a pleasant journey!`
              })
            }
          }
          else {
            this.generaltSzovegek.push({
              stationName: megallas,
              type: 1,
              hungarian: `A MÁV-START Zrt. köszönti ${megallas} állomáson felszálló utasait a ${this.chosenTrain.friendlyVegallomas}ig${kozvetlenKocsi.magyar} közlekedő ${this.chosenTrain.nev} ${this.chosenTrain.vonatnem}${this.chosenTrain.vonatnem.includes('vonat') ? 'án' : ' vonatán'}. 
                          Járatunk menetrend szerint ${this.getMegallasiRend(megallas, 'magyar')} Felhívjuk figyelmüket, hogy a vasút területén tilos a dohányzás. 
                          ${etkezoKocsi.magyar} Kellemes utazást kívánunk!`,
                          //Tisztelt utasaink! A járvány helyzet miatt a vonaton történő utazás során, valamint a zárt utasforgalmi területeken kötelező az orr és a száj eltakarása maszkkal! Ennek elmaradása esetén a szabálytalanul utazót büntetés kiszabása mellett a vonatszemélyzet leszállítja a vonatról! Kellemes utazást kívánunk!`,
              english: `MÁV-START would like to welcome its passengers boarding at ${megallas} on the ${this.szkonyv.angolVonatnem[this.chosenTrain.vonatnem]} ${this.chosenTrain.nev} to ${this.chosenTrain.vegallomas}${kozvetlenKocsi.angol}.
                          This train ${this.getMegallasiRend(megallas, 'angol')} Please note that smoking is not allowed on the train.
                          ${etkezoKocsi.angol} We wish you a pleasant journey!`
                          //Dear Passengers! Due to pandemic regulations, facial covering is mandatory with a mask when travelling by train, and in closed passenger areas. The person failing to do so will be excluded from travel and must pay a fine. We wish you a pleasant journey!`
            })
          }
        }
      }

      index++;
    })
  }

  getMegallasiRend(fromAllomas, nyelv) {
    let megallasiRend = '';

    let megallasiHelyek = this.szkonyv.getMegallasiHelyek(this.chosenTrain.megallasiRendId, this.chosenTrain.reverse);

    /*if(this.chosenTrain.irany == 'Budapest') {
      megallasiHelyek.reverse();
    }*/

    let allomasok = [];
      if(!this.chosenTrain.szakaszhatar || (this.chosenTrain.vonatnem == 'InterCity' && this.chosenTrain.nev != 'KÉK HULLÁM')) { // FIGYELEM! utószezoni gyorsokra optimalizálva!
        let index = 0;
        if(nyelv == 'magyar') {
          megallasiRend = 'csak '
        }
        else {
          megallasiRend = 'will stop only at '
        }
        let shouldInclude = false;
        let megallasiHelyekCount = 0;
        for(let k = 0; k < megallasiHelyek.length; k++) {
          let allomas = megallasiHelyek[k];
          if(shouldInclude) {
            if(index == megallasiHelyek.length - 1) {
              break;
            }
            if(index != megallasiHelyek.length - 2) {
              megallasiHelyekCount++;
              megallasiRend += allomas + ', '
            }
            else {
              megallasiHelyekCount++;
              if(megallasiHelyekCount == 1) {
                if(nyelv == 'magyar') {
                  megallasiRend += '' + allomas + ' állomáson áll meg.'
                }
                else {
                  megallasiRend += '' + allomas + ' station.'
                }
              }
              else {
                if(nyelv == 'magyar') {
                  megallasiRend += 'és ' + allomas + ' állomásokon áll meg.'
                }
                else {
                  megallasiRend += 'and ' + allomas + ' stations.'
                }
              }
            }
          }
          if(allomas == fromAllomas) {
            shouldInclude = true;
          }
          index++;
        }
        if(megallasiHelyekCount == 0) {
          if(nyelv == 'magyar') {
            megallasiRend = `${this.chosenTrain.vegallomas}ig nem áll meg.`;
          }
          else {
            megallasiRend = `will not stop until ${this.chosenTrain.vegallomas}.`
          }
        }
      }
      // budapest felé megy, a mindenhol megállós szakasz után kell állomást sorolni
      else if(this.chosenTrain.irany == 'Budapest') {
        let index = 0;
        let kulonMegallasiHatar = this.chosenTrain.szakaszhatar;

        if(megallasiHelyek.indexOf(fromAllomas) >= megallasiHelyek.indexOf(kulonMegallasiHatar)) {
          if(nyelv == 'magyar') {
            megallasiRend = ` csak `
          }
          else {
            megallasiRend = ` will stop only at `
          }
        }
        else {
          if(nyelv == 'magyar') {
            megallasiRend = `${kulonMegallasiHatar}ig ${this.chosenTrain.nev == 'KÉK HULLÁM' ? 'Szepezdfürdő és Badacsonyörs kivételével ' : ''}minden állomáson és megállóhelyen megáll. `
            if(kulonMegallasiHatar == 'Lepsény' || kulonMegallasiHatar == 'Balatonfüred') {
              megallasiRend += `${kulonMegallasiHatar}től csak `
            }
            else {
              megallasiRend += `${kulonMegallasiHatar}tól csak `
            }
            //megallasiRend = `${this.megallasiHatarok[this.myTrain['Vonat']['Nev']]}ig csak `
          }
          else {
            //megallasiRend = `until ${this.megallasiHatarok[this.myTrain['Vonat']['Nev']]} will stop only at `
            megallasiRend = `until ${kulonMegallasiHatar} will stop at every station${this.chosenTrain.nev == 'KÉK HULLÁM' ? ' except Szepezdfürdő and Badacsonyörs.' : '.'}`
            megallasiRend += `From ${kulonMegallasiHatar} it will stop only at `
          }
        }
        let shouldInclude = false;
        let megallasiHelyekCount = 0;
        for(let k = 0; k < megallasiHelyek.length; k++) {
          let allomas = megallasiHelyek[k];
          if(shouldInclude) {
            /*if(allomas['AllomasNev'] != this.allStations[this.myTrain['Vonat']['Viszonylat']['CelAllomasKod']]) {
              break;
            }*/
            if(megallasiHelyek[k+1] == this.chosenTrain.vegallomas) {
              megallasiHelyekCount++;
              if(megallasiHelyekCount == 1) {
                if(nyelv == 'magyar') {
                  megallasiRend += '' + allomas + ' állomáson áll meg.'
                }
                else {
                  megallasiRend += '' + allomas + ' station.'
                }
              }
              else {
                if(nyelv == 'magyar') {
                  megallasiRend += 'és ' + allomas + ' állomásokon áll meg.'
                }
                else {
                  megallasiRend += 'and ' + allomas + ' stations.'
                }
              }
              break;
            }
            else {
              megallasiHelyekCount++;
              megallasiRend += allomas + ', '
            }
          }
          // ha a jelenlegi állomás már elhagyta a megállási határt, akkor a felsorolást a jelenlegi állomástól kezdjük, ellenkező esetben felsorolunk mindent a megállási határtól
          if(megallasiHelyek.indexOf(fromAllomas) >= megallasiHelyek.indexOf(kulonMegallasiHatar)) {
            if(allomas == fromAllomas) {
              shouldInclude = true;
            }
          }
          else {
            if(allomas == this.chosenTrain.szakaszhatar) {
              shouldInclude = true;
            }
          }
         
          index++;
        }
        /*if(nyelv == 'magyar') {
          megallasiRend += ` ${this.megallasiHatarok[this.myTrain['Vonat']['Nev']]}tól minden állomáson és megállóhelyen megáll.`
        }
        else {
          megallasiRend += ` From ${this.megallasiHatarok[this.myTrain['Vonat']['Nev']]} it will stop at every station.`
        }*/
      }
      else {
        let index = 0;
        let kulonMegallasiHatar = this.chosenTrain.szakaszhatar;

        if(megallasiHelyek.indexOf(fromAllomas) >= megallasiHelyek.indexOf(kulonMegallasiHatar)) { // ha elértük a megállási határt, vagy átléptük
          if(nyelv == 'magyar') {
            megallasiRend = `${this.chosenTrain.nev == 'KÉK HULLÁM' ? 'Szepezdfürdő és Badacsonyörs kivételével ' : ''}minden állomáson és megállóhelyen megáll.`
          }
          else {
            megallasiRend = `will stop at every station${this.chosenTrain.nev == 'KÉK HULLÁM' ? ' except Szepezdfürdő and Badacsonyörs.' : '.'}`
          }
          return megallasiRend;
        }
        if(nyelv == 'magyar') {
          megallasiRend = `${kulonMegallasiHatar}ig csak `
        }
        else {
          megallasiRend = `until ${kulonMegallasiHatar} will stop only at `
        }
        let shouldInclude = false;
        let megallasiHelyekCount = 0;
        for(let k = 0; k < megallasiHelyek.length; k++) {
          let allomas = megallasiHelyek[k];
          if(shouldInclude) {
            if(allomas == kulonMegallasiHatar) {
              break;
            }
            if(megallasiHelyek[k+1] == kulonMegallasiHatar) {
              megallasiHelyekCount++;
              if(megallasiHelyekCount == 1) {
                if(nyelv == 'magyar') {
                  megallasiRend += '' + allomas + ' állomáson áll meg.'
                }
                else {
                  megallasiRend += '' + allomas + ' station.'
                }
              }
              else {
                if(nyelv == 'magyar') {
                  megallasiRend += 'és ' + allomas + ' állomásokon áll meg.'
                }
                else {
                  megallasiRend += 'and ' + allomas + ' stations.'
                }
              }
              break;
            }
            else {
              megallasiHelyekCount++;
              megallasiRend += allomas + ', '
            }
          }
          if(allomas == fromAllomas) {
            shouldInclude = true;
          }
          index++;
        }
        // ha nincs felsorolandó megálló, akkor nem áll meg a határig sehol
        if(megallasiHelyekCount == 0) {
          if(nyelv == 'magyar') {
            megallasiRend = `${kulonMegallasiHatar}ig nem áll meg.`
          }
          else {
            megallasiRend = `will not stop until ${kulonMegallasiHatar}.`
          }
        }
        if(nyelv == 'magyar') {
          if(kulonMegallasiHatar == 'Lepsény' || kulonMegallasiHatar == 'Balatonfüred') {
            megallasiRend += ` ${kulonMegallasiHatar}től ${this.chosenTrain.nev == 'KÉK HULLÁM' ? 'Szepezdfürdő és Badacsonyörs kivételével ' : ''}minden állomáson és megállóhelyen megáll.`
          }
          else {
            megallasiRend += ` ${kulonMegallasiHatar}tól ${this.chosenTrain.nev == 'KÉK HULLÁM' ? 'Szepezdfürdő és Badacsonyörs kivételével ' : ''}minden állomáson és megállóhelyen megáll.`
          }
        }
        else {
          megallasiRend += ` From ${kulonMegallasiHatar} it will stop at every station${this.chosenTrain.nev == 'KÉK HULLÁM' ? ' except Szepezdfürdő and Badacsonyörs.' : '.'}`
        }
      }
      return megallasiRend;
  }

  moveBemondas(how) {
    if(how > 0) {
      this.currentIndex++;
      localStorage.setItem('szovegkonyvIndex', this.currentIndex);
    }
    else {
      this.currentIndex--;
      localStorage.setItem('szovegkonyvIndex', this.currentIndex);
    }
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

}
