import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { BackendService } from '../../backend.service';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import * as PullToRefresh from 'pulltorefreshjs';
import { timer } from 'rxjs';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-vonat',
  templateUrl: './vonat.component.html',
  styleUrls: ['./vonat.component.css'],
  providers: [DatePipe]
})
export class VonatComponent implements OnInit, AfterViewInit, OnDestroy {
  myTrain = <any>{};
  allStations = {};
  kozeliAllomas = {};
  mozdony = {};
  error = "";
  closeResult: string;
  storageVonatinfo;
  isLoading;
  osszeallitas;
  ptrinit: any;
  idoString = '--:--:--';
  myDate = new Date();
  subscription;
  adatbazisNap = "hetfo";
  szvorSzerelveny;

  constructor(private data: BackendService, private modalService: NgbModal, private route: ActivatedRoute, private router: Router, private datePipe: DatePipe) {
    this.idoString = this.datePipe.transform(this.myDate, 'HH:mm:ss');
  }

  ngOnInit() {
    if(this.route.snapshot.params['szam'] != "") {

      let maiNap = new Date().getDay();
      if(maiNap == 0) {
        this.adatbazisNap = "vasarnap";
      }
      else if(maiNap >= 2 && maiNap <= 4) {
        this.adatbazisNap = "kedd-csutortok";
      }
      else if(maiNap == 5) {
        this.adatbazisNap = "pentek";
      }
      else if(maiNap == 6) {
        this.adatbazisNap = "szombat";
      }

      let vonatszam = this.route.snapshot.params['szam'];
      let vonatid;
      this.isLoading = true;
      this.observableTimer();
      this.data.getVonatinfo(vonatszam).subscribe(data => {
        this.isLoading = false;
        this.myTrain = data;
        console.log(this.myTrain);
        vonatid = this.myTrain.Vonat.ID;
        this.data.addLastTrainNumber({
          vonatszam: this.myTrain.Vonat.Szam,
          viszonylat: this.myTrain.Menetrend[0].AllomasNev + ' - ' + this.myTrain.Menetrend[this.myTrain.Menetrend.length - 1].AllomasNev
        })
        //localStorage.setItem('vonatszam', this.myTrain.Vonat.Szam)
        this.data.getVonatPozicio(this.myTrain.Vonat.Szam).subscribe(data => {
          this.kozeliAllomas = data;
        })
        this.data.getMozdonyNev(vonatid).subscribe(data => {
          this.mozdony = data;
        })
        this.data.checkOsszeallitas(this.myTrain.Vonat.Szam).subscribe(data => {
          if(data['url']) {
            this.osszeallitas = data['url'];
          }
          else {
            this.osszeallitas = null;
          }
        })
        this.data.checkSZVOR(this.myTrain.Vonat.Szam).subscribe(data => {
          this.szvorSzerelveny = data;
        }, error => {
          this.szvorSzerelveny = null;
        })
      }, error => {
        this.error = error;
      })
    }
    
    this.data.getAllomasLista().subscribe(data => {
      this.allStations = data;
    }, error => {
      console.log(error);
    })
  }

  observableTimer() {
    const source = timer(1000, 1000);
    this.subscription = source.subscribe(val => {
      this.myDate = new Date();
      this.idoString = this.datePipe.transform(this.myDate, 'HH:mm:ss')
    })
  }

  ngAfterViewInit() {
    this.ptrinit = PullToRefresh.init({
      mainElement: '#refresh',
      instructionsPullToRefresh: 'Húzd le a frissítéshez',
      instructionsReleaseToRefresh: 'Engedd el a frissítéshez',
      instructionsRefreshing: 'Frissítés...',
      iconArrow: '<i class="fa fa-arrow-up"></i>',
      distMax: 100,
      distReload: 70,
      distThreshold: 80,
      getStyles() {
        return ".__PREFIX__ptr { " +
               "      box-shadow: inset 0 -3px 5px rgba(0, 0, 0, 0.12);" +
               "      pointer-events: none; " +
               "      font-size: 0.85em;  " +
               "      font-weight: bold; " +
               "      top: 0; " +
               "      height: 0; " +
               "      transition: height 0.3s, min-height 0.3s; " +
               "      text-align: center; " +
               "      width: 100%; " +
               "      overflow: hidden; " +
               "      display: flex; " +
               "      " +
               "      align-items: flex-end; " +
               "      align-content: stretch; " +
               "    } " +
               "      .__PREFIX__box { " +
               "    " +
               "    padding: 10px;" +
               "    flex-basis: 100%; " +
               "  } " +
               ".__PREFIX__pull { " +
               "    transition: none; " +
               "  } " +
               ".__PREFIX__text { " +
               "    margin-top: .33em; " +
               "    color: white; " +
               "  } " +
               ".__PREFIX__icon { " +
               "    color: white;" +
               "    transition: transform .3s; " +
               "  } " +
               ".__PREFIX__top { " +
               "    touch-action: pan-x pan-down pinch-zoom; " +
               "  } " +
               ".__PREFIX__release .__PREFIX__icon { " +
               "    transform: rotate(180deg); " +
               "  }";
      },
      onRefresh() {
        window.location.reload();
      }
    })
  }

  ugrasSzemelyzetre() {
    this.modalService.dismissAll('Szemelyzet click');
  }

  ugrasTerkepre() {
    localStorage.setItem('terkepVonat', this.myTrain['Vonat']['Szam']);
    this.modalService.dismissAll('Szemelyzet click');
  }

  resetVonatszam() {
    this.router.navigate(['vonatinfo']);
    /*localStorage.removeItem('vonatszam');
    localStorage.removeItem('vonatid');
    this.myTrain = <any>{};
    this.mozdony = {};
    this.kozeliAllomas = {};
    this.inProgress = false;
    this.success = false;
    this.wasError = false;
    this.submitted = false;*/
  }

  checkLekozlekedett() {
    if(this.myTrain['Menetrend'][this.myTrain['Menetrend'].length - 1]['Ido']['ErkTeny'] && !this.myTrain['Vonat']['Kozlekedik']) {
      return true
    }
    else {
      return false
    }
  }

  displayViszonylatszam(fontId) {
    if(fontId) {
      return String.fromCharCode(fontId)
    }
    else {
      return '';
    }
    
  }

  checkColor(erkM, erkT, erkMSz, erkTSz) {
    if(erkM < erkT && erkMSz != erkTSz) {
      return "red";
    }
    else {
      return "green";
    }
  }

  getTenyIdoFromKeses(erkTenyDatum, erkMDatum, Keses) {
    if(erkTenyDatum != null) {
      return erkTenyDatum * 1000;
    }
    else {
      var becsult = erkMDatum + (Keses * 60);
      return becsult * 1000;
    }
  }

  jumpToAllomas(allomasnev) {
    this.router.navigate(['/allomasinfo', allomasnev])
  }

  checkBold(allomasnev, index) {
    if(allomasnev == this.myTrain.Vonat.kovetkezoAllomas) {
      return 'bold';
    }
    else {
      return 'normal';
    }
    /*if(index != 0) {
      if(!this.myTrain.Menetrend[index].Ido.ErkTeny && this.myTrain.Menetrend[index-1].Ido.ErkTeny) {
        return 'bold';
      }
      else {
        return 'normal';
      }
    }
    else {
      if(!this.myTrain.Menetrend[index].Ido.IndTeny) {
        return 'bold';
      }
      else {
        return 'normal';
      }
    }
    if(index != this.myTrain.Menetrend.length) {
      if(this.myTrain.Vonat.kovetkezoAllomas == this.myTrain.Menetrend[index+1].AllomasNev) {
        return 'bold';
      }
      else {
        return 'normal';
      }
    }
    else {
      if(this.myTrain.Vonat.kovetkezoAllomas == this.myTrain.Menetrend[index].AllomasNev) {
        return 'bold';
      }
      else {
        return 'normal';
      }
    }*/
  }

  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }

  ngOnDestroy() {
    PullToRefresh.destroyAll();
    this.subscription.unsubscribe();
  }
}
